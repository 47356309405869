import { Article, Download, Medication } from "@mui/icons-material";
import { DialogTitle, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { CommonDialog } from "../../../components/common/CommonDialog";
import ControlMedication from "../../../components/common/ControlMedication";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { IframeDialog } from "../../ehrrecords/components/IframeDialog";

export default function MedicalPrescriptionActions({ row }: any) {
  const [base64, setBase64] = useState<any>();
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [openMedicalControl, setOpenMedicationControl] =
    useState<boolean>(false);

  async function getDocumentPdf() {
    const response = await GetBinary({ urlBinary: row?.binaryUrl });
    setBase64(response);
    return response;
  }

  async function downloadBase64File() {
    const auxDocument = !base64 ? await getDocumentPdf() : base64;
    const linkSource = `data:${auxDocument?.contentType};base64,${auxDocument?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Receita médica";
    downloadLink.click();
  }

  return (
    <Stack width="fit-content">
      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => setOpenDocument(true)}>
          <Tooltip title="Visualizar documento">
            <Article />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => downloadBase64File()}>
          <Tooltip title="Baixar">
            <Download />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={() => setOpenMedicationControl(true)}
        >
          <Tooltip title="Uso do medicamento">
            <Medication />
          </Tooltip>
        </IconButton>
      </Stack>

      {openDocument && (
        <IframeDialog
          handleClose={() => setOpenDocument(false)}
          isOpen={openDocument}
          data={row?.binaryUrl}
          title={row?.description}
          loadedBase64={base64}
          setLoaded={setBase64}
        />
      )}
      {openMedicalControl && (
        <CommonDialog
          isOpen={openMedicalControl}
          handleClose={() => setOpenMedicationControl(false)}
        >
          <DialogTitle>Controle o uso do seu remédio</DialogTitle>
          <Stack paddingX={1}>
            {row?.medication
              // .filter(
              //   (el: any) =>
              //     el?.resource?.status !== "completed" &&
              //     el?.resource?.status !== "stopped"
              // )
              .map((el: any, index: number) => (
                <ControlMedication data={el} index={index} />
              ))}
          </Stack>
        </CommonDialog>
      )}
    </Stack>
  );
}
