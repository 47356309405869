import { Grid } from "@mui/material";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

const smokingOptions = [
  {
    code: "LA18976-3",
    display: "atualmente, fumante diário",
    value: "Current every day smoker",
  },
  {
    code: "LA18977-1",
    display: "atualmente, fumante alguns dias na semana",
    value: "Current some day smoker",
  },
  { code: "LA15920-4", display: "ex-fumante", value: "Former smoker" },
  { code: "LA18978-9", display: "nunca fumou", value: "Never smoker" },
  {
    code: "LA18979-7",
    display: "estado tabagista atual desconhecido",
    value: "Smoker, current status unknown",
  },
  {
    code: "LA18980-5",
    display: "sem conhecimento se já fumou",
    value: "Unknown if ever smoked",
  },
  {
    code: "LA18981-3",
    display: "fumante frequente",
    value: "Heavy tobacco smoker",
  },
  {
    code: "LA18982-1",
    display: "fumante eventual",
    value: "Light tobacco smoker",
  },
  {
    code: "",
    display: "Limpar",
    value: "",
  },
];

// const breastfeedingOptions = [
//   {
//     display: "Exclusivo",
//     value: "exclusivo",
//   },
//   {
//     display: "Predominante",
//     value: "predominante",
//   },
//   {
//     display: "Complementado",
//     value: "complementado",
//   },
//   {
//     display: "Inexistente",
//     value: "inexistente",
//   },
//   {
//     display: "Limpar",
//     value: "",
//   },
// ];

export function SocialFields({ gender }: any) {
  const { control } = useMedicalCareContext();

  return (
    <Grid container spacing={3} marginTop="1px">
      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Consumo diário de álcool (ml)"}
          name={"Anthropometric.alcoholic"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <FormSelectCompleted
          control={control}
          name="Anthropometric.smoking"
          label="Tabagismo"
          selectOptions={smokingOptions}
        />
      </Grid>
    </Grid>
  );
}
