import { Box, Stack, Typography } from "@mui/material";

import { RestartAltSharp } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { LargeContentBox, SecondaryButton } from "../../components/common";
import {
  FormDatePicker,
  FormSelectInput,
  FormTextInput,
} from "../../components/form";
import { PrivateMainLayout } from "../../components/layout";
import { usePatientOrganizationContext } from "../../contexts/PatientOrganizationContext";
import { useFormatter } from "../../utils/useFormatter";
import { useRegex } from "../../utils/useRegex";
import { PatientSearchActions } from "./components/PatientSearchActions";

interface IProfessionalDashboard {
  patientIdentifier: string | undefined;
  identifier: string;
  patientName: string;
  patientName2: string;
  name: string | undefined;
  birthDate: Dayjs | undefined;
  gender: string;
}

const defaultValues = {
  patientIdentifier: "",
  identifier: "",
  patientName: "",
  patientName2: "",
  birthDate: undefined,
  gender: "",
};

const typeOptionsGender: Array<any> = [
  { display: "Masculino", value: "male" },
  { display: "Feminino", value: "female" },
];

export function PatientSearch() {
  const { patientOrganization, refetchPatientOrganization } =
    usePatientOrganizationContext();
  const { state } = useLocation();
  const methods = useForm<IProfessionalDashboard>({ defaultValues });
  const { control, watch, setValue, reset } = methods;
  const patientValue = state?.patient?.value || "";

  const searchField = watch("patientName");
  const identifierField = watch("identifier");
  const birthDateField = watch("birthDate");
  const genderField = watch("gender");

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
  ];

  const rows = patientOrganization
    ?.filter((element: any) =>
      searchField
        ? element.resource?.name?.[0]?.text
            ?.toUpperCase()
            ?.includes(searchField.toUpperCase())
        : element
    )
    ?.filter((filter: any) =>
      useFormatter
        .formatCPF(filter?.resource?.id?.split("-")[1])
        .includes(identifierField)
    )
    ?.filter((filterGender: any) =>
      genderField ? filterGender.resource.gender === genderField : filterGender
    )
    ?.filter((filterDate: any) =>
      birthDateField?.toString() !== undefined
        ? filterDate?.resource?.birthDate?.includes(
            dayjs(birthDateField).format("YYYY-MM-DD")
          )
        : filterDate
    )
    ?.map((el: any) => {
      const identifier = useFormatter.formatCPF(el.resource?.id.split("-")[1]);
      const name =
        useFormatter.formatNameDisplay(el.resource?.name?.[0]?.text) || "-";
      const birthDate =
        el.resource?.birthDate &&
        `${useFormatter.formatDate(
          el.resource?.birthDate
        )} - ${useFormatter.formatAge(el.resource?.birthDate)} anos`;
      const gender = useFormatter.formatGender(el.resource?.gender) || "-";

      return {
        identifier,
        name,
        birthDate,
        gender,
      };
    });

  useEffect(() => {
    if (patientValue) setValue("patientName", patientValue);
    refetchPatientOrganization();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValue]);

  return (
    <PrivateMainLayout dashboard>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "neutral0.main",
          borderRadius: "20px",
          mb: 2,
          padding: "24px 40px",
        }}
      >
        <Typography variant="h6" fontWeight={600} mt={2} mb={2}>
          Filtrar
        </Typography>
        <Stack
          alignItems="center"
          width="100%"
          spacing={2}
          direction="row"
          mb={2}
        >
          <FormTextInput label="Nome" name="patientName" control={control} />

          <FormTextInput
            label="CPF"
            name="identifier"
            control={control}
            required={true}
            pattern={useRegex.cpf}
            mask={useFormatter.formatCPF}
            maxLength={14}
          />

          <FormDatePicker
            name="birthDate"
            label="Data de nascimento"
            maxDate={dayjs()}
            control={control}
          />
          <FormSelectInput
            name="gender"
            control={control}
            label="Sexo de nascimento"
            selectOptions={typeOptionsGender}
          />

          <SecondaryButton width="500px" height="56px" onClick={() => reset()}>
            <RestartAltSharp />
          </SecondaryButton>
        </Stack>
      </Box>
      <LargeContentBox>
        <Stack justifyContent="flex-start" width="100%">
          <Stack width="100%" justifyContent="flex-start">
            <Typography variant="h6" fontWeight={600} mb={2}>
              Pacientes
            </Typography>
            <GenericTable
              tableColumns={columns}
              tableRows={rows}
              actions={(rowData: any) => <PatientSearchActions row={rowData} />}
            />
          </Stack>
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
