import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { PrimaryButton } from "../../../components/common";
import SelectWithInput from "./SelectWithInput";

export default function DialogAccountFinish({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose?: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          paddingX: 2,
          paddingBottom: 2,
          minWidth: "600px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Extração de dados"}</DialogTitle>
      <DialogContent>
        <SelectWithInput />
      </DialogContent>
      <DialogActions>
        <PrimaryButton width={"100%"} height={"50px"} onClick={() => {}}>
          Baixar extração dados
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
