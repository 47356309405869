import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CommonDialog } from "../../../components/common/CommonDialog";
import {
  FormAutoComplete,
  FormButtonGroup,
  FormTextInput,
} from "../../../components/form";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { EditOrganization } from "../../../services/fhir";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useFormatter } from "../../../utils/useFormatter";
import { UFValueSet } from "../../professionalregister/components/form/ValueSet";

interface IformInput {
  enrollment: string;
  state: {
    label: string;
    value: string;
  };
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  displayStateRegistration: string;
  organization: any;
}

export function EditStateRegistrationDialog({
  isOpen,
  setIsOpen,
  displayStateRegistration,
  organization,
}: IProps) {
  const defaultValues: IformInput = {
    enrollment: displayStateRegistration?.replace(/[A-Za-z]/g, ""),
    state: {
      label: displayStateRegistration?.slice(0, 2),
      value: displayStateRegistration?.slice(0, 2),
    },
  };

  const navigate = useNavigate();
  const { refetchOrganization } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<IformInput>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = methods;

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await EditOrganization(
        organization,
        "inscricaoEstadual",
        `${data?.state?.value}${data.enrollment}`
      );
      if (response?.status === HTTPResponseStatus.Success) {
        refetchOrganization();
        toast.success("Alteração realizada com sucesso");
        setIsOpen(false);
        navigate("/clinic");
        return;
      }
      toast.error(
        "Houve um erro na atualização do número de inscrição estadual, tente novamente mais tarde"
      );
    } catch (error) {
      toast.error(
        "Houve um erro na atualização do número de inscrição estadual, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    navigate("/clinic");
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        Editar inscrição estadual
      </DialogTitle>
      <DialogContent>
        <Stack pt={1} direction="row" spacing={1}>
          <FormAutoComplete
            name="state"
            control={control}
            label="Estado"
            selectOptions={UFValueSet}
            defaultValue={{
              label: displayStateRegistration?.slice(0, 2),
              value: displayStateRegistration?.slice(0, 2),
            }}
          />
          <Stack direction="column" width="100%" spacing={1}>
            <FormTextInput
              name="enrollment"
              label="Número de inscrição"
              control={control}
              autoFocus={true}
              pattern={/^\d{8,14}$/}
              mask={useFormatter.removeSpecialChars}
              placeholder="Ex: 123456789"
              required={true}
            />

            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ color: "neutral700.main" }}
              pl={1}
            >
              Insira somente números
            </Typography>
          </Stack>
        </Stack>
        <Stack
          width="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
        >
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Salvar"
            loading={loading}
            disabled={!isDirty}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
