import { Lock, Remove } from "@mui/icons-material";
import { Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import {
  InputRadio,
  InputText,
} from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

export function ExamsFields({ item, type, handleDeleteItem, disabled }: any) {
  const { control, unregister } = useMedicalCareContext();

  const alteradoWatch = useWatch({
    name: `exams.${type}.${item?.text.replace(".", " ")}.value`,
    control,
  });

  useEffect(() => {
    if (alteradoWatch === "normal") {
      unregister(`exams.${type}.${item?.text.replace(".", " ")}.alterado`);
    }
  }, [alteradoWatch, item?.text, type, unregister]);

  return (
    <Grid
      item
      xs={6}
      paddingRight={2}
      sx={{
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack>
          <InputRadio
            key={`exams.${type}.${item?.text}`}
            label={item?.text}
            name={`exams.${type}.${item?.text.replace(".", " ")}.value`}
            control={control}
            answerOption={[
              { valueCoding: { id: "normal", display: "Normal" } },
              { valueCoding: { id: "alterado", display: "Alterado" } },
            ]}
            defaultValue={"not_evaluated"}
            sx={{ mb: 2 }}
          />
        </Stack>

        {!item?.notEditable ? (
          <Tooltip title="Remover item">
            <IconButton
              onClick={() => {
                handleDeleteItem();
                unregister(`exams.${type}.${item?.text.replace(".", " ")}`);
              }}
            >
              <Remove sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="">
            <IconButton>
              <Lock />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {alteradoWatch === "alterado" && (
        <InputText
          key={item?.item?.[0]?.linkId}
          label={item?.item?.[0].text}
          name={`exams.${type}.${item?.text.replace(".", " ")}.alterado`}
          control={control}
          multiline={true}
        />
      )}
    </Grid>
  );
}
