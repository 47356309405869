import { ArrowBack, Close } from "@mui/icons-material";
import { Dialog, IconButton, Stack } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BundlePatientSummary } from "../../../../services/fhir";
import { useFhirData } from "../../../../utils/useFhirData";
import { TabDocumentsChoise } from "./components/TabDocumentsChoise";
import { TableExamSolicitation } from "./components/TableExamSolicitation";
import { TablePrescription } from "./components/TablePrescription";
import { TableSolicitations } from "./components/TableSolicitations";
import { VisualizationDocument } from "./components/VisualizationDocument";

interface IProps {
  open: boolean;
  handleClose: VoidFunction;
  addItem: any;
}

export function PreviousDocumentsDialog({
  open,
  handleClose,
  addItem,
}: IProps) {
  const [binaryUrl, setBinaryUrl] = useState<string>("");
  const { state } = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState();

  const [tabValue, setTabValue] = useState<number>(0);

  const examSolicitation = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "service-request"
  );

  const prescriptionDocuments = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "prescricao"
  );

  const medicalCertificate = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "atestado"
  );

  const resultsData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "resultado"
  );

  const handleTab = (event: SyntheticEvent, newValueTab: number) => {
    setTabValue(newValueTab);
    setBinaryUrl("");
  };

  useEffect(() => {
    async function GetBundle() {
      setLoading(true);
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: state?.patientId,
        });

        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoading(false);
      }
    }
    if (state?.patientId) GetBundle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Stack maxHeight={binaryUrl ? "fit-content" : "500px"}>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
          paddingX={2}
          paddingY={1}
        >
          <IconButton onClick={handleClose} sx={{ alignSelf: "flex-end" }}>
            <Close sx={{ color: "neutral600.main" }} />
          </IconButton>
          <TabDocumentsChoise tabValue={tabValue} handleTab={handleTab} />
        </Stack>

        {binaryUrl ? (
          <>
            <Stack justifyContent="flex-start">
              <IconButton
                sx={{ width: "40px" }}
                onClick={() => {
                  setBinaryUrl("");
                }}
              >
                <ArrowBack />
              </IconButton>
            </Stack>
            <Stack alignItems="center">
              <VisualizationDocument data={binaryUrl} />
            </Stack>
          </>
        ) : (
          <>
            {tabValue === 0 && (
              <TableSolicitations
                solicitation={examSolicitation}
                resultsData={resultsData}
                loading={loading}
                setBinaryUrl={setBinaryUrl}
                addItem={addItem}
              />
            )}

            {tabValue === 1 && (
              <TablePrescription
                data={useFhirData.extractPatientSummaryResource(
                  summaryData,
                  "MedicationRequest"
                )}
                documentReference={prescriptionDocuments?.[0]?.entry?.filter(
                  (el: any) =>
                    el?.resource?.resourceType === "DocumentReference"
                )}
                loading={loading}
                setBinaryUrl={setBinaryUrl}
                addItem={addItem}
              />
            )}

            {tabValue === 2 && (
              <TableExamSolicitation
                certificate={medicalCertificate}
                loading={loading}
                setBinaryUrl={setBinaryUrl}
              />
            )}
          </>
        )}
      </Stack>
    </Dialog>
  );
}
