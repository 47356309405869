import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";

import { PatchAppointmentStatus } from "../../services/fhir";
import { CommonDialog } from "../common/CommonDialog";
import { FormButtonGroup } from "../form";
import { isMobile } from "react-device-detect";
import { DeleteWhereby } from "../../services/whereby/DeleteWhereby";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setRefetch: Dispatch<SetStateAction<boolean>>;
  appointment: fhir5.Appointment | undefined;
}

export default function ScheduleCancelDialog({
  isOpen,
  setIsOpen,
  appointment,
  setRefetch,
}: IProps) {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (appointment?.id) {
        await PatchAppointmentStatus({
          appointmentId: appointment.id,
          status: "cancelled",
        });
      } else {
        throw Error();
      }
      if (appointment?.virtualService) {
        DeleteWhereby(appointment?.virtualService?.[0]?.sessionKey as string);
      }
      toast.success("Consulta cancelada");
      setIsOpen(false);
      setRefetch((prev) => !prev);
    } catch (error) {
      toast.error("Não foi possível concluir o cancelamento da sua consulta");
    } finally {
      setLoading(false);
    }
  };

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle fontWeight={600} textAlign="center">
        Cancelar consulta
      </DialogTitle>
      <DialogContent>
        <Typography textAlign="center" variant="subtitle2">
          Deseja confirmar a operação?
        </Typography>
        <Stack
          minWidth="100%"
          mt={3}
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <FormButtonGroup
            onGoBackButtonClick={() => setIsOpen(false)}
            onNextButtonClick={onSubmit}
            goBackButtonText="Não"
            nextButtonText="Sim"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
