import { useFhirData } from "../../../utils/useFhirData";
import { Diagnosis } from "./patientSummaryAccordions/Diagnosis/Diagnosis";
import { Prescriptions } from "./patientSummaryAccordions/Prescriptions/Prescriptions";
import { ServiceRequest } from "./patientSummaryAccordions/ServiceRequest/ServiceRequest";

import { Stack } from "@mui/material";
import { useState } from "react";
import { useSessionContext } from "../../../contexts/SessionContext";
import { IframeDialog } from "./IframeDialog";
import { Allergies } from "./patientSummaryAccordions/Allergies/Allergies";
import { ClinicalEncounter } from "./patientSummaryAccordions/ClinicalEncounter/ClinicalEncounter";
import { ClinicalEncounterVisualizer } from "./patientSummaryAccordions/ClinicalEncounter/ClinicalEncounterVisualizer";
import { Documents } from "./patientSummaryAccordions/Documents/Documents";
import { ObservationsEncounter } from "./patientSummaryAccordions/ObservationsEncounter/ObservationsEncounter";
import { ProcedureRequest } from "./patientSummaryAccordions/ProcedureRequest/ProcedureRequest";

export function PatientSummary({ summary, setRefetch }: any) {
  const { access } = useSessionContext();
  const [open, setOpen] = useState<boolean>(false);
  const [responseViewer, setResponseViewer] = useState<boolean>(false);
  const [selectedResponse, setSelectedResponse] = useState<any>();
  const [binaryUrl, setBinaryUrl] = useState<string>("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBinaryUrl("");
    setOpen(false);
  };

  const handleCloseResponseViewer = () => {
    setSelectedResponse(undefined);
    setResponseViewer(false);
  };

  const prescriptionDocuments = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "prescricao"
  );

  const medicalNoteData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "prescricao"
  )?.[0]?.entry;

  const solicitationData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "service-request"
  )?.[0]?.entry;

  const NoteReportData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "atestado"
  )?.[0]?.entry;

  const resultsData = useFhirData.extractPatientSummaryResource(
    summary,
    "DocumentReference",
    "resultado"
  );

  const observationsData = useFhirData.extractPatientSummaryResource(
    summary,
    "Observation"
  );

  const encounterData = useFhirData.extractPatientSummaryResource(
    summary,
    "Encounter"
  );
  const questionnaireData = useFhirData.extractPatientSummaryResource(
    summary,
    "QuestionnaireResponse"
  );

  const auxEncounter = encounterData?.[0]?.entry?.map((el: any) => {
    const encounter = el?.resource;
    const auxObservations = observationsData?.[0]?.entry?.filter((e: any) =>
      e?.resource?.encounter?.reference.includes(el?.resource?.id)
    );
    const auxQuestionnaire = questionnaireData?.[0]?.entry?.filter((e: any) =>
      e?.resource?.encounter?.reference.includes(el?.resource?.id)
    );
    return {
      encounter,
      obs: auxObservations,
      questionnaire: auxQuestionnaire,
    };
  });

  function handleOpenVisualizer(encounterId: string) {
    const findValue = auxEncounter?.find((el: any) => {
      return el?.encounter?.id === encounterId;
    });
    if (findValue) {
      setSelectedResponse(findValue?.questionnaire?.[0]?.resource?.item);
      setResponseViewer(true);
    }
  }

  function verifyVisualizer(encounterId: string) {
    const findValue = auxEncounter?.find((el: any) => {
      return el?.encounter?.id === encounterId;
    });
    return findValue?.questionnaire?.[0]?.resource?.item;
  }

  return (
    <>
      <Stack spacing="4px">
        <Stack paddingBottom={4} spacing="4px">
          <Documents
            setRefetch={setRefetch}
            sign
            handleOpen={handleOpen}
            setBinaryUrl={setBinaryUrl}
            title="Documentos"
            medicalNoteData={medicalNoteData}
            solicitationData={solicitationData}
            resultsData={useFhirData.extractPatientSummaryResource(
              summary,
              "DocumentReference",
              "resultado"
            )}
            NoteReportData={NoteReportData}
            data={summary}
          />
          {access?.type === "professional" ? (
            <Documents
              setRefetch={setRefetch}
              handleOpen={handleOpen}
              setBinaryUrl={setBinaryUrl}
              title="Assinadura pendente"
              medicalNoteData={medicalNoteData?.filter((e: any) => {
                return (
                  e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
                  "ProofOfOrigin"
                );
              })}
              solicitationData={solicitationData?.filter((e: any) => {
                return (
                  e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
                  "ProofOfOrigin"
                );
              })}
              resultsData={useFhirData.extractPatientSummaryResource(
                summary,
                "DocumentReference",
                "resultado"
              )}
              NoteReportData={NoteReportData?.filter((e: any) => {
                return (
                  e?.resource?.securityLabel?.[0]?.coding?.[0]?.code !==
                  "ProofOfOrigin"
                );
              })}
              data={summary}
            />
          ) : (
            <></>
          )}
        </Stack>
        {access?.type === "professional" && (
          <ClinicalEncounter
            verifyVisualizer={verifyVisualizer}
            title="Atendimentos"
            openVisualizer={handleOpenVisualizer}
            data={auxEncounter}
          />
        )}
        {access?.type === "professional" && (
          <ObservationsEncounter
            verifyVisualizer={verifyVisualizer}
            title="Observações"
            data={auxEncounter}
            openVisualizer={handleOpenVisualizer}
          />
        )}
        <Prescriptions
          verifyVisualizer={verifyVisualizer}
          openVisualizer={handleOpenVisualizer}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
          title="Medicamentos"
          data={
            useFhirData.extractPatientSummaryResource(
              summary,
              "MedicationRequest"
            )?.[0]?.entry
          }
          documentReference={prescriptionDocuments?.[0]?.entry?.filter(
            (el: any) => el?.resource?.resourceType === "DocumentReference"
          )}
        />

        <ServiceRequest
          verifyVisualizer={verifyVisualizer}
          openVisualizer={handleOpenVisualizer}
          setRefetch={setRefetch}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
          title="Exames"
          exames={useFhirData.extractPatientSummaryResource(
            summary,
            "ServiceRequest"
          )}
          data={useFhirData.extractPatientSummaryResource(
            summary,
            "DocumentReference",
            "service-request"
          )}
          resultsData={resultsData}
        />
        <Diagnosis
          verifyVisualizer={verifyVisualizer}
          openVisualizer={handleOpenVisualizer}
          title="Diagnósticos"
          data={useFhirData.extractPatientSummaryResource(summary, "Condition")}
        />

        <Allergies
          verifyVisualizer={verifyVisualizer}
          openVisualizer={handleOpenVisualizer}
          title="Alergias"
          data={useFhirData.extractPatientSummaryResource(
            summary,
            "AllergyIntolerance"
          )}
        />

        <ProcedureRequest
          verifyVisualizer={verifyVisualizer}
          openVisualizer={handleOpenVisualizer}
          handleOpen={handleOpen}
          setBinaryUrl={setBinaryUrl}
          title="Procedimentos"
          procedimentos={useFhirData.extractPatientSummaryResource(
            summary,
            "ServiceRequest"
          )}
          data={useFhirData.extractPatientSummaryResource(
            summary,
            "DocumentReference",
            "service-request"
          )}
          resultsData={resultsData}
        />

        {responseViewer && selectedResponse && (
          <ClinicalEncounterVisualizer
            data={selectedResponse}
            handleClose={handleCloseResponseViewer}
            isOpen={responseViewer}
          />
        )}

        {open && (
          <IframeDialog
            setRefetchSummary={setRefetch}
            handleClose={handleClose}
            isOpen={open}
            data={binaryUrl}
          />
        )}
      </Stack>
    </>
  );
}
