import React from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle, Stack } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { toast } from "react-toastify";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";

interface IProps {
  type: "add" | "edit";
  isOpen: boolean;
  handleClose: VoidFunction;
  display: any;
  control: any;
  handleSubmit: any;
}
export function MaritalStatusDialog({
  type,
  isOpen,
  handleClose,
  display,
  control,
  handleSubmit,
}: IProps) {
  const { userPatient, fetchUser } = useSessionContext();

  const maritalStatusCode = [
    {
      value: "Unknown",
      display: "Desconhecido",
      code: "UNK",
    },
    {
      value: "Divorced",
      display: "Divorciado",
      code: "D",
    },
    {
      value: "Legally Separated",
      display: "Separação Judicial",
      code: "L",
    },
    {
      value: "Never Married",
      display: "Nunca Casado",
      code: "S",
    },
    {
      value: "Married",
      display: "Casado(a)",
      code: "M",
    },
    {
      value: "unmarried",
      display: "Solteiro(a)",
      code: "U",
    },
    {
      value: "Widowed",
      display: "Viúvo(a)",
      code: "W",
    },
  ];

  const auxDefault = maritalStatusCode?.find(
    (el: any) => el.display === display
  );

  const auxFindMaritalStatus = userPatient?.maritalStatus;

  const onSubmit = async (data: any) => {
    const auxDisplay = maritalStatusCode?.find(
      (el: any) => el.value === data.maritalStatus?.value
    )?.display;

    const path = "/maritalStatus";
    const content = {
      coding: [
        {
          system: "https://saude.gov.br/fhir/terminologia/BREstadoCivil",
          code: data?.maritalStatus?.code,
          display: auxDisplay,
        },
      ],
    };

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: auxFindMaritalStatus === undefined ? [content] : content,
      });
      await fetchUser();
      toast.success(
        `Estado civil ${
          type === "add" ? "adicionado" : "atualizado"
        } com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>
        {type === "add" ? "Adicionar" : "Atualizar"} Estado civil
        <Stack marginY={2}>
          <FormSelectCompleted
            defaultValue={auxDefault}
            name="maritalStatus"
            label=""
            control={control}
            selectOptions={maritalStatusCode}
            required
          />
        </Stack>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </DialogTitle>
    </CommonDialog>
  );
}
