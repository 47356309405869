import { Grid, Tab } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { usePrescriptionAttendenceContext } from "../../../../contexts/PrescriptionAttendenceContext";
import { useDatetime } from "../../../../utils/useDatetime";
import { useFhirData } from "../../../../utils/useFhirData";
import { useFormatter } from "../../../../utils/useFormatter";
import { ReadOnlyField } from "../ReadOnlyField";
import { AllergiesTab } from "./components/AllergiesTab";
import { ConditionsTab } from "./components/ConditionsTab";
import { CreateResourceDialog } from "./components/CreateResourceDialog/CreateResourceDialog";
import { MedicationsTab } from "./components/MedicationsTab";

type SelectedTabType = "1" | "2" | "3";

export function PatientInfo({
  patientData,
  patientReference,
  references,
  patientSummary,
  setRefetch,
}: {
  patientData?: fhir5.Patient;
  patientReference?: fhir5.Reference;
  references?: any;
  patientSummary?: any;
  setRefetch: any;
}) {
  const { isOpenDiagnostic, setIsOpenDiagnostic, tabSelected } =
    usePrescriptionAttendenceContext();
  const { formatDate } = useDatetime;
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>("1");

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleChangeTab = (e: React.SyntheticEvent, value: SelectedTabType) => {
    setSelectedTab(value);
  };

  const patientName =
    patientData?.name?.[0]?.text || patientReference?.display || "-";

  const data = [
    { label: "Nome do paciente", description: formatNameDisplay(patientName) },
    {
      label: "Sexo de nascimento",
      description: formatGender(patientData?.gender) || "-",
    },
    {
      label: "Idade",
      description: patientData?.birthDate
        ? `${formatAge(`${patientData?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Data de nascimento",
      description: patientData?.birthDate
        ? formatDate(`${patientData?.birthDate}`)
        : "-",
    },
  ];

  const allergiesList = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "AllergyIntolerance"
  );

  const conditionsList = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "Condition"
  );

  const statementList = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "MedicationStatement"
  );

  const medicationRequestList = useFhirData.extractPatientSummaryResource(
    patientSummary,
    "DocumentReference",
    "prescricao"
  );

  function SelectedResourceType() {
    if (selectedTab === "2") return "Condition";
    if (tabSelected === "2") return "Condition";
    if (selectedTab === "3") return "MedicationStatement";
    else return "AllergyIntolerance";
  }

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={6}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container direction="row" rowSpacing={3} columnSpacing={3}>
            {data?.map((el) => (
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={6}
                key={`patient-information-${el.label}`}
              >
                <ReadOnlyField
                  title={el.label}
                  description={el.description}
                  icon={false}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <TabContext value={selectedTab}>
                <TabList onChange={handleChangeTab}>
                  <Tab
                    value="1"
                    label="Alergias"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    value="2"
                    label="Diagnósticos"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    value="3"
                    label="Medicamentos e prescrições"
                    sx={{ textTransform: "none" }}
                  />
                </TabList>

                <TabPanel value="1" sx={{ padding: 0 }}>
                  <AllergiesTab
                    data={allergiesList?.[0]?.entry || []}
                    openDialog={() => setOpenDialog(true)}
                  />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 0 }}>
                  <ConditionsTab
                    data={conditionsList?.[0]?.entry || []}
                    openDialog={() => setOpenDialog(true)}
                  />
                </TabPanel>
                <TabPanel value="3" sx={{ padding: 0 }}>
                  <MedicationsTab
                    statementData={statementList?.[0]?.entry || []}
                    medicationRequestData={
                      medicationRequestList?.[0]?.entry || []
                    }
                    openDialog={() => setOpenDialog(true)}
                  />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {(isOpenDiagnostic || openDialog) && (
        <CreateResourceDialog
          references={references}
          isOpen={isOpenDiagnostic || openDialog}
          setRefetch={setRefetch}
          handleClose={() => {
            isOpenDiagnostic
              ? setIsOpenDiagnostic(false)
              : setOpenDialog(false);
          }}
          resourceType={SelectedResourceType()}
        />
      )}
    </>
  );
}
