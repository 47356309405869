import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Step,
  StepContent,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";

import { useFormatter } from "../../../../utils/useFormatter";
import { useDatetime } from "../../../../utils/useDatetime";
import {
  ChevronLeft,
  ChevronRight,
  History,
  WhatsApp,
} from "@mui/icons-material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useInternationalVerify } from "../../../../utils/useInternationalVerify";

export function Header() {
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;
  const { formatDate } = useDatetime;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const { setOpenSummary, patient } = useMedicalCareContext();
  const handleClickSummary = () => {
    setOpenSummary(true);
  };

  const steps = ["data1", "data2"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  type DemographicDataType = {
    [key: string]: {
      label: string;
      display: any;
    };
  };

  const auxType = {
    raceExists: patient?.extension?.find(
      (el: any) =>
        el.url ===
        "http://www.saude.gov.br/fhir/StructureDefinition/BRRacaCorEtnia-1.0"
    ),
    genderExists: patient?.extension?.find(
      (el: any) =>
        el.url ===
        "http://www.saude.gov.br/fhir/StructureDefinition/BRIdentidadeGenero"
    ),
    educationExists: patient?.extension?.find(
      (el: any) =>
        el.url === "http://terminology.hl7.org/ValueSet/v3-EducationLevel"
    ),
    religionExist: patient?.extension?.find(
      (el: any) =>
        el.url === "http://terminology.hl7.org/ValueSet/v3-ReligiousAffiliation"
    ),
    maritalStatusExists: patient?.maritalStatus,
  };

  const demographicData: DemographicDataType = {
    displayRace: {
      label: "Raça/Cor",
      display: auxType?.raceExists?.valueCodeableConcept?.coding?.[0]?.display,
    },
    displayGenderIdentity: {
      label: "Identidade de gênero",
      display:
        auxType?.genderExists?.valueCodeableConcept?.coding?.[0]?.display,
    },
    displayEducation: {
      label: "Escolaridade",
      display:
        auxType?.educationExists?.valueCodeableConcept?.coding?.[0]?.display,
    },
    displayReligion: {
      label: "Religião",
      display:
        auxType?.religionExist?.valueCodeableConcept?.coding?.[0]?.display,
    },
    displayMaritalStatus: {
      label: "Estado civil",
      display: auxType?.maritalStatusExists?.coding?.[0]?.display,
    },
  };

  const auxAddress = patient?.address?.[0];

  const city = auxAddress?.city || "";
  const addressState = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, addressState, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  const phone = patient?.telecom?.find((e: any) => e.system === "phone")?.value;

  const cpf = patient?.id?.split("-")?.[1];

  const data = [
    {
      label: "Nome",
      description: formatNameDisplay(patient?.name?.[0]?.text || "-"),
    },
    {
      label: "CPF",
      description: cpf,
    },
    {
      label: "Telefone",
      description: (
        <>
          <Stack
            sx={{ height: "20px" }}
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography minWidth="150px">
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                      phone
                    )}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      ),
    },

    {
      label: "Sexo de nascimento",
      description: formatGender(patient?.gender) || "-",
    },
    {
      label: "Idade",
      description: patient?.birthDate
        ? `${formatAge(`${patient?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Data de nascimento",
      description: patient?.birthDate
        ? formatDate(`${patient?.birthDate}`)
        : "-",
    },
    {
      label: "Endereço",
      description: address || "-",
    },
  ];

  const demographicsData = [
    {
      label: demographicData?.displayEducation?.label,
      description: demographicData?.displayEducation?.display || "-",
    },
    {
      label: demographicData?.displayReligion?.label,
      description: demographicData?.displayReligion?.display || "-",
    },
    {
      label: demographicData?.displayMaritalStatus?.label,
      description: demographicData?.displayMaritalStatus?.display || "-",
    },
    {
      label: demographicData?.displayGenderIdentity?.label,
      description: demographicData?.displayGenderIdentity?.display || "-",
    },
  ];

  return (
    <>
      <Box pl={6} sx={{ direction: "row" }}>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
              gap: 2,
            }}
          >
            <Tooltip title="Anterior">
              <IconButton onClick={handleBack} disabled={activeStep === 0}>
                <ChevronLeft
                  sx={{
                    color:
                      activeStep === 0 ? "neutral700.main" : "primary800.main",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 2,
              }}
            >
              {activeStep === 0 && (
                <Stack
                  direction="row"
                  sx={{ width: "100%" }}
                  gap={4}
                  justifyContent={"space-between"}
                >
                  {data?.map((el) => (
                    <Stack direction="column">
                      <Stack direction="row">
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {el.label}
                        </Typography>
                      </Stack>
                      <Typography variant="body1" color="neutral700.main">
                        {el.description}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              )}

              {activeStep === 1 && (
                <Stack direction="row" sx={{ width: "100%" }} gap={6}>
                  {demographicsData?.map((el) => (
                    <Stack
                      direction="column"
                      key={`patient-information-${el.label}`}
                    >
                      <Stack direction="row">
                        <Typography
                          variant="body1"
                          color="neutral1000.main"
                          fontWeight={500}
                        >
                          {el.label}
                        </Typography>
                      </Stack>
                      <Typography variant="body1" color="neutral700.main">
                        {el.description}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              )}
            </Box>

            <Tooltip title="Próximo">
              <IconButton
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                <ChevronRight
                  sx={{
                    color:
                      activeStep === steps.length - 1
                        ? "neutral700.main"
                        : "primary800.main",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>

          <Button
            sx={{
              width: "200px",
              textTransform: "none",
            }}
            startIcon={<History />}
            onClick={handleClickSummary}
          >
            Sumário de saúde
          </Button>
        </Stack>
      </Box>
    </>
  );
}
