import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DataSkeleton } from "../../../components/common";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import ScheduleCancelDialog from "../../../components/schedule/ScheduleCancelDialog";
import { useSessionContext } from "../../../contexts/SessionContext";
import { GetAppointment } from "../../../services/fhir";
import { useFhirData } from "../../../utils/useFhirData";
import { useFormatter } from "../../../utils/useFormatter";
import { Actions } from "./Actions";

export function AppointmentAccordion() {
  const { user } = useSessionContext();
  const { formatDateTimeToHour } = useFormatter;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const openCancelDialog = (id: any) => {
    setSelectedId(id);
    setIsOpen(true);
  };

  const [appointment, setAppointment] =
    useState<fhir5.Bundle<fhir5.Appointment>>();
  const [openAccordion, setOpenAccordion] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const columns = [
    { column: "Serviço", rowName: "healthcare" },
    { column: "Clínica", rowName: "location" },
    { column: "Médico", rowName: "practitioner" },
    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
    { column: "Status", rowName: "status" },
  ];

  useEffect(() => {
    async function fetchAppointment() {
      setLoading(true);
      const response = await GetAppointment({
        ehrrunner: `${user?.username}`,
        startDate: dayjs().format("YYYY-MM-DD"),
        status: ["booked"],
      });
      setAppointment(response);
      setLoading(false);
    }

    fetchAppointment();
  }, [user.username, refetch]);

  if (loading) {
    return <DataSkeleton />;
  }

  const rows = appointment?.entry
    ?.filter(
      (el: any) =>
        el.resource?.status !== "cancelled" &&
        new Date(el?.resource?.end) > new Date(Date.now())
    )
    ?.map((row: any) => {
      const location = useFhirData.findParticipant(
        row?.resource?.participant,
        "Location"
      )?.display;
      const practitioner = useFhirData.findParticipant(
        row?.resource?.participant,
        "Practitioner"
      )?.display;
      const healthcare = useFhirData.findParticipant(
        row?.resource?.participant,
        "HealthcareService"
      )?.display;

      const hour = row?.resource?.start
        ? formatDateTimeToHour(row?.resource?.start)
        : "-";
      const date = row?.resource?.start
        ? dayjs(row?.resource?.start)?.format("DD/MM/YYYY ")
        : "-";

      const status = row.resource?.status || "-";
      return {
        location,
        practitioner,
        healthcare,
        hour,
        date,
        status,
        id: row.resource.id,
      };
    });

  const handleChange = () => {
    setOpenAccordion((prev) => !prev);
  };

  return (
    <Accordion expanded={openAccordion} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Agendamentos e consultas</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rows}
          actions={(rowData: any) => (
            <Actions row={rowData} openCancelDialog={openCancelDialog} />
          )}
        />
      </AccordionDetails>

      <ScheduleCancelDialog
        appointment={
          appointment?.entry?.find((el: any) => el.resource.id === selectedId)
            ?.resource
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRefetch={setRefetch}
      />
    </Accordion>
  );
}
