import { DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { RecoverPassword } from "../../../../services/fhir";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useFirebaseService } from "../../../../utils/useFirebase";
import { EditPasswordSteps } from "../../Types";
import ConfirmPhoneContent from "./ConfirmPhoneContent";
import InfoContent from "./InfoContent";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface IFormInput {
  confirmationCode: string;
}

export function EditPasswordDialog({ isOpen, setIsOpen }: IProps) {
  const { user, fetchUser } = useSessionContext();
  const { verifyCode, sendVerificationCode } = useFirebaseService;
  const [step, setStep] = useState(EditPasswordSteps.S1Info);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    confirmationCode: "",
  };

  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function handleClose() {
    setIsOpen(false);
    setStep(EditPasswordSteps.S1Info);
  }

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    try {
      await verifyCode(data.confirmationCode);

      const response = await RecoverPassword(user.username, "application");
      if (response?.status === HTTPResponseStatus.Success) {
        await fetchUser();
        toast.success(
          "E-mail com instruções de edição de senha enviado com sucesso"
        );
        setIsOpen(false);
        return;
      }
      throw new Error();
    } catch (error: any) {
      if (
        error?.code === "auth/argument-error" ||
        error?.code === "auth/invalid-verification-code"
      ) {
        toast.error("Código inválido");
        return;
      }
      toast.error(
        "Houve um erro no envio do e-mail de edição de senha, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = async () => {
    setLoading(true);
    await sendVerificationCode(user.phone)
      .then(() => {
        setStep(EditPasswordSteps.S2ConfirmPhone);
      })
      .catch((err) => {
        console.log("EditPasswordDialog onConfirm", err);
        toast.error(
          "Não foi possível enviar o código de verificação, por favor, tente novamente mais tarde"
        );
      })
      .finally(() => setLoading(false));
  };

  const resendCode = () => {
    sendVerificationCode(user.phone);
  };

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={isOpen}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">Editar senha</DialogTitle>
      {step === EditPasswordSteps.S1Info && (
        <InfoContent
          loading={loading}
          onCancelButtonClick={handleClose}
          onSendButtonClick={handleSubmit(onConfirm)}
        />
      )}
      {step === EditPasswordSteps.S2ConfirmPhone && (
        <ConfirmPhoneContent
          control={control}
          onGoBackButtonClick={() => setStep(EditPasswordSteps.S1Info)}
          onResendButtonClick={resendCode}
          onSubmitButtonClick={handleSubmit(onSubmit)}
          loading={loading}
        />
      )}
    </CommonDialog>
  );
}
