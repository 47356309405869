import { Add, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack
} from "@mui/material";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

export function Certificate({
  control,
  handleSubmit,
  setClean,
  action,
  setValue,
  isEdit,
}: any) {
  const { user } = useSessionContext();

  const [renderField, setRenderField] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, setType] = useState();

  const onSubmit = async (data: any) => {
    try {
      if (!data?.certificate) {
        toast.warn("Selecione um exame para realizar a solicitação");
        throw new Error("Campo vazio");
      } else {
        const auxItem = {
          tabValue: "3",
          type: "certificate",
          name: "Relatório",
          tag: "",
          form: "",
          note: data?.certificate,
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err");
    } finally {
      if (data?.certificate) setRenderField(false);
      setClean(true);
    }
  };

  useEffect(() => {
    if (type === 1) {
      setValue(
        "certificate",
        `Atestado para os defidos fins que o paciente ${user?.name} de CPF ${user?.username} está apto...`
      );
    }
    if (type === 2) {
      setValue(
        "certificate",
        `Atestado para os defidos fins que o paciente ${user?.name} de CPF ${user?.username} compareceu...`
      );
    }
    setRenderField(false);
  }, [type, setValue, user]);

  useEffect(() => {
    setRenderField(true);
  }, [renderField]);

  return (
    <>
      <Box sx={{ width: "100%", maxHeight: "60vh", overflow: "auto" }}>
        {renderField && (
          <InputText
            multiline
            minRows={4}
            maxRows={100}
            label="Relatório"
            name="certificate"
            control={control}
            autoFocus={true}
          />
        )}

        <Stack width="100%" direction="row" mt={2} justifyContent="right">
          <Button
            sx={{ width: "fit-content", border: "1px solid #007C6D" }}
            onClick={handleSubmit(onSubmit)}
            variant="text"
          >
            {isEdit ? <Edit /> : <Add />}
          </Button>
        </Stack>
      </Box>
    </>
  );
}
