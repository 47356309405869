import { NotesRounded } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { useSessionContext } from "../../contexts/SessionContext";
import { ProtocolSettings } from "./ProtocolSettings";

export function useProtocolSettings() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    active: access?.type === "professional",
    path: "/protocolsettings",
    icon: NotesRounded,
    text: "Gestão de protocolos",

    component: () => <ProtocolSettings />,
  };

  return configs;
}
