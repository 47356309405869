import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useSessionContext } from "./SessionContext";
import { GetOrganizations } from "../services/fhir";
import { oids } from "../configs/Settings";
import { LocationContextProvider } from "./LocationContext";
import { HealthcareServiceContextProvider } from "./HealthcareServiceContext";
import { PractitionerRoleContextProvider } from "./PractitionerRoleContext";
import { PatientOrganizationContextProvider } from "./PatientOrganizationContext";
interface OrganizationContextValue {
  refetchOrganization: Function;
  resetStates: Function;
  organization: fhir5.Organization | undefined;
  organizationList: fhir5.BundleEntry<fhir5.Organization>[] | undefined;
  refetchOrganizationList: Function;
  loading: boolean;
  organizationId: string;
  setOrganizationId: Dispatch<SetStateAction<string>>;
}

export const OrganizationContext = createContext<OrganizationContextValue>(
  null!
);

interface OrganizationContextProviderProps {
  children: React.ReactNode;
}

export function OrganizationContextProvider({
  children,
}: OrganizationContextProviderProps) {
  const { access, user } = useSessionContext();

  const [organization, setOrganization] = useState<fhir5.Organization>();
  const [organizationId, setOrganizationId] = useState<string>('');

  const [organizationList, setOrganizationList] =
    useState<fhir5.BundleEntry<fhir5.Organization>[]>();

  const [loading, setLoading] = useState(false);

  const [refetch, setRefetch] = useState<boolean>(false);

  function refetchOrganization() {
    setRefetch(!refetch);
  }

  const [refetchList, setRefetchList] = useState<boolean>(false);

  function refetchOrganizationList() {
    setRefetchList(!refetchList);
  }

  const resetStates = () => {
    setOrganization(undefined);
  };

  useEffect(() => {
    async function fetchOrganization() {
      if (!access?.organizationId) {
        setOrganization(undefined);
        setOrganizationId('');
        return;
      }

      const organizationResponse = await GetOrganizations({
        orgIds: `${oids.cnpj}-${access.organizationId}`,
      });
      setOrganization(organizationResponse);
      setOrganizationId(access.organizationId);
    }

    setLoading(true);
    async function asyncCalls() {
      try {
        await fetchOrganization();
      } catch (err) {
        console.error('Erro ao carregar organização:', err);
        setOrganization(undefined);
        setOrganizationId('');
      } finally {
        setLoading(false);
      }
    }
    if (access.type === "professional") asyncCalls();
  }, [access.organizationId, access.type, refetch, user.username]);

  useEffect(() => {
    async function fetchOrganizationsList() {
      const response = await GetOrganizations({ content: "socio", count: 350 });
      setOrganizationList(response?.entry);
    }
    fetchOrganizationsList();
  }, [refetchList]);

  const contextValue: OrganizationContextValue = {
    resetStates,
    refetchOrganization,
    organizationList,
    refetchOrganizationList,
    organization,
    loading,
    organizationId,
    setOrganizationId,
  };

  return (
    <OrganizationContext.Provider value={contextValue}>
      <LocationContextProvider>
        <HealthcareServiceContextProvider>
          <PractitionerRoleContextProvider>
            <PatientOrganizationContextProvider>
              {children}
            </PatientOrganizationContextProvider>
          </PractitionerRoleContextProvider>
        </HealthcareServiceContextProvider>
      </LocationContextProvider>
    </OrganizationContext.Provider>
  );
}

export function useOrganizationContext() {
  return useContext(OrganizationContext);
}
