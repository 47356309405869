import { Dialog, DialogTitle, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LargeContentBox, PageTitle } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { GetAdminQuestionnaireResponse } from "../../services/fhir/questionnaireresponse/GetAdminQuestionnaireResponse";
import { ConfigureDocument } from "./components/ConfigureDocument";
import { DocumentsRegistered } from "./components/DocumentsRegistered";

const defaultValues = {
  documentType: undefined,
  documentName: undefined,
};

export function PersonPdf() {
  const [fileBase64, setFileBase64] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);

  const [dataRegister, setDataRegister] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organization } = useOrganizationContext();
  const [refetch, setRefetch] = useState(false);

  const methods = useForm({ defaultValues });
  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    async function getQuestionnaireResponse() {
      setLoading(true);
      try {
        const response = await GetAdminQuestionnaireResponse({
          questionnaire: "customPdf",
          author: organization?.id,
        });
        setDataRegister(response?.entry);
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
        setRefetch(false);
      }
    }
    getQuestionnaireResponse();
  }, [organization?.id, refetch]);

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <PageTitle>Modelos de documentos</PageTitle>

          <DocumentsRegistered
            dataRegister={dataRegister}
            setOpenModal={setOpenModal}
            loading={loading}
            setLoading={setLoading}
            setRefetch={setRefetch}
          />

          {openModal && (
            <Dialog
              open={openModal}
              maxWidth="lg"
              fullWidth
              keepMounted
              onClose={setOpenModal}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle textAlign="center">Novo modelo</DialogTitle>

              <ConfigureDocument
                dataRegister={dataRegister}
                control={control}
                handleSubmit={handleSubmit}
                fileBase64={fileBase64}
                setFileBase64={setFileBase64}
                setOpenModal={setOpenModal}
                reset={reset}
                setRefetch={setRefetch}
              />
            </Dialog>
          )}
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
