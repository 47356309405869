import { endpoints } from "../../configs/Settings";
import { useAxios } from "../../utils/useAxios";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";

export async function DeleteWhereby(id: string) {
  const url = `${endpoints.WHEREBY}/${id}`;

  const headers = {
    Authorization: await useAuth.getUserToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const response = await useAxios
    .delete(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`DeleteWhereby: ${response.status}`);
      }
    })
    .catch((error) => {
      console.error("DeleteWhereby", error);
      // toast.error(`Erro ao realizar o delete do whereby`);
      return {
        error: true,
      };
    });
  if (response) return response;
}
