import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { IdentificationFields } from "../IdentificationFields";
export function AnamneseHistoryAccordion({ filter, data, index }: any) {
  const [expanded, setExpanded] = useState(index === 0 ? true : false);

  const handleChange = (event: any, isExpanded: any) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        minWidth: "400px",
        flexGrow: 1,
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {filter === 1 ? (
          <Typography>
            {data?.filterResponse?.[0]?.answer?.[0]?.valueString} {index}
          </Typography>
        ) : (
          <Stack direction="row" width="100%" spacing={2}>
            <Stack direction="row" width="100%" spacing={2}>
              <Chip
                label={data?.displayInfo?.healthcare || "Sem especialidade"}
                size="small"
                sx={{
                  color: data?.displayInfo?.healthcare
                    ? "neutral700.main"
                    : "neutral500.main",

                  fontWeight: 500,
                  backgroundColor: data?.displayInfo?.healthcare
                    ? ""
                    : "#f1f1f1",
                }}
              />
            </Stack>
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {filter === 1 ? (
          <IdentificationFields />
        ) : (
          <Stack spacing={1}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                padding: 1,
                paddingLeft: 2,
                border: "1px solid",
                borderWidth: 1,
                borderRadius: 2,
                borderColor: "#ccc",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography
                  fontWeight={600}
                  sx={{
                    color: "neutral600.main",
                  }}
                >
                  Queixa Principal
                </Typography>
              </Stack>
              <Typography
                fontWeight={400}
                sx={{
                  color: "neutral700.main",
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {`${
                  data?.filterResponse?.[0]?.answer?.[0]?.valueString || "-"
                }`}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              sx={{
                width: "100%",
                padding: 1,
                paddingLeft: 2,
                border: "1px solid",
                borderWidth: 1,
                borderRadius: 2,
                borderColor: "#ccc",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography fontWeight={600} sx={{ color: "neutral600.main" }}>
                  Histórial atual
                </Typography>
              </Stack>
              <Typography
                fontWeight={400}
                sx={{
                  color: "neutral700.main",
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >{`${
                data?.filterResponse?.[1]?.answer?.[0]?.valueString || "-"
              }`}</Typography>
            </Stack>

            <Stack
              direction="column"
              sx={{
                width: "100%",
                padding: 1,
                paddingLeft: 2,
                border: "1px solid",
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#ccc",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography fontWeight={600} sx={{ color: "neutral600.main" }}>
                  História patologica
                </Typography>
              </Stack>
              <Typography
                sx={{
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >{`${
                data?.filterResponse?.[2]?.answer?.[0]?.valueString || "-"
              }`}</Typography>
            </Stack>

            <Stack
              direction="column"
              sx={{
                width: "100%",
                padding: 1,
                paddingLeft: 2,
                border: "1px solid",
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#ccc",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography fontWeight={600} sx={{ color: "neutral600.main" }}>
                  História social
                </Typography>
              </Stack>
              <Typography
                sx={{
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >{`${
                data?.filterResponse?.[3]?.answer?.[0]?.valueString || "-"
              }`}</Typography>
            </Stack>

            <Stack
              direction="column"
              sx={{
                width: "100%",
                padding: 1,
                paddingLeft: 2,
                border: "1px solid",
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#ccc",
              }}
            >
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography fontWeight={600} sx={{ color: "neutral600.main" }}>
                  História familiar
                </Typography>
              </Stack>
              <Typography
                sx={{
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >{`${
                data?.filterResponse?.[4]?.answer?.[0]?.valueString || "-"
              }`}</Typography>
            </Stack>
          </Stack>
        )}
        <Divider sx={{ my: 2 }}></Divider>
        <Typography
          color="neutral700.main"
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
        >
          Dr. {data?.displayInfo?.practitioner}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
