import { PeopleAlt } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { CardProfessional } from "./components/CardProfessional";
import { HeaderScheduling } from "./components/HeaderScheduling";
import { SlotsMobile } from "./components/SlotsMobile";

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  location: fhir5.Location;
  practitioners: any;
  slots: fhir5.BundleEntry<fhir5.Slot> | any;
  loading: boolean;
  setRefetchSlots: Dispatch<SetStateAction<boolean>>;
  selectedPractitioner: number;
  setSelectedPractitioner: Dispatch<SetStateAction<number>>;
}

export function SchedulingScreenMobile({
  open,
  onClose,
  location,
  practitioners,
  slots,
  loading,
  setRefetchSlots,
  selectedPractitioner,
  setSelectedPractitioner,
}: IProps) {
  return (
    <ScreenMobile title="Agendamento" open={open} onClose={onClose}>
      <Stack gap={1} mt={1} width="100%" height="100%" maxHeight="500px">
        <HeaderScheduling location={location} />

        <Stack gap={1} alignItems="center" direction="row" width="100%">
          <PeopleAlt fontSize="small" />
          <Typography variant="body2" color="neutral700.main">
            Escolha um dos profissionais disponíveis:
          </Typography>
        </Stack>
        <Stack width="100%" gap={2}>
          {practitioners?.map((el: any, index: number) => (
            <>
              <CardProfessional
                namePractitioner={el?.display}
                healthcare={el?.healthcare?.display}
                selectedPractitioner={selectedPractitioner === index}
                onClick={() => setSelectedPractitioner(index)}
              />
            </>
          ))}
        </Stack>
        <Divider sx={{ width: "100%", background: "neutral400.main" }} />

        <Typography variant="body2" fontWeight={600} color="neutral700.main">
          Horários disponíveis
        </Typography>

        <SlotsMobile
          slots={slots}
          loading={loading}
          location={location}
          setRefetchSlots={setRefetchSlots}
        />
      </Stack>
    </ScreenMobile>
  );
}
