import React from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle, Stack } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { toast } from "react-toastify";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";

interface IProps {
  type: "add" | "edit";
  isOpen: boolean;
  handleClose: VoidFunction;
  display: any;
  control: any;
  handleSubmit: any;
}
export function ReligionDialog({
  type,
  isOpen,
  handleClose,
  display,
  control,
  handleSubmit,
}: IProps) {
  const { userPatient, fetchUser } = useSessionContext();

  const religionCodes = [
    {
      value: "Roman Catholic Church",
      display: "Catolicismo Romano",
      code: "1041",
    },
    {
      value: "Evangelical Covenant",
      display: "Evangelicalismo",
      code: "1070",
    },
    {
      value: "African Religions",
      display: "Religiões africanas",
      code: "1002",
    },
    {
      value: "Spiritualism",
      display: "Espiritismo",
      code: "1048",
    },
    {
      value: "Islam",
      display: "Islamismo",
      code: "1023",
    },
    {
      value: "Judaism",
      display: "Judaismo",
      code: "1026",
    },
    {
      value: "Hinduism",
      display: "Induísmo",
      code: "1020",
    },
    {
      value: "Zen Buddhism",
      display: "Budismo",
      code: "1059",
    },
    {
      value: "Protestant",
      display: "Protestante",
      code: "1077",
    },
    {
      value: "Jehovah's Witnesses",
      display: "Testemunha de Jeová",
      code: "1025",
    },
    {
      value: "Celticism",
      display: "Ceticismo",
      code: "1012",
    },
    {
      value: "Atheism",
      display: "Nenhuma religião",
      code: "1007",
    },
  ];

  const auxDefault = religionCodes?.find((el: any) => el.display === display);

  function verifyPath(extensionReligion: any) {
    if (extensionReligion === undefined) {
      return "/extension";
    }
    if (extensionReligion === -1) {
      return "/extension/-";
    } else {
      return `/extension/${extensionReligion}`;
    }
  }

  const auxFindExtensionReligion = userPatient?.extension?.findIndex(
    (el: any) =>
      el.url === "http://terminology.hl7.org/ValueSet/v3-ReligiousAffiliation"
  );

  const onSubmit = async (data: any) => {
    const auxDisplay = religionCodes?.find(
      (el: any) => el.value === data.religion?.value
    )?.display;

    const path = verifyPath(auxFindExtensionReligion);
    const content = {
      url: "http://terminology.hl7.org/ValueSet/v3-ReligiousAffiliation",
      valueCodeableConcept: {
        coding: [
          {
            system:
              "http://terminology.hl7.org/CodeSystem/v3-ReligiousAffiliation",
            code: data?.religion?.code,
            display: auxDisplay,
          },
        ],
      },
    };

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: auxFindExtensionReligion === undefined ? [content] : content,
      });
      await fetchUser();
      toast.success(
        `Religião ${type === "add" ? "adicionada" : "atualizada"} com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>
        {type === "add" ? "Adicionar" : "Atualizar"} Religião
        <Stack marginY={2}>
          <FormSelectCompleted
            defaultValue={auxDefault}
            name="religion"
            label=""
            control={control}
            selectOptions={religionCodes}
            required
          />
        </Stack>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </DialogTitle>
    </CommonDialog>
  );
}
