import { Add } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";
import { ExamsFields } from "../ExamsFields";
import { ProcedureAndCertificate } from "../ProcedureAndCertificate";

// interface TabPanelProps {
//   children?: ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ maxHeight: "60vh", pt: 2, overflow: "auto" }}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export function ProcedureAndExams({ setSelect, type }: any) {
  const {
    control,
    setValue,
    action,
    setClean,
    handleSubmit,
    isEdit,
    subTabValue,
    setSubTabValue,
  } = useInterventionsContext();

  // const handleChange = (event: SyntheticEvent, newValue: number) => {
  //   setSubTabValue(newValue);
  // };

  return (
    <Stack width="100%" maxHeight="60vh" overflow="auto">
      <Button
        sx={{ width: "fit-content", textTransform: "none" }}
        startIcon={<Add />}
        onClick={() => setSelect(true)}
      >
        Selecionar protocolo salvo
      </Button>

      {type === "exams" ? (
        <ExamsFields
          control={control}
          setValue={setValue}
          action={action}
          setClean={setClean}
          handleSubmit={handleSubmit}
          isEdit={isEdit}
          subTabValue={subTabValue}
          setSubTabValue={setSubTabValue}
        />
      ) : (
        <ProcedureAndCertificate
          control={control}
          setValue={setValue}
          action={action}
          setClean={setClean}
          handleSubmit={handleSubmit}
          isEdit={isEdit}
          subTabValue={subTabValue}
          setSubTabValue={setSubTabValue}
        />
      )}

      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={subTabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Exames" {...a11yProps(0)} />
          <Tab label="Procedimentos" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={subTabValue} index={0}>
        <ExamsFields />
      </CustomTabPanel>
      <CustomTabPanel value={subTabValue} index={1}>
        <ProcedureAndCertificate />
      </CustomTabPanel> */}
    </Stack>
  );
}
