import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { NoDataWarning } from "../../../../../../components/common";

interface IProps {
  data: any;
}

// This function is util function
export function unitTransformer({
  unit,
  value,
}: {
  unit: string;
  value?: number;
}) {
  unit = unit?.toLowerCase();

  switch (unit) {
    case "comprimido":
      return (value ?? 1) > 1 ? "comprimidos" : "comprimido";
    case "m":
      return (value ?? 1) > 1 ? "minutos" : "minuto";
    case "s":
      return (value ?? 1) > 1 ? "segundos" : "segundo";
    case "h":
      return (value ?? 1) > 1 ? "horas" : "hora";
    case "min":
      return (value ?? 1) > 1 ? "minutos" : "minuto";
    case "d":
      return (value ?? 1) > 1 ? "dias" : "dia";
    default:
      return unit;
  }
}

export function RenderMedications({ data }: IProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (event: any) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedItems = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          marginBottom: 2,
          color: "text.secondary",
        }}
      >
        Medicamentos Declarado
      </Typography>
      {data && data?.length > 0 ? (
        <>
          {selectedItems?.map((element: any) => {
            const name = element?.resource?.medication?.concept?.text;
            // const doseQuantity =
            //   element?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]
            //     ?.doseQuantity?.value;
            // const doseQuantityUnit = unitTransformer({
            //   unit: element?.resource?.dosageInstruction?.[0]?.doseAndRate?.[0]
            //     ?.doseQuantity?.unit,
            //   value: doseQuantity,
            // });
            // const doseTimingFrequency =
            //   element?.resource?.dosage?.[0]?.timing?.repeat?.frequency;
            // const doseTimingDuration =
            //   element?.resource?.dosage?.[0]?.timing?.repeat?.duration;
            // const doseTimingDurationUnit = unitTransformer({
            //   unit: element?.resource?.dosage?.[0]?.timing?.repeat
            //     ?.durationUnit,
            //   value: doseTimingDuration,
            // });
            // const doseTimingPeriodUnit = unitTransformer({
            //   unit: element?.resource?.dosage?.[0]?.timing?.repeat?.periodUnit,
            //   value: doseTimingFrequency,
            // });

            // const dosage = `${doseQuantity} ${doseQuantityUnit} a cada ${doseTimingFrequency} ${doseTimingPeriodUnit} por ${doseTimingDuration} ${doseTimingDurationUnit}`;

            return (
              <Accordion
                sx={{
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.1)",
                  },
                  boxShadow: "none",
                  border: "0.2px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "16px",
                  marginBottom: 2,
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: 2,
                        marginBottom: 1,
                      }}
                    >
                      <Typography
                        align="center"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: 600,
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            );
          })}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              {" "}
              Mostrando {startIndex + 1} -{" "}
              {Math.min(startIndex + itemsPerPage, data.length)} de{" "}
              {data.length} itens{" "}
            </Typography>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Items por página</InputLabel>
              <Select
                value={itemsPerPage}
                onChange={handleChangeItemsPerPage}
                label="Items por página"
              >
                {[3, 5, 10].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              marginBottom: 4,
            }}
          />
        </>
      ) : (
        <>
          <NoDataWarning />
        </>
      )}
    </>
  );
}
