import { BubbleChat } from "flowise-embed-react";
import luxor from "../../assets/luxor.png";
import { useSessionContext } from "../../contexts/SessionContext";
import { useFormatter } from "../../utils/useFormatter";
import { endpoints } from "../../configs/Settings";

export function Chatbot() {
  const { access } = useSessionContext();
  const { user } = useSessionContext();
  return (
    <BubbleChat
      chatflowid={`${
        access?.type === "professional"
          ? endpoints.chatbotId
          : endpoints.chatbotIdPatient
      }`}
      //apiHost="http://localhost:3000"
      //apiHost="http://flowise.fluxmed.com:3000/api"
      apiHost={`${endpoints.chatbotHost}`}
      theme={{
        button: {
          backgroundColor: "#00ae9a",
          // right: 210,
          // bottom: 1052,
          right: 20,
          bottom: 20,
          size: 48,
          dragAndDrop: false,
          iconColor: "#ffffff",
          autoWindowOpen: {
            autoOpen: false, //parameter to control automatic window opening
            openDelay: 2, // Optional parameter for delay time in seconds
            autoOpenOnMobile: false, //parameter to control automatic window opening in mobile
          },
          // customIconSrc: luxor,
        },
        chatWindow: {
          showTitle: true,
          title: "Luxor",
          titleAvatarSrc: luxor,
          showAgentMessages: true,
          welcomeMessage: `Olá ${useFormatter.formatNameDisplay(
            user?.name?.split(" ")?.[0]
          )}! Como posso ajudar?`,
          errorMessage: "Erro! O Fluxmed Chatbot esta fora do ar no momento",
          backgroundColor: "#ffffff",
          backgroundImage: "enter image path or link", // If set, this will overlap the background color of the chat window.
          height: 550,
          width: 400,
          fontSize: 16,
          //starterPrompts: ['What is a bot?', 'Who are you?'], // It overrides the starter prompts set by the chat flow passed
          starterPromptFontSize: 15,
          clearChatOnReload: false, // If set to true, the chat will be cleared when the page reloads.
          poweredByTextColor: "#ffffff",
          botMessage: {
            backgroundColor: "#f7f8ff",
            textColor: "#303235",
            showAvatar: false,
            avatarSrc:
              "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
          },
          userMessage: {
            backgroundColor: "#00ae9a",
            textColor: "#ffffff",
            showAvatar: false,
            avatarSrc:
              "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
          },
          textInput: {
            placeholder: "Digite sua pergunta",
            backgroundColor: "#ffffff",
            textColor: "#303235",
            sendButtonColor: "#00ae9a",
            //maxChars: 50,
            //maxCharsWarningMessage: 'You exceeded the characters limit. Please input less than 50 characters.',
            autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
            //sendMessageSound: true,
            // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
            //receiveMessageSound: true,
            // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true.
          },
          // feedback: {
          //   color: "#303235",
          // },
          // footer: {
          //   textColor: "#303235",
          //   text: "Powered by",
          //   company: "Flowise",
          //   //companyLink: "https://flowiseai.com",
          // },
        },
      }}
    />
  );
}
