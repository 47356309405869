import axios from "axios";
import { ApiResponse, PreapprovalResponse, SubscriptionData } from "../../pages/registerorganization/types";



export class SubscriptionService {
  private readonly apiUrl = `${import.meta.env.VITE_ENDPOINT_BILLING}/subscribe`;

  async createSubscription(
    subscriptionData: SubscriptionData
  ): Promise<PreapprovalResponse> {
    try {
      const response = await axios.post<PreapprovalResponse>(
        this.apiUrl,
        subscriptionData
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "Erro ao enviar a solicitação de assinatura:",
          error.message
        );
        if (error.response) {
          console.error("Detalhes do erro:", error.response.data);
        }
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        console.error("Ocorreu um erro desconhecido:", error);
        throw new Error('Ocorreu um erro desconhecido ao criar a assinatura');
      }
    }
  }

    async getSubscriptionPlans(organizationId: string | null): Promise<
    {
      plan: PreapprovalResponse
    }[]
  > {
    try {
      let url = `${import.meta.env.VITE_ENDPOINT_BILLING}/subscriptionPlans`;
      if (organizationId !== null) {
        url += `/${organizationId}`;
      }

      const response = await axios.get<ApiResponse>(url);
      
      // Verifica se results existe na resposta
      const subscriptionPlans = response.data.results || [];

      const formattedPlans = subscriptionPlans.map((selectedPlan) => ({
        plan: selectedPlan
      }));

      return formattedPlans;
    } catch (error) {
      console.error("Erro ao obter os planos de assinatura:", error);
      throw error;
    }
  }
}
