/* eslint-disable @typescript-eslint/no-unused-vars */
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../../components/form";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { APPC_CONSENT } from "../../../../services/appc";

export function ConfirmConsentDialog({ setRefetch }: any) {
  const { user } = useSessionContext();
  const {
    confirmConsentDialog,
    setConfirmConsentDialog,
    subjectName,
    resetStates,
    subjectId,
    vigency,
    consentType,
  } = useConsentContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    resetStates();
    setConfirmConsentDialog(false);
  };

  const vigencyValue = vigency === 1000 ? null : vigency;

  const handleConfirm = async () => {
    try {
      if (consentType === "cpf") {
        await APPC_CONSENT({
          rule: "Permit",
          patient_id: user.username,
          subject_id: [subjectId],
          cnes: null,
          vigency: vigencyValue,
          purposeofuse: "PATRQT",
        });
      } else
        await APPC_CONSENT({
          rule: "Permit",
          patient_id: user.username,
          subject_id: null,
          cnes: [subjectId?.split("-")[1]],
          vigency: vigencyValue,
        });
      toast.success(`Consentimento concedido com sucesso! `);
    } catch {
      toast.error(`Não foi possível finalizar a operação.`);
    } finally {
      handleClose();
      setRefetch((prev: boolean) => !prev);
    }
  };

  const vigencyDict: { [key: string]: string } = {
    1: "1 dia",
    7: "1 semana",
    30: "1 mês",
    365: "1 ano",
  };
  return (
    <CommonDialog handleClose={handleClose} isOpen={confirmConsentDialog}>
      <DialogTitle textAlign="center">
        Confirmar novo consentimento
        <Typography variant="body1" color="neutral700.main">
          Atenção: ao confirmar essa ação <b> {subjectName} </b> terá acesso a
          suas informações de saúde
          {vigencyDict[vigency] ? (
            <>
              {" "}
              por
              <b> {vigencyDict[vigency]} </b>
            </>
          ) : (
            <b> até revogação manual </b>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleConfirm}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
