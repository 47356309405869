import {
  FileCopy,
  Phone,
  PrivacyTip
} from "@mui/icons-material";
import { useState } from "react";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { ContactMobile } from "../contactmobile/ContactMobile";
import { PasswordScreenMobile } from "../passwordscreenmobile/PasswordScreenMobile";
import { PaymentScreenMobile } from "../paymentscreenmobile/PaymentScreenMobile";
import { PersonalInformationMobile } from "../personalinformationmobile/PersonalInformationMobile";
import { RegisterDataItens } from "./components/RegisterDataItens";

export function RegisterDataMenuMobile({ open, onClose }: any) {
  const [openPersonalInformation, setOpenPersonalInformation] =
    useState<boolean>(false);

  const [openContact, setOpenContact] = useState<boolean>(false);

  const [openPayment, setOpenPayment] = useState<boolean>(false);

  const [openPasswordScreen, setOpenPasswordScreen] = useState<boolean>(false);

  return (
    <>
      <ScreenMobile title="Dados cadastrais" open={open} onClose={onClose}>
        <RegisterDataItens
          icon={<FileCopy />}
          text="Informações pessoais"
          textAuxiliar="Aqui você encontra suas informações e dados pessoais"
          onClick={() => setOpenPersonalInformation(true)}
        />
        <RegisterDataItens
          icon={<Phone />}
          text="Contato"
          textAuxiliar="Seus dados para contato."
          onClick={() => setOpenContact(true)}
        />

        <RegisterDataItens
          icon={<PrivacyTip />}
          text="Senha"
          onClick={() => setOpenPasswordScreen(true)}
        />
      </ScreenMobile>

      <PersonalInformationMobile
        open={openPersonalInformation}
        onClose={() => setOpenPersonalInformation(false)}
      />
      <ContactMobile open={openContact} onClose={() => setOpenContact(false)} />

      <PaymentScreenMobile
        open={openPayment}
        onClose={() => setOpenPayment(false)}
      />
      <PasswordScreenMobile
        open={openPasswordScreen}
        onClose={() => setOpenPasswordScreen(false)}
      />
    </>
  );
}
