import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

interface IProps {
  id?: string;
  actor?: string;
  location?: string;
  includeSlot?: boolean;
  count?: number;
  active?: boolean;
  startDate?: string;
  specialty?: string;
  city?: string;
  state?: string;
  organization?: string;
  practitioner?: string;
  includeLocation?: boolean;
  sort?: string;
}

/**
 * @param id schedule id
 * @param actor id
 * @param location id
 * @param includeSlot boolean, it doesn't filter slots by any property
 * @param count int
 * @param active boolean
 * @param startdate YYYY-MM-DD
 * @param specialty from EspecialidadeMedica value set
 * @param city name, e.g. Brasília
 * @param state acronym, e.g. DF
 * @param organization {oid.cnpj}-{cnpj} or Organization/{oid.cnpj}-{cnpj}
 * @param practitioner {oid.cpf}-{cpf} or Practitioner/{oid.cpf}-{cpf}
 * @param includeLocation boolean
 * @param sort string
 * @returns Bundle
 */
export async function GetSchedule({
  id,
  actor,
  location,
  includeSlot,
  count,
  active,
  startDate,
  specialty,
  city,
  state,
  organization,
  practitioner,
  includeLocation,
  sort,
}: IProps) {
  let url = `${endpoints.WORKFLOWFHIR}/Schedule`;
  if (id) url = `${url}/${id}`;
  else if (count) url = `${url}?_count=${count}`;
  else url = `${url}?_count=100`;
  if (actor) url = `${url}&actor=${actor}`;
  if (location) url = `${url}&actor=${location}`;
  if (includeSlot) url = `${url}&_revinclude=Slot:schedule`;
  if (active) url = `${url}&active=true`;
  if (startDate) url = `${url}&_has:Slot:schedule:start=${startDate}`;
  if (specialty) url = `${url}&actor.name:exact=${specialty}`;
  if (city) url = `${url}&actor.address-city=${city}`;
  if (state) url = `${url}&actor.address-state=${state}`;
  if (organization) url = `${url}&actor.organization=${organization}`;
  if (practitioner) url = `${url}&actor=${practitioner}`;
  if (includeLocation) url = `${url}&_include=Schedule:actor:Location`;
  if (sort) url = `${url}&_sort=${sort}`;

  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get Schedule: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetSchedule ", error);
      // toast.error(`GetSchedule Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
