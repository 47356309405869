import { FileDownload, Share } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

import { useEffect, useState } from "react";
import { CircularLoading, SecondaryButton } from "../../../components/common";
import ControlMedication from "../../../components/common/ControlMedication";
import { ScreenMobile } from "../../../components/common/ScreenMobile";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { IframeVisualization } from "./IframeVisualization";

interface IProps {
  openInformation?: boolean;
  handleClose?: VoidFunction;
  data?: any;
}

export function ViewMedicalPrescription({
  openInformation,
  handleClose,
  data,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [base64, setBase64] = useState<any>();

  function downloadBase64File() {
    const linkSource = `data:${base64?.contentType};base64,${base64?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Receita médica";
    downloadLink.click();
  }

  useEffect(() => {
    async function getDocumentPdf() {
      const response = await GetBinary({ urlBinary: data?.binaryUrl });
      setBase64(response);
    }
    if (data?.binaryUrl) {
      getDocumentPdf();
    }
    setLoading(false);
  }, [data]);

  return isMobile ? (
    <ScreenMobile
      title="Documento"
      open={openInformation ? openInformation : false}
      onClose={handleClose}
    >
      <Stack
        gap={1}
        mt={1}
        width="100%"
        height="100%"
        maxHeight="500px"
        direction="column"
      >
        <Stack minHeight="450px" height="100%" width="100%">
          <IframeVisualization
            data={`data:${base64?.contentType};base64,${base64?.data}`}
          />
        </Stack>
        <Stack direction="row" gap={1}>
          <SecondaryButton
            width="50%"
            height="42px"
            onClick={() => downloadBase64File()}
          >
            <FileDownload /> Baixar
          </SecondaryButton>
          {/* <SecondaryButton width="50%" height="42px" onClick={() => {}}>
            <RemoveRedEye /> Visualizar
          </SecondaryButton> */}
        </Stack>
        {/* <SecondaryButton width="100%" height="42px" onClick={() => {}}>
          <Share /> Compartilhar
        </SecondaryButton> */}

        <Divider
          sx={{ width: "100%", background: "neutral400.main", marginTop: 3 }}
        />
        {data?.medication
          .filter(
            (el: any) =>
              el?.resource?.status !== "completed" &&
              el?.resource?.status !== "stopped"
          )
          .map((el: any) => (
            <ControlMedication data={el} />
          ))}
      </Stack>
    </ScreenMobile>
  ) : (
    <>
      {base64?.data && !loading ? (
        <IframeVisualization
          data={`data:${base64?.contentType};base64,${base64?.data}`}
        />
      ) : (
        <CircularLoading />
      )}
      <Stack width="100%" direction="row" gap={1} margin="24px 0px 16px">
        <SecondaryButton
          width="50%"
          height="42px"
          onClick={() => downloadBase64File()}
        >
          <FileDownload /> Baixar
        </SecondaryButton>

        <SecondaryButton width="50%" height="42px" onClick={() => {}}>
          <Share /> Compartilhar
        </SecondaryButton>
      </Stack>

      <Divider
        sx={{ width: "100%", background: "neutral400.main", marginTop: 3 }}
      />
      <Typography
        variant="body1"
        color="neutral700.main"
        fontWeight={600}
        mt={3}
      >
        Controle o uso do seu remédio
      </Typography>
      {data?.medication
        // .filter(
        //   (el: any) =>
        //     el?.resource?.status !== "completed" &&
        //     el?.resource?.status !== "stopped"
        // )
        .map((el: any) => (
          <ControlMedication data={el} />
        ))}
    </>
  );
}
