import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetContract } from "../services/fhir/contract/GetContract";
import { useOrganizationContext } from "./OrganizationContext";
import { usePractitionerRoleContext } from "./PractitionerRoleContext";

import { useLocation, useNavigate } from "react-router-dom";
import { FormButtonGroup } from "../components/form";
import { useSessionContext } from "./SessionContext";

interface ContractContextValue {
  currentContract: fhir5.BundleEntry<fhir5.Contract>[] | undefined;
  inactiveContracts: fhir5.BundleEntry<fhir5.Contract>[] | undefined;
  setRefetchContract: any;
  setRefetchInactiveContracts: any;
}

export const ContractContext = createContext<ContractContextValue>(null!);

export function ContractContextProvider({ children }: { children: ReactNode }) {
  const { access } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { practitionerRole } = usePractitionerRoleContext();
  const [currentContract, setCurrentContract] = useState<
    fhir5.BundleEntry<fhir5.Contract>[] | undefined
  >();
  const [inactiveContracts, setInactiveContracts] = useState<
    fhir5.BundleEntry<fhir5.Contract>[] | undefined
  >();

  const { pathname } = useLocation();
  const verifyRoute = pathname === "/practitioners" || pathname === "/clinic";

  const verifyRoles =
    access?.roles?.includes("manager") || access?.roles?.includes("owner");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<boolean>(false);

  const navigate = useNavigate();
  // function handleClose() {
  //   navigate("/practitioners");
  //   setIsOpen(false);
  // }

  const [refetchContract, setRefetchContract] = useState<boolean>(false);
  const [refetchInactiveContracts, setRefetchInactiveContracts] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchInactiveContracts() {
      try {
        const response = await GetContract({
          organization: organization?.id,
          count: 100,
          sort: true,
          status: "cancelled",
        });
        setInactiveContracts(response?.entry);
      } catch (err) {
        console.log("err", err);
      }
    }
    if (organization?.id !== undefined) {
      fetchInactiveContracts();
    }
    setRefetchInactiveContracts(false);
  }, [organization?.id, refetchInactiveContracts]);

  useEffect(() => {
    async function fetchCurrentContract() {
      try {
        const response = await GetContract({
          organization: organization?.id,
          count: 1,
          sort: true,
          status: "executed",
        });
        setCurrentContract(response?.entry);

        const limitPractitioners =
          (practitionerRole &&
            practitionerRole?.filter(
              (el: any) => el?.resource?.active !== false
            )?.length >
              parseInt(
                response?.entry?.[0]?.resource?.term?.[0]?.identifier?.value ||
                  ""
              )) ||
          false;
        setLimit(limitPractitioners);
        if (limitPractitioners) setIsOpen(true);
      } catch (err) {
        console.log("err", err);
      }
    }
    if (organization?.id !== undefined) {
      fetchCurrentContract();
    }
    setRefetchContract(false);
  }, [organization?.id, refetchContract, practitionerRole]);

  const contextValue = {
    currentContract,
    inactiveContracts,
    setRefetchContract,
    setRefetchInactiveContracts,
  };

  return (
    <ContractContext.Provider value={contextValue}>
      <>
        {access && verifyRoles && !verifyRoute && limit && (
          <Dialog
            open={isOpen}
            PaperProps={{
              sx: { borderRadius: "10px", padding: 2 },
            }}
            maxWidth="sm"
          >
            <DialogTitle textAlign="center" mt={2}>
              Limite de profissionais atingido
            </DialogTitle>

            <DialogContent>
              <Stack spacing={2}>
                <Typography variant="body1">
                  Você atingiu o limite de profissionais ativos para o seu plano
                  de assinatura, gerencie os profissionais ativos ou altere o
                  plano.
                </Typography>
                <Stack direction="row" spacing={5}>
                  <Typography variant="body1">
                    <Typography sx={{ fontWeight: 600 }}>
                      Quantidade de profissionais na clínica:
                    </Typography>{" "}
                    {practitionerRole?.length} profissionais
                  </Typography>
                  <Typography variant="body1">
                    <Typography sx={{ fontWeight: 600 }}>
                      Limite de profissionais:{" "}
                    </Typography>{" "}
                    {
                      currentContract?.[0]?.resource?.term?.[0]?.identifier
                        ?.value
                    }{" "}
                    profissionais
                  </Typography>
                </Stack>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
              >
                <FormButtonGroup
                  onGoBackButtonClick={() => navigate("/practitioners")}
                  onNextButtonClick={() => navigate("/clinic#signature")}
                  goBackButtonText="Gerenciar profissionais"
                  nextButtonText="Alterar plano"
                  loading={loading}
                />
              </Stack>
            </DialogActions>
          </Dialog>
        )}
        {children}
      </>
    </ContractContext.Provider>
  );
}

export function useContractContext() {
  return useContext(ContractContext);
}
