import React, { createContext, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { GetEncounter, GetPatient, PostEncounter } from "../services/fhir";
import { IEncounterData } from "../utils/interfaces";
import { useFhirData } from "../utils/useFhirData";
import { useAuthContext } from "./AuthContext";
import { useOrganizationContext } from "./OrganizationContext";
import { usePractitionerRoleContext } from "./PractitionerRoleContext";
import { useSessionContext } from "./SessionContext";
import { useViewContext } from "./ViewContext";

interface UseClinicalRegisterContextValue {
  verifyEncounter: Function;
  inProgress: any;
  setInProgress: any;
}

export const UseClinicalRegisterContext =
  createContext<UseClinicalRegisterContextValue>(null!);

interface UseClinicalRegisterContextProviderProps {
  children: React.ReactNode;
}

export function ClinicalRegisterContextProvider({
  children,
}: UseClinicalRegisterContextProviderProps) {
  const { user } = useSessionContext();
  const { userPractitionerRole } = usePractitionerRoleContext();
  const { organization } = useOrganizationContext();
  const { setSidebarIsOpen } = useViewContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getNewToken, parsedTime } = useAuthContext();
  const [inProgress, setInProgress] = useState("");

  const navigate = useNavigate();

  const verifyEncounter = async (
    dataAppointment: any,
    prescription?: boolean
  ) => {
    const encounterData: IEncounterData = {
      appointment: dataAppointment?.id || "",
      type: dataAppointment?.appointmentType?.coding?.[0].code || "",
      organization: organization?.id || "",
      participant: dataAppointment?.participant,
    };

    const patientId = useFhirData
      .findParticipant(dataAppointment?.participant, "Patient")
      ?.reference?.split("-")?.[1];

    const dataPatient = await GetPatient({ identifier: patientId });
    const patientName = dataPatient?.data?.name?.[0]?.text;

    const verifyEncounter = await GetEncounter({
      patientId,
      appointmentId: dataAppointment?.id,
      lastUpdaded: true,
      count: 1,
    });
    const encounter: fhir5.Encounter =
      verifyEncounter?.entry?.[0]?.resource ||
      (await PostEncounter(encounterData).then((response) => response));
    const auxEncounterReference = {
      id: encounter.id,
      subject: patientId,
      requester: {
        reference: `PractitionerRole/${userPractitionerRole?.id}`,
        display: user?.name,
      },
      appointmentId: dataAppointment?.id,
      participant: dataAppointment?.participant,
      ...(dataAppointment?.virtualService && {
        virtualService: dataAppointment?.virtualService?.[0]?.addressUrl,
        sessionKey: dataAppointment?.virtualService?.[0]?.sessionKey,
      }),
    };

    setSidebarIsOpen(false);

    await getNewToken();

    setInProgress(dataAppointment?.id);

    navigate(!prescription ? "/clinicalregister" : "/prescription", {
      state: {
        encounterReference: auxEncounterReference,
        patientId: patientId,
        patientName: patientName,
      },
    });
  };

  const contextValue: UseClinicalRegisterContextValue = {
    verifyEncounter,
    inProgress,
    setInProgress,
  };

  return (
    <UseClinicalRegisterContext.Provider value={contextValue}>
      {children}
    </UseClinicalRegisterContext.Provider>
  );
}

export function useClinicalRegisterContext() {
  return useContext(UseClinicalRegisterContext);
}
