import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DocumentReference } from "fhir/r5";
import { useState } from "react";
import { isMobile, isMobileSafari } from "react-device-detect";

import { LargeContentBox, PageTitle } from "../../components/common";
import { DataGridPresentation } from "../../components/common/DataGridPresentation";
import { ScreenMobile } from "../../components/common/ScreenMobile";
import { PrivateMainLayout } from "../../components/layout";
import { usePatientHealthCareInformationContext } from "../../contexts/PatientHealthCareInformationContext";
import { GetBinary } from "../../services/fhir/patientsummary/Binary/GetBinary";
import { MedicalNoteActions } from "./components/MedicalNoteActions";
import { ViewMedicalNote } from "./components/ViewMedicalNote";

interface IProps {
  open?: boolean;
  onClose?: VoidFunction;
}

export default function MedicalNote({ open, onClose }: IProps) {
  const { documentReferenceNote } = usePatientHealthCareInformationContext();
  const [openInformation, setOpenInformation] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataRow, setDataRow] = useState<any>();

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    {
      field: "description",
      headerName: "Profissional",
      minWidth: 350,
      flex: 1,
    },
    {
      field: "date",
      headerName: "Data",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "specialty",
      headerName: "Especialidade",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "sign",
      headerName: "Assinado digitalmente",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "document",
      headerName: "Ações",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      renderCell: (e: any) => <MedicalNoteActions row={e?.row} />,
    },
  ];

  const rows =
    documentReferenceNote?.map(
      (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
        const auxPractitioner = el?.resource?.contained.find(
          (contained: any) => contained?.resourceType === "Practitioner"
        );

        const auxSpecialty =
          el?.resource?.practiceSetting?.coding?.[0]?.display;

        const sign =
          el?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
          "ProofOfOrigin"
            ? "Sim"
            : "Não";

        return {
          id: index,
          sign,
          description:
            auxPractitioner?.name?.[0]?.given?.[0] ||
            auxPractitioner?.identifier?.[0]?.value,
          date: dayjs(el?.resource?.date).format("DD/MM/YYYY") || "-",
          specialty: auxSpecialty || "-",

          binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
        };
      }
    ) || [];

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return isMobile ? (
    <ScreenMobile
      title="Relatórios médicos"
      open={open ? open : false}
      onClose={onClose}
    >
      <Stack width="90vw" minHeight="20vh" height="auto" maxHeight="70vh">
        <DataGridPresentation
          rows={rows}
          columns={columns}
          rowClick={async (e: any) => {
            try {
              const response = await GetBinary({
                urlBinary: e?.row?.binaryUrl,
              });
              const blob = base64ToBlob(response?.data, response?.contentType);
              const fileUrl = URL.createObjectURL(blob);
              const auxFile = {
                fileUrl,
                fileName: "Relatório.pdf",
              };

              if (isMobileSafari) {
                if (auxFile) {
                  const link = document.createElement("a"); // Cria um elemento <a>
                  link.href = auxFile?.fileUrl; // Define o URL do Blob
                  link.download = auxFile?.fileName; // Define o nome do arquivo
                  // document.body.appendChild(link); // Adiciona o link ao documento
                  link.click(); // Simula o clique no link
                  // document.body.removeChild(link); // Remove o link do documento
                  // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
                } else {
                  alert(
                    "O arquivo ainda está sendo preparado, por favor tente novamente."
                  );
                }
              } else {
                window.open(auxFile.fileUrl, "_blank");
                // setDataRow(e.row);
                // setOpenInformation(true);
              }
            } catch (err) {
              console.log("err", err);
            }
          }}
        />
        <ViewMedicalNote
          openInformation={openInformation}
          handleClose={() => setOpenInformation(false)}
          data={dataRow}
        />
      </Stack>
    </ScreenMobile>
  ) : (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack width="100%" height="100%" overflow="auto">
          <PageTitle>Relatórios médicos</PageTitle>

          <DataGridPresentation
            rows={rows}
            columns={columns}
            rowClick={(e: any) => {}}
          />
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
