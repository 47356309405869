import {
  FileUpload,
  Visibility
} from "@mui/icons-material";
import { Button, Grid, Stack, Typography, styled } from "@mui/material";
import iconFlat from "../assets/IconFlat.svg";
import iconPharmacy from "../assets/iconPharmacy.svg";

import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FormSwitch } from "../../../components/form/FormSwitch";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useSessionContext } from "../../../contexts/SessionContext";
import { EHRPosterPatchStatus } from "../../../services/fhir/patientsummary/EHRPosterPatchStatus";
import { IframeDialog } from "../../ehrrecords/components/IframeDialog";
import { ConfirmDocumentDialog } from "../../examsolicitation/components/ConfirmDocumentDialog";
import { StopMedicationDialog } from "./StopMedicationDialog";

interface IProps {
  type: "medication" | "examSolicitation";
  fullData?: any;
  name?: string;
  date?: any;
  control?: any;
  result?: any;
  setRefetch?: any;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function HealthMedicineDetails({
  type,
  name,
  date,
  fullData,
  result,
  setRefetch,
}: IProps) {
  const methods = useForm({});
  const { control } = methods;
  const { user } = useSessionContext();

  const [fileBase64, setFileBase64] = useState<any>();
  const [openResult, setOpenResult] = useState<boolean>();
  const [openControl, setOpenControl] = useState<boolean>(false);
  const [renderButton, setRenderButton] = useState<boolean>(true);

  const convertToBase64 = (file: File) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
  };

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };

  const choiseChange = useWatch({ name: "medicationControl", control });

  function onCloseDialog() {
    setFileBase64(undefined);
  }

  const docReferenceData = {
    servicePratice: {
      code: fullData?.practiceSetting?.coding?.[0]?.code,
      system: fullData?.practiceSetting?.coding?.[0]?.system,
      display: fullData?.practiceSetting?.coding?.[0]?.display,
    },
    time: {
      start: dayjs().toISOString(),
      end: dayjs().toISOString(),
    },
  };

  const refs = {
    id: fullData?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],
    subject: user?.username,
    appointmentId: fullData?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await EHRPosterPatchStatus({
          resourceType: "MedicationRequest",
          resourceId: fullData?.id,
          status: choiseChange ? "active" : "stopped",
        });
        toast.success("Alterado com sucesso");
        setRefetch((prev: any) => !prev);
      } catch (err) {
        console.log("err", err);
      }
    }
    if (choiseChange) fetchData();
    if (choiseChange === false) setOpenControl(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiseChange, fullData]);

  return (
    <>
      <Grid
        container
        alignContent="flex-start"
        p={2}
        sx={{ boxShadow: 2 }}
        height="fit-content"
        mt={{ xs: 0, md: 2 }}
      >
        <Grid item xs={2}>
          <img
            src={type === "medication" ? iconPharmacy : iconFlat}
            alt="icone"
            width="45px"
            height="30px"
          />
        </Grid>
        <Grid item xs={9}>
          <Stack direction="column" width="100%">
            <Typography
              variant="body1"
              color="neutral700.main"
              fontWeight={600}
            >
              {name}
            </Typography>
            <Typography variant="body2" color="neutral700.main">
              {type === "medication" ? "Prescrito" : "Solicitado"} em {date}
            </Typography>
            {type === "medication" ? (
              <FormSwitch
                name="medicationControl"
                label="Estou tomando"
                control={control}
                defaultValue={fullData.status === "active" ? true : false}
                // checked={fullData.status === "active" ? true : false}
              />
            ) : (
              <>
                {renderButton && !result && (
                  <Button
                    component="label"
                    role={undefined}
                    variant="text"
                    tabIndex={-1}
                    startIcon={<FileUpload />}
                    sx={{ width: "fit-content", color: "error700.main" }}
                    onChange={(e) => uploadImage(e)}
                  >
                    <Typography
                      variant="body1"
                      textTransform="lowercase"
                      sx={{
                        "&::first-letter": {
                          textTransform: "uppercase",
                        },
                      }}
                    >
                      Carregar resultado de exame
                    </Typography>
                    <VisuallyHiddenInput type="file" />
                  </Button>
                )}
                {renderButton && result && (
                  <Button
                    component="label"
                    role={undefined}
                    variant="text"
                    tabIndex={-1}
                    startIcon={<Visibility />}
                    sx={{ width: "fit-content", color: "primary700.main" }}
                    onClick={() => setOpenResult(true)}
                  >
                    <Typography
                      variant="body1"
                      textTransform="lowercase"
                      sx={{
                        "&::first-letter": {
                          textTransform: "uppercase",
                        },
                      }}
                    >
                      Visualizar resultado enviado
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Grid>
      </Grid>

      {openResult && (
        <IframeDialog
          isOpen={openResult}
          handleClose={() => setOpenResult(false)}
          data={result?.resource?.content?.[0]?.attachment?.url}
        />
      )}

      {fileBase64 && (
        <ConfirmDocumentDialog
          setRenderButton={setRenderButton}
          setRefetch={setRefetch}
          fileBase64={fileBase64}
          isOpenDialog={fileBase64}
          onCloseDialog={() => onCloseDialog()}
          basedOn={fullData?.basedOn?.filter((el: any) =>
            el.reference?.includes("ServiceRequest")
          )}
          encounterReference={refs}
          data={{
            ...docReferenceData,
            base64: fileBase64?.split(",")?.[1],
            codeValue: "RESULT",
            codeDisplay: "Resultado de exame",
            categoryValue: "resultado",
            categoryDisplay: "Resultado de exame",
          }}
        />
      )}

      <StopMedicationDialog
        isOpen={openControl}
        handleClose={() => {
          setOpenControl(false);
          setRefetch((prev: any) => !prev);
        }}
        data={fullData}
      />
    </>
  );
}
