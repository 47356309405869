import {
  ChevronLeft,
  ChevronRight,
  Circle,
  CircleOutlined,
  Phone,
} from "@mui/icons-material";
import { Card, IconButton, Slide, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SearchFields } from "../../../../components/schedule/SearchFields";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { useFormatter } from "../../../../utils/useFormatter";
import NaturalScience from "../../assets/NaturalScience.svg";
import Logo from "../images/Logo.png";

type SlideDirection = "left" | "right";

interface ISelectOption {
  label: string;
  value: string;
}

export interface IFormInput {
  healthcareService: ISelectOption | undefined;
  organization: ISelectOption | undefined;
  practitioner: ISelectOption | undefined;
}

const defaultValues = {
  healthcareService: undefined,
  organization: undefined,
  practitioner: undefined,
};

export function CarrouselMobile() {
  const navigate = useNavigate();

  const { organizationList } = useOrganizationContext();

  const displayOrganization = organizationList?.filter((e: any) => {
    return e?.resource?.id === "2.16.76.1.3.3-42842528000158";
    // return true;
  });
  const auxAssets: { [key: string]: string } = {
    "2.16.76.1.3.3-42842528000158": NaturalScience,
    "2.16.76.1.3.3-05490544000100": Logo,
  };

  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, watch, resetField } = methods;

  const onSubmit = (data: IFormInput) => {
    const searchParams = {
      ...(data.healthcareService && {
        healthcareService: data.healthcareService,
      }),
      ...(data.organization && {
        organization: data.organization,
      }),
      ...(data.practitioner && {
        practitioner: data.practitioner,
      }),
    };

    navigate("/schedulingsearch", {
      state: {
        ...(searchParams && {
          ...searchParams,
        }),
      },
    });
  };

  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState<SlideDirection>("left"); // Usando o tipo definido

  const handleNext = () => {
    setDirection("left"); // Direção para frente
    if (index + 1 === displayOrganization?.length) setIndex(0);
    else setIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setDirection("right"); // Direção para trás
    if (index === 0 && displayOrganization?.length) {
      setIndex(displayOrganization?.length - 1);
    } else {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeout(() => {
        setDirection("left");
        setIndex((prevIndex) =>
          prevIndex + 1 === displayOrganization?.length ? 0 : prevIndex + 1
        );
      }, 500);
    }, 9000);

    return () => clearInterval(timer);
  }, [index, displayOrganization?.length]);

  return (
    <>
      {displayOrganization?.length ? (
        <Stack
          spacing={1}
          sx={{
            width: "100%",
            backgroundColor: "neutral0.main",
            borderRadius: { xs: "10px", md: "16px" },
          }}
        >
          <Card sx={{ padding: 0, paddingBottom: 0 }}>
            <Stack height="140px" direction="row" alignItems="center">
              <Stack>
                <IconButton onClick={handlePrev}>
                  <ChevronLeft />
                </IconButton>
              </Stack>
              <Stack width="100%">
                {displayOrganization?.map((el, i) => {
                  // const auxAddress = el?.resource?.contact?.find(
                  //   (el: fhir5.ExtendedContactDetail) => el.address
                  // )?.address;
                  // const city = auxAddress?.city || "";
                  // const state = auxAddress?.state || "";
                  // const postalCode = auxAddress?.postalCode || "";

                  // const line = auxAddress?.line?.join(", ") || "";

                  // const address =
                  //   [line, city, state, postalCode]
                  //     ?.filter((el: any) => el)
                  //     ?.join(" , ") || " ";

                  const src = auxAssets?.[`${el?.resource?.id}` || ""];
                  const organizationContact =
                    el?.resource?.contact
                      ?.find((e: any) => e?.telecom)
                      ?.telecom?.find((el: any) => el?.system === "phone")
                      ?.value || "-";

                  return (
                    <Slide
                      key={el?.resource?.id}
                      direction={direction}
                      in={i === index}
                      mountOnEnter
                      unmountOnExit
                      timeout={{ enter: 500, exit: 200 }}
                    >
                      <Stack direction={"column"} width="100%">
                        <Stack
                          sx={{
                            display: i !== index ? "none" : "flex",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/schedulingsearch", {
                              state: {
                                organization: {
                                  value: el?.resource?.id,
                                  label:
                                    el?.resource?.alias?.[0] ||
                                    el?.resource?.name,
                                },
                              },
                            });
                          }}
                          justifyContent="center"
                          alignItems="center"
                          p={2}
                          spacing={2}
                        >
                          <img
                            src={src || NaturalScience}
                            width={100}
                            height={100}
                            alt="Imagem clínica"
                          />

                          {/* <SecondaryButton
                          width={"220px"}
                          height="42px"
                          onClick={() => {
                            navigate("/schedulingsearch", {
                              state: {
                                organization: {
                                  value: el?.resource?.id,
                                  label:
                                    el?.resource?.alias?.[0] ||
                                    el?.resource?.name,
                                },
                              },
                            });
                          }}
                          id="goBackButton"
                        >
                          Agendar
                        </SecondaryButton> */}
                        </Stack>
                        {organizationContact && (
                          <Stack
                            direction="row"
                            width="100%"
                            spacing={1}
                            alignContent={"center"}
                            justifyContent={"center"}
                          >
                            <Phone sx={{ color: "neutral700.main" }} />
                            <Typography
                              variant="body1"
                              color="neutral700.main"
                              fontWeight={500}
                            >
                              {useFormatter.formatPhoneNumber(
                                organizationContact
                              )}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Slide>
                  );
                })}
              </Stack>
              <Stack>
                <IconButton onClick={handleNext}>
                  <ChevronRight />
                </IconButton>
              </Stack>
            </Stack>

            <Stack width="100%" direction="row" justifyContent="center">
              {displayOrganization?.map((el: any, ind: number) => {
                const auxCheck = ind === index;
                return (
                  <Stack width="fit-content" alignItems="center">
                    <IconButton
                      onClick={() => {
                        if (ind < index) setDirection("right");
                        setIndex(ind);
                      }}
                    >
                      {!auxCheck ? (
                        <CircleOutlined sx={{ fontSize: "12px" }} />
                      ) : (
                        <Circle color="primary" sx={{ fontSize: "12px" }} />
                      )}
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </Card>

          {/* <SearchFields
            resetField={resetField}
            onSubmit={onSubmit}
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
          /> */}
        </Stack>
      ) : (
        <Stack
          spacing={1}
          sx={{
            width: "100%",
            backgroundColor: "neutral0.main",
            borderRadius: { xs: "10px", md: "16px" },
            mb: { xs: 1, sm: 2 },
            padding: 2,
          }}
        >
          <SearchFields
            resetField={resetField}
            onSubmit={onSubmit}
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
          />
        </Stack>
      )}

      {/* <SearchFields
        resetField={resetField}
        onSubmit={onSubmit}
        control={control}
        handleSubmit={handleSubmit}
        watch={watch}
      /> */}
    </>
  );
}
