import { FileCopy } from "@mui/icons-material";
import { useSessionContext } from "../../contexts/SessionContext";
import { IFeatures } from "../../utils/interfaces";
import MedicalNote from "./MedicalNote";

export function useMedicalNote() {
  const { access } = useSessionContext();
  const configs: IFeatures = {
    initial: false,
    active: access.type === "patient",
    path: "/medicalnote",
    text: "Relatórios médicos",
    icon: FileCopy,

    // sideMenu: true,
    sideMenuIndex: 3,

    component: () => <MedicalNote />,
  };

  return configs;
}
