import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import logoHeader from "../../../assets/fluxmed-logo-horizontal.png";
import { CircularLoading, ContentBoxTitle } from "../../../components/common";
import { SubscriptionService } from "../../../services/subscription/SubscriptionService";
import { SubscriptionCard } from "./SubscriptionCard";

interface IProps {
  setSelectedPlan: (plan: any) => void;
  handleNext: () => void;
}

const organizationId = localStorage.getItem("organizationValue");

export function SubscriptionPlan({ setSelectedPlan, handleNext }: IProps) {
  const [paymentOptions, setPaymentOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const subscriptionService = new SubscriptionService();

  const fetchAllSubscriptionPlans = async () => {
    const allPlans = await subscriptionService.getSubscriptionPlans(
      organizationId
    );
    console.log("Planos recebidos:", allPlans);
    return allPlans && Array.isArray(allPlans) ? allPlans : [];
  };

  useEffect(() => {
    setLoading(true);
    try {
      const fetchPaymentOptions = async () => {
        const options = await fetchAllSubscriptionPlans();
        console.log("Opções de pagamento:", options);
        setPaymentOptions(options);
        setLoading(false);
      };

      fetchPaymentOptions();
    } catch (err) {
      console.error("Erro ao buscar planos de assinatura:", err);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlanSelection = (plan: any) => {
    console.log("Plano selecionado:", plan);
    setSelectedPlan(plan);
    handleNext();
  };

  return (
    <Stack alignItems="center" spacing={10}>
      <Stack alignItems="center" spacing={2}>
        <img src={logoHeader} alt="Logo fluxmed" width={185} height="auto" />
        <ContentBoxTitle>Faça a sua assinatura</ContentBoxTitle>
      </Stack>
      <Stack spacing={6} justifyContent="center" alignItems="center">
        {loading ? (
          <CircularLoading />
        ) : paymentOptions.length > 0 ? (
          <Stack direction="row" spacing={3}>
            {paymentOptions.map((plan, index) => (
              <SubscriptionCard
                key={index}
                option={plan}
                onSelectPlan={handlePlanSelection}
              />
            ))}
          </Stack>
        ) : (
          <Typography>
            Nenhum plano de assinatura disponível no momento.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
