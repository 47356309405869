import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DocumentAccordion } from "../DocumentAccordion";
import { MedicalCertificate } from "./MedicalCertificate";
import { PrescriptionItem } from "./PrescriptionItem";
import { ProcedureTab } from "./ProcedureTab";
import { ServiceTab } from "./ServiceTab";

type SelectedTabType = "1" | "2" | "3" | "4";

export function ProcedureAccordion({
  prescriptionItens,
  setPrescriptionItens,
  addItem,
}: any) {
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>("1");
  const [selectedItem, setSelectedItem] = useState<number>();
  const methods = useForm();
  const { reset } = methods;

  const handleChangeTab = (e: React.SyntheticEvent, value: SelectedTabType) => {
    reset();
    setSelectedTab(value);
  };

  const editItem = (item: any) => {
    setPrescriptionItens((prevState: any) => {
      const updatedItems = [...prevState];
      const index = updatedItems.findIndex((el, i) => i === selectedItem);
      if (index !== -1) {
        updatedItems[index] = item;
      }

      return updatedItems;
    });
    setSelectedItem(undefined);
  };

  const action = selectedItem === undefined ? addItem : editItem;
  const isEdit = selectedItem !== undefined;

  const procedureArray = prescriptionItens.filter(
    (el: any) => el.type === "procedure"
  );
  const serviceArray = prescriptionItens.filter(
    (el: any) => el.type === "service"
  );
  const certificateArray = prescriptionItens.filter(
    (el: any) => el.type === "certificate"
  );

  return (
    <>
      <DocumentAccordion
        defaultExpaded
        subtitle="Ações e informações de procedimento"
        title="Procedimentos"
      >
        {
          <>
            {procedureArray.length === 0 ? (
              <></>
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography
                    variant="body1"
                    color="neutral1000.main"
                    fontWeight={500}
                  >
                    Procedimentos:
                  </Typography>
                </Stack>
                {procedureArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);

                  return (
                    <PrescriptionItem
                      key={`procedure-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {
          <>
            {serviceArray.length === 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                  mb={1}
                >
                  Exames:{" "}
                </Typography>
                {serviceArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);
                  return (
                    <PrescriptionItem
                      key={`exams-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {
          <>
            {certificateArray.length === 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <Typography
                  variant="body1"
                  color="neutral1000.main"
                  fontWeight={500}
                  mb={1}
                >
                  Atestados:{" "}
                </Typography>
                {certificateArray?.map((el: any, index: number) => {
                  const auxIndex = prescriptionItens.indexOf(el);
                  return (
                    <PrescriptionItem
                      key={`item-${index}`}
                      setSelectedTab={setSelectedTab}
                      methods={methods}
                      setPrescriptionItens={setPrescriptionItens}
                      index={index}
                      prescriptionIndex={auxIndex}
                      setSelectetItem={setSelectedItem}
                      selectedItem={selectedItem}
                      item={el}
                    />
                  );
                })}
              </>
            )}
          </>
        }
        {<></>}

        <Stack>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleChangeTab}>
                <Tab
                  value="1"
                  label="Procedimentos"
                  sx={{ textTransform: "none" }}
                />
                <Tab value="2" label="Exames" sx={{ textTransform: "none" }} />
                <Tab
                  value="3"
                  label="Relatórios e outros documentos"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <ProcedureTab
                methods={methods}
                action={action}
                isEdit={isEdit}
              ></ProcedureTab>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <ServiceTab methods={methods} action={action} isEdit={isEdit} />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <MedicalCertificate
                methods={methods}
                action={action}
                isEdit={isEdit}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </DocumentAccordion>
    </>
  );
}
