import { Stack, Typography } from "@mui/material";

export function PatientMedicationOption({ option }: { option: any }) {
  // const tarjas = [
  //   {
  //     name: "VERMELHA",
  //     value: "CE",
  //     color: "red",
  //     display: "Controle especial",
  //   },
  //   {
  //     name: "PRETA",
  //     value: "CE",
  //     color: "black",
  //     display: "Controle especial",
  //   },
  //   {
  //     name: "AMARELA",
  //     value: "AM",
  //     color: "#FEE2A9",
  //     display: "Receita amarela",
  //   },
  //   {
  //     name: "AZUL",
  //     value: "AZ",
  //     color: "#A4CBE8",
  //     display: "Receita azul",
  //   },
  //   {
  //     name: "SEM TARJA",
  //     value: "BR",
  //     color: "black",
  //     display: "Receita branca",
  //   },
  // ];

  // const auxTag = tarjas.find((el: any) =>
  //   option.tag.toUpperCase()?.includes(el.name)
  // );

  return (
    <Stack
      width="100%"
      justifyContent="space-between"
      pl={2}
      pr={2}
      key={`${option.label}-${option.value}`}
    >
      <Stack direction="row" gap={1} alignItems="center">
        {/* <Avatar
          sx={{
            fontSize: 16,
            background: "white",
            color: auxTag?.color || "black",
            border: `1px solid ${auxTag?.color || "black"}`,
          }}
        >
          {auxTag?.value || "-"}
        </Avatar> */}
        <Typography
          width="fit-content"
          variant="body1"
          color="neutral1000.main"
          fontWeight={500}
        >
          {option?.name}
        </Typography>
        <Typography width="fit-content">
          {" "}
          {option?.description || "Descrição"}
        </Typography>
      </Stack>
      <Typography>{option?.presentation || "Apresentação"} </Typography>
    </Stack>
  );
}
