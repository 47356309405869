/**
 * @description Serviço de autenticação OAuth do Fluxmed
 * @module OAuthUser
 * @since Fevereiro 2024
 * 
 * Processo de autenticação:
 * 1. Constrói a URL de autenticação com o escopo específico do usuário
 * 2. Prepara os dados de autenticação no formato x-www-form-urlencoded
 * 3. Obtém o token básico de autorização
 * 4. Realiza a requisição POST para obter o token de acesso
 * 5. Retorna os tokens de acesso e refresh em caso de sucesso
 */

import { endpoints } from "../../configs/Settings";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

/**
 * @description Realiza autenticação OAuth do usuário
 * @param {string} username - Identificador do usuário (email, telefone ou username)
 * @param {string} password - Senha do usuário
 * @returns {Promise<{access_token: string, refresh_token: string, expires_in: number}>} Tokens de acesso e refresh
 * @throws {Error} Erro de autenticação com status
 */
export async function OAuthUser(username: string, password: string) {
  const url = `${endpoints.token_api}?scope=fluxmed_${
    username.split("[AT]")[0]
  }`;
  const data = `grant_type=password&username=${username}&password=${encodeURIComponent(
    password
  )}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getBasicToken(),
    "Content-Type": "application/x-www-form-urlencoded",
  };
  try {
    const response = await useAxios.post(url, data, { headers, timeout: 20000 });
    if (response.status !== 200) {
      throw new Error(`OAuthRequest error: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}
