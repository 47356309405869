import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";

// const primaryButton: any = {
//   icon: {
//     booked: <Description />,
//     arrived: <Description />,
//     proposed: <Check />,
//     pending: <Check />,
//   },
//   text: {
//     booked: "Preencher ficha de acompanhamento",
//     arrived: "Preencher ficha de acompanhamento",
//     proposed: "Confirmar consulta",
//     pending: "Confirmar consulta",
//   },
//   backgroundColor: {
//     booked: "primary700.main",
//     arrived: "primary700.main",
//     proposed: "neutral700.main",
//     pending: "neutral700.main",
//   },

//   hover: {
//     booked: "primary800.main",
//     arrived: "primary800.main",
//     proposed: "neutral600.main",
//     pending: "neutral600.main",
//   },
// };

const secondaryButton: any = {
  icon: {
    booked: <Close />,
    arrived: <Close />,
    proposed: <Close />,
    pending: <Close />,
  },
  text: {
    booked: "Cancelar consulta",
    arrived: "Cancelar consulta",
    proposed: "Cancelar consulta",
    pending: "Cancelar consulta",
  },
  borderColor: {
    booked: "primary700.main",
    arrived: "primary700.main",
    proposed: "neutral700.main",
    pending: "neutral700.main",
  },
  color: {
    booked: "primary700.main",
    arrived: "primary700.main",
    proposed: "neutral700.main",
    pending: "neutral700.main",
  },

  hover: {
    booked: "error100.main",
    arrived: "error100.main",
    proposed: "error100.main",
    pending: "error100.main",
  },
};

export function PatientActions({ status, setIsOpen }: any) {
  return (
    <>
      {/* <Button
        sx={{
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 3,
          fontSize: 16,
          textTransform: "none",
          color: "neutral0.main",
          backgroundColor: `${
            primaryButton.backgroundColor[status || "pending"]
          }`,
          ":hover": {
            backgroundColor: `${primaryButton.hover[status || "pending"]}`,
          },
        }}
      >
        {primaryButton.icon[status]} {primaryButton.text[status]}
      </Button> */}

      <Button
        sx={{
          gap: 1,
          border: "1px solid",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 3,
          fontSize: 16,
          textTransform: "none",
          backgroundColor: "transparent",
          borderColor: secondaryButton.borderColor[status || "pending"],
          color: secondaryButton.color[status || "pending"],
          ":hover": {
            backgroundColor: "neutral0.main",
          },
        }}
        onClick={() => setIsOpen(true)}
      >
        <Close /> {secondaryButton.text[status]}
      </Button>
    </>
  );
}
