import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "relative",
      }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          top: "50%",
          left: "50%",
          bottom: 0,
          right: 0,
          position: "absolute",
          transform: "translate(-50%, -16%)",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary", fontWeight: "bold" }}
        >
          {(props.value === 0 && "Iniciando...") ||
            (props.value < 100 && `${Math.round(props.value)}%`) ||
            (props.value === 100 && "Concluído!")}
        </Typography>
      </Box>
    </Box>
  );
}
