import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import { useSessionContext } from "../../../contexts/SessionContext";

import { toast } from "react-toastify";
import { useLocationContext } from "../../../contexts/LocationContext";
import { Location } from "../../schedule/components/Location";
import { PostLocation } from "../../../services/fhir";
import { Close } from "@mui/icons-material";
import { PutLocation } from "../../../services/fhir/location/PutLocation";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  editData?: any;
  setEditData?: any;
  row?: any;
}

export function CreateLocationDialog({
  isOpen,
  setIsOpen,
  editData,
  setEditData,
}: IProps) {
  const { access } = useSessionContext();
  const { refetchLocation } = useLocationContext();

  function handleClose() {
    setIsOpen(false);
  }

  async function createLocation(data: any) {
    try {
      if (editData) {
        const dataLocation = editData?.fullResource;
        console.log("dataLocation", dataLocation);
        const auxAddress = data?.serviceOnline
          ? {
              country: "Brasil",
            }
          : {
              city: data?.city,
              country: "Brasil",
              line: [
                data?.city,
                data?.state,
                data?.cep,
                data?.zone,
                data?.number,
                data?.street,
                data?.complement,
              ],
              ...(data?.cep && { postalCode: data?.cep }),
              state: data?.state,
            };
        const auxDataLocation = {
          ...dataLocation,
          name: data?.name,
          alias: [data?.name],
          address: auxAddress,
          contact:
            dataLocation?.contact === undefined
              ? [
                  {
                    telecom: [{ system: "phone", value: data?.phoneClinic }],
                  },
                ]
              : dataLocation.contact?.map((contactItem: any) => {
                  if (contactItem.telecom) {
                    return {
                      ...contactItem,
                      telecom: contactItem.telecom.map((telecomItem: any) =>
                        telecomItem.system === "phone"
                          ? { ...telecomItem, value: data?.phoneClinic }
                          : telecomItem
                      ),
                    };
                  }
                  return contactItem;
                }),
        };

        await PutLocation(auxDataLocation, dataLocation?.id);
      } else {
        await PostLocation(data, access.organizationId);
      }
      toast.success(
        editData
          ? "Localização atualizada com sucesso!"
          : "Localização criada com sucesso!"
      );
      refetchLocation();
    } catch {
      toast.warn(
        "Não foi possível finalizar a operação, por favor tente novamente!"
      );
    } finally {
      handleClose();
      setEditData(false);
    }
  }

  return (
    <Dialog
      open={isOpen}
      // onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "10px", width: "1000px" },
      }}
      maxWidth="lg"
    >
      <IconButton
        onClick={() => {
          handleClose();
          setEditData(false);
        }}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="center">Nova localização</DialogTitle>

      <DialogContent>
        <Location
          handleClose={handleClose}
          create={createLocation}
          editData={editData}
          setEditData={setEditData}
          defaultValues={
            editData
              ? {
                  serviceOnline: editData?.type === "Presencial" ? false : true,
                  cep: editData?.address.postalCode,
                  name: editData?.name,
                  city: editData?.address.city,
                  state: editData?.address.state,
                  zone: editData?.address?.line?.[3],
                  street: editData?.address.line?.[5],
                  number: editData?.address.line?.[4],
                  complement: editData?.address.line?.[6],
                  ...(editData?.phoneNumber !== "-" && {
                    phoneClinic: editData?.phoneNumber,
                  }),
                }
              : {
                  cep: "",
                  serviceOnline: false,
                  name: "",
                  city: "",
                  state: "",
                  zone: "",
                  street: "",
                  number: "",
                  complement: "",
                }
          }
        />
      </DialogContent>
    </Dialog>
  );
}
