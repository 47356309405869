import axios, { AxiosInstance } from "axios";

const useAxios: AxiosInstance = axios.create({
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 20000,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
});

useAxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { useAxios };
