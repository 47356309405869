/**
 * @description Utilitário de autenticação do Fluxmed
 * @module useAuth
 * @since Fevereiro 2024
 * 
 * Funções de autenticação:
 * - Gerenciamento de tokens (basic, application, user)
 * - Acesso a identificadores (subject, organization)
 * - Formatação de tokens para headers
 */

import base64 from "base-64";
import { endpoints } from "../configs/Settings";

/**
 * @description Gera o token básico para autenticação inicial
 * @returns {Promise<string>} Token no formato 'Basic {base64(key:secret)}'
 */
async function getBasicToken() {
  try {
    const credetials = base64.encode(
      `${endpoints.portalConsumerKey}:${endpoints.portalConsumerSecret}`
    );
    return `Basic ${credetials}`;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Recupera o token da aplicação do localStorage
 * @returns {Promise<string>} Token no formato 'Bearer {token}'
 */
async function getApplicationToken() {
  try {
    const application_token = localStorage.getItem("application_token");
    return `Bearer ${application_token}`;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Recupera o token do usuário do localStorage
 * @returns {Promise<string>} Token no formato 'Bearer {token}'
 */
async function getUserToken() {
  try {
    const token = localStorage.getItem("token");
    return `Bearer ${token}`;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Recupera o token de acesso puro do localStorage
 * @returns {Promise<string>} Token sem o prefixo Bearer
 */
async function getXUserToken() {
  try {
    const access_token = localStorage.getItem("token");
    return access_token;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Recupera o identificador do usuário do localStorage
 * @returns {Promise<string>} Username do usuário
 */
async function getSubjectId() {
  try {
    const subjectId = localStorage.getItem("username");
    return subjectId;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Recupera o identificador da organização do localStorage
 * @returns {Promise<string>} ID da organização
 */
async function getOrganizationId() {
  try {
    const organizationId = localStorage.getItem("organizationId");
    return organizationId;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Hook de autenticação com funções utilitárias
 */
export const useAuth = {
  getBasicToken,
  getApplicationToken,
  getUserToken,
  getSubjectId,
  getOrganizationId,
  getXUserToken,
};
