import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import "./CheckoutForm.css";
import { useSessionContext } from "../../../contexts/SessionContext";
import { SubscriptionService } from "../../../services/subscription/SubscriptionService";
import {
  CheckoutFormProps,
  SubscriptionData,
  PreapprovalResponse,
} from "../types";

declare global {
  interface Window {
    MercadoPago: any;
  }
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ selectedPlan }) => {
  const subscriptionService = useMemo(() => new SubscriptionService(), []);

  const [isSDKLoaded, setIsSDKLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscriptionSuccessful, setIsSubscriptionSuccessful] =
    useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error" | "warning",
  });
  const formRef = useRef<HTMLFormElement>(null);
  const cardFormRef = useRef<any>(null);
  const { user } = useSessionContext();
  const organizationValue = localStorage.getItem("organizationValue");

  const showSnackbar = useCallback(
    (message: string, severity: "success" | "error" | "warning") => {
      console.log("Mostrando Snackbar:", message, severity);
      setSnackbar({ open: true, message, severity });
      setTimeout(() => {
        console.log("Fechando Snackbar após 5 segundos");
        setSnackbar((prev) => ({ ...prev, open: false }));
      }, 5000);
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    console.log("Fechando Snackbar manualmente");
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handlePaymentSubmit = useCallback(
    async (cardToken: any) => {
      console.log("Iniciando submissão de pagamento");
      setIsSubmitting(true);
      try {
        const subscriptionData: SubscriptionData = {
          email: user.email[0],
          selectedPlan: selectedPlan,
          organizationId: organizationValue || "",
          cardToken: cardToken.token,
        };

        console.log("Dados da assinatura:", subscriptionData);
        const response: PreapprovalResponse =
          await subscriptionService.createSubscription(subscriptionData);

        if (response) {
          console.log("Resposta da criação de assinatura:", response);
          if (response.status === "authorized") {
            console.log("Assinatura criada com sucesso!");
            showSnackbar(
              "Assinatura criada com sucesso! Aguardando aprovação.",
              "success"
            );
            setIsSubscriptionSuccessful(true);
          } else {
            console.warn(
              "Assinatura criada, mas com status não esperado:",
              response.status
            );
            showSnackbar(
              "Assinatura criada, mas com status não esperado. Por favor, verifique.",
              "warning"
            );
          }
        } else {
          console.error("Resposta inválida do servidor");
          showSnackbar(
            "Erro na criação da assinatura. Por favor, tente novamente.",
            "error"
          );
        }
      } catch (error) {
        console.error("Erro ao processar a criação da assinatura:", error);
        if (error instanceof Error) {
          showSnackbar(`Erro ao criar assinatura: ${error.message}`, "error");
        } else {
          showSnackbar(
            "Erro desconhecido ao criar assinatura. Por favor, tente novamente.",
            "error"
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [selectedPlan, showSnackbar, user, organizationValue, subscriptionService]
  );

  useEffect(() => {
    const initMercadoPago = async () => {
      try {
        await loadMercadoPago();
        setIsSDKLoaded(true);
      } catch (error) {
        console.error("Erro ao carregar o SDK do Mercado Pago:", error);
        showSnackbar("Erro ao carregar o SDK do Mercado Pago", "error");
      } finally {
        setIsLoading(false);
      }
    };

    initMercadoPago();
  }, [showSnackbar]);

  useEffect(() => {
    if (!isSDKLoaded || !formRef.current) return;

    const publicKey = import.meta.env.VITE_MP_PUBLIC_KEY;
    if (!publicKey) {
      console.error("Chave pública do Mercado Pago não definida");
      showSnackbar("Erro de configuração do pagamento", "error");
      return;
    }

    const mp = new window.MercadoPago(publicKey, {
      locale: "pt-BR",
    });

    cardFormRef.current = mp.cardForm({
      amount: selectedPlan.auto_recurring.transaction_amount.toString(),
      autoMount: false,
      form: {
        id: "form-checkout",
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular do cartão",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número do cartão",
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "Data de expiração (MM/YYYY)",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de segurança",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Parcelas",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número do documento",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Emissor",
        },
      },
      callbacks: {
        onFormMounted: (error: any) => {
          if (error) {
            console.error("Erro ao montar o formulário:", error);
            showSnackbar("Erro ao montar o formulário", "error");
          } else {
            console.log("Formulário montado com sucesso");
          }
          setIsLoading(false);
        },
        onSubmit: (event: Event) => {
          event.preventDefault();
          cardFormRef.current
            .createCardToken()
            .then((cardToken: any) => {
              console.log("Token do cartão:", cardToken);
              handlePaymentSubmit(cardToken);
            })
            .catch((error: any) => {
              console.error("Erro ao criar token do cartão:", error);
              showSnackbar(
                "Erro ao processar o cartão. Por favor, verifique os dados e tente novamente.",
                "error"
              );
            });
        },
        onFetching: (resource: string) => {
          console.log("Buscando recurso:", resource);
          setIsLoading(resource === "payment_method_id");
        },
      },
    });

    setTimeout(() => {
      cardFormRef.current.mount();
    }, 0);
  }, [isSDKLoaded, selectedPlan, showSnackbar, handlePaymentSubmit]);

  useEffect(() => {
    if (isSubscriptionSuccessful) {
      const timer = setTimeout(() => {
        window.location.href = import.meta.env.VITE_MP_BACK_URL || "/";
      }, 5000); // Redireciona após 5 segundos

      return () => clearTimeout(timer);
    }
  }, [isSubscriptionSuccessful]);

  return (
    <div className="container">
      {snackbar.open && (
        <div className={`snackbar ${snackbar.severity}`}>
          {snackbar.message}
          <button onClick={handleSnackbarClose} className="snackbar-close">
            X
          </button>
        </div>
      )}

      {isSubscriptionSuccessful ? (
        <div className="success-message">
          <h2>Assinatura realizada com sucesso!</h2>
          <p>
            Obrigado por assinar o Fluxmed! Se você não for redirecionado
            automaticamente,{" "}
            <a
              href={import.meta.env.VITE_MP_BACK_URL}
              className="redirect-link"
              target="_self"
              rel="noopener noreferrer"
            >
              clique aqui
            </a>
            .
          </p>
        </div>
      ) : (
        <div className="form-container">
          <div className="form-wrapper">
            <form id="form-checkout" ref={formRef} className="checkout-form">
              <div className="form-group">
                <input
                  type="text"
                  id="form-checkout__cardholderName"
                  placeholder="Titular do cartão"
                  className="form-input"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  id="form-checkout__cardholderEmail"
                  className="form-input"
                  placeholder="E-mail"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="form-checkout__cardNumber"
                  className="form-input"
                  placeholder="Número do cartão"
                  required
                />
              </div>
              <div className="form-row">
                <div className="form-group form-group-half">
                  <input
                    type="text"
                    id="form-checkout__expirationDate"
                    className="form-input"
                    placeholder="Data de expiração (MM/YYYY)"
                    required
                  />
                </div>
                <div className="form-group form-group-half">
                  <input
                    type="text"
                    id="form-checkout__securityCode"
                    className="form-input"
                    placeholder="Código de segurança"
                    required
                  />
                </div>
              </div>
              <div className="form-group" style={{ display: "none" }}>
                <select
                  id="form-checkout__installments"
                  className="form-select"
                  disabled
                >
                  {/* Opções serão preenchidas pelo Mercado Pago */}
                </select>
              </div>
              <div className="form-group">
                <select
                  id="form-checkout__identificationType"
                  className="form-select"
                  required
                >
                  {/* Opções serão preenchidas pelo Mercado Pago */}
                </select>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="form-checkout__identificationNumber"
                  className="form-input"
                  placeholder="Número do documento"
                  required
                />
              </div>
              <div className="form-group">
                <select
                  id="form-checkout__issuer"
                  className="form-select"
                  disabled
                >
                  {/* Opções serão preenchidas pelo Mercado Pago */}
                </select>
              </div>
              <button
                type="submit"
                className={`submit-button ${
                  isLoading || isSubmitting ? "disabled" : ""
                }`}
                disabled={isLoading || isSubmitting}
              >
                {isSubmitting ? "Processando..." : "Finalizar Pagamento"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
