import { useEffect } from "react";
import { LargeContentBox } from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";

import { useNavigate } from "react-router-dom";
import { useHealthcareServiceContext } from "../../contexts/HealthcareServiceContext";
import { useLocationContext } from "../../contexts/LocationContext";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";
import { useSessionContext } from "../../contexts/SessionContext";
import { Form } from "./components/form/Form";
import { WarningSpecialtyDialog } from "./components/WarningSpecialtyDialog";

export function Scheduler() {
  const { access, user } = useSessionContext();
  const { refetchLocation } = useLocationContext();
  const { refetchHealthcareService } = useHealthcareServiceContext();

  const { practitionerRole } = usePractitionerRoleContext();

  const specialty = practitionerRole?.find((el: any) => {
    const verifyRole = access?.roles?.includes("manager")
      ? true
      : el?.resource?.id?.includes(user?.username);
    return el.resource?.specialty && verifyRole;
  });

  const navigate = useNavigate();

  const handleClose = () => navigate(-1);

  useEffect(() => {
    refetchLocation();
    refetchHealthcareService();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Form />
      </LargeContentBox>

      {!specialty && (
        <WarningSpecialtyDialog open={!specialty} handleClose={handleClose} />
      )}
    </PrivateMainLayout>
  );
}
