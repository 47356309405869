import "react-international-phone/style.css";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { Controller } from "react-hook-form";

import { FormInputProps } from "../types";

interface InternationalPhoneProps extends FormInputProps {
  defaultCountry?: CountryIso2;
}

export const InternationalPhone: React.FC<InternationalPhoneProps> = ({
  name,
  control,
  label,
  required,
  defaultCountry = "br",
  placeholder = "Número de telefone",
  defaultValue,
  autoFocus,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      defaultCountry,
      value: defaultValue,
    });

  const formatBrazilianPhoneNumber = (value: any) => {
    const digits = value.replace(/\D/g, "");

    if (digits.length === 12) {
      return `+${digits.slice(0, 2)} (${digits.slice(2, 4)}) ${digits.slice(
        4,
        8
      )}-${digits.slice(8, 12)}`;
    } else if (digits.length === 13) {
      return `+${digits.slice(0, 2)} (${digits.slice(2, 4)}) ${digits.slice(
        4,
        9
      )}-${digits.slice(9, 13)}`;
    }
  };

  const getValidationRules = (countryIso2: CountryIso2 | undefined) => {
    if (countryIso2 === "br") {
      return {
        pattern: {
          value: /^\+55\s\(\d{2}\)\s\d{4,5}-\d{4}$/,
          message: "Insira um número de telefone válido no formato brasileiro.",
        },
      };
    } else {
      return {
        pattern: {
          value: /^\+\d{1,3}\s?\(?\d+\)?[\d\s-]+$/,
          message:
            "Insira um número de telefone válido no formato internacional.",
        },
      };
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
        ...getValidationRules(country?.iso2),
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          variant="filled"
          label={label || "Número de telefone"}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={inputValue}
          onChange={(e) => {
            const rawValue = e.target.value;
            if (rawValue.length <= 19) {
              handlePhoneValueChange(e);
              const formattedValue =
                country?.iso2 === "br"
                  ? formatBrazilianPhoneNumber(rawValue)
                  : rawValue;
              onChange(formattedValue);
            }
          }}
          type="tel"
          sx={{
            "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
          }}
          error={!!error}
          helperText={error ? error.message : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  value={country?.iso2 || ""}
                  onChange={(e) => {
                    const selectedCountry = e.target.value as CountryIso2;
                    setCountry(selectedCountry);
                    onChange(selectedCountry);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                        width: "360px",
                      },
                    },
                  }}
                  sx={{
                    minWidth: "80px",
                    fieldset: { display: "none" },
                    ".MuiSelect-select": { padding: "8px" },
                  }}
                  renderValue={(value) => (
                    <FlagImage
                      iso2={value as CountryIso2}
                      style={{ display: "flex" }}
                    />
                  )}
                >
                  {defaultCountries.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        <FlagImage
                          iso2={country.iso2}
                          style={{ marginRight: "8px" }}
                        />
                        <Typography marginRight="8px">
                          {country.name}
                        </Typography>
                        <Typography color="gray">
                          +{country.dialCode}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputAdornment>
            ),
          }}
          fullWidth
          {...restProps}
        />
      )}
    />
  );
};
