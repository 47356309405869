import {
  Article,
  Description,
  Download,
  FileUpload,
} from "@mui/icons-material";
import { IconButton, Stack, styled, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { usePatientHealthCareInformationContext } from "../../../contexts/PatientHealthCareInformationContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { IframeDialog } from "../../ehrrecords/components/IframeDialog";
import { ConfirmDocumentDialog } from "./ConfirmDocumentDialog";

interface IProps {
  row: any;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function ExamSolicitationActions({ row }: IProps) {
  const { user } = useSessionContext();
  const { setRefetchServiceRequest } = usePatientHealthCareInformationContext();
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [viewResults, setViewResults] = useState<boolean>(false);
  const [base64, setBase64] = useState<any>();
  const [base64Results, setBase64Results] = useState<any>();
  const [fileBase64, setFileBase64] = useState<any>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  async function getDocumentPdf() {
    const response = await GetBinary({ urlBinary: row?.binaryUrl });
    setBase64(response);
    return response;
  }

  async function downloadBase64File() {
    const auxDocument = !base64 ? await getDocumentPdf() : base64;
    const linkSource = `data:${auxDocument?.contentType};base64,${auxDocument?.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "Solicitações de exames";
    downloadLink.click();
  }

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };

  const convertToBase64 = (file: File) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
  };

  const docReferenceData = {
    servicePratice: {
      code: row?.fullResource?.practiceSetting?.coding?.[0]?.code,
      system: row?.fullResource?.practiceSetting?.coding?.[0]?.system,
      display: row?.fullResource?.practiceSetting?.coding?.[0]?.display,
    },
    time: {
      start: dayjs().toISOString(),
      end: dayjs().toISOString(),
    },
  };

  const refs = {
    id: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Encounter"))
      ?.reference?.split("/")?.[1],
    subject: user?.username,
    appointmentId: row?.fullResource?.context
      ?.find((el: any) => el?.reference?.includes("Appointment"))
      ?.reference?.split("/")?.[1],
  };

  return (
    <Stack width="fit-content">
      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => setOpenDocument(true)}>
          <Tooltip title="Visualizar documento">
            <Article />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => downloadBase64File()}>
          <Tooltip title="Baixar">
            <Download />
          </Tooltip>
        </IconButton>

        {row.auxResults ? (
          <IconButton onClick={() => setViewResults(true)}>
            <Tooltip title="Resultado de exame">
              <Description />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton
            onClick={handleClickUpload}
            onChange={(e: any) => uploadImage(e)}
          >
            <Tooltip title="Enviar resultado">
              <FileUpload sx={{ color: "primary700.main" }} />
            </Tooltip>
            <VisuallyHiddenInput ref={fileInputRef} type="file" accept=".pdf" />
          </IconButton>
        )}
      </Stack>

      {openDocument && (
        <IframeDialog
          handleClose={() => setOpenDocument(false)}
          isOpen={openDocument}
          data={row?.binaryUrl}
          title={row?.description}
          loadedBase64={base64}
          setLoaded={setBase64}
        />
      )}
      {viewResults && (
        <IframeDialog
          handleClose={() => setViewResults(false)}
          isOpen={viewResults}
          data={row?.auxResults?.resource?.content?.[0]?.attachment?.url || ""}
          title="Resultado de exame"
          loadedBase64={base64Results}
          setLoaded={setBase64Results}
        />
      )}

      {fileBase64 && (
        <ConfirmDocumentDialog
          setRenderButton={() => {}}
          setRefetch={setRefetchServiceRequest}
          fileBase64={fileBase64}
          isOpenDialog={fileBase64}
          onCloseDialog={() => {
            setFileBase64(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }}
          basedOn={row?.fullResource?.basedOn?.filter((el: any) =>
            el.reference?.includes("ServiceRequest")
          )}
          encounterReference={refs}
          data={{
            ...docReferenceData,
            base64: fileBase64?.split(",")?.[1],
            codeValue: "RESULT",
            codeDisplay: "Resultado de exame",
            categoryValue: "resultado",
            categoryDisplay: "Resultado de exame",
          }}
        />
      )}
    </Stack>
  );
}
