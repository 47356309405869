import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

import { PatchAppointmentStatus } from "../../../services/fhir";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../components/form";
import { DeleteWhereby } from "../../../services/whereby/DeleteWhereby";

export function NextAppointmentsDialog({ setModalOpen, open, row }: any) {
  const [loading, setLoading] = useState(false);

  const handleCancelAppointment = async (data: any) => {
    setLoading(true);
    try {
      await PatchAppointmentStatus({
        appointmentId: data?.auxCancelAppointment.id,
        status: "cancelled",
      });
      row.setTabRefetch((prev: boolean) => !prev);

      // Validação para deletar a consulta no Whereby tanto na agenda do profissional quanto na agenda do manager
      if (data?.auxCancelAppointment?.wherebyId) {
        DeleteWhereby(data?.auxCancelAppointment?.wherebyId);
      } else if (data?.appointment?.virtualService) {
        DeleteWhereby(
          data?.appointment?.virtualService?.[0]?.sessionKey as string
        );
      }

      toast.success("Consulta cancelada com sucesso!");
      setModalOpen(false);
    } catch (err) {
      toast.error("Não foi possível cancelar a consulta selecionada!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={open}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          Cancelar consulta
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack
          height="100%"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">
            Deseja confirmar a operação?
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            width="100%"
            mt={3}
          >
            <FormButtonGroup
              onGoBackButtonClick={() => setModalOpen(false)}
              onNextButtonClick={() => handleCancelAppointment(row)}
              goBackButtonText="Não"
              nextButtonText="Sim"
              loading={loading}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
