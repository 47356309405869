import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { endpoints, oids } from "../configs/Settings";
import { GetCustomization } from "../services/fhir/questionnaireresponse/GetCustomization";
import { PostAdminQuestionnaireResponse } from "../services/fhir/questionnaireresponse/PostAdminQuestionnaireResponse";
import { PutAdminQuestionnaireResponse } from "../services/fhir/questionnaireresponse/PutAdminQuestionnaireResponse";
import { useFhirData } from "../utils/useFhirData";
import { useMedicalCareContext } from "./MedicalCareContext";
import { useOrganizationContext } from "./OrganizationContext";
import { useSessionContext } from "./SessionContext";

interface CustomizationContextValue {
  customization: any;
  itens: any;
  handleDeleteItem: any;
  handleAddItem: any;
  customizationResponse: any;
  defaultCustomID: any;
  saveCustomization: any;
}

export const CustomizationContext = createContext<CustomizationContextValue>(
  null!
);

export function CustomizationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { user } = useSessionContext();
  const { appointment } = useMedicalCareContext();
  const { organization } = useOrganizationContext();

  const appointmentSpecialty = useFhirData.findParticipant(
    appointment?.participant,
    "Healthcare"
  )?.display;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);

  const [customization, setCustomization] = useState<boolean>(false);
  const [defaultCustomID, setDefaultCustomID] = useState<string>();

  const [itens, setItens] = useState<any>();

  const customizationResponse = {
    resourceType: "QuestionnaireResponse",
    status: "completed",
    questionnaire: "Questionnaire/CustomRegister",
    author: {
      reference: `Organization/${organization?.id}`,
    },
    source: {
      reference: `Practitioner/${oids.cpf}-${user?.username}`,
    },
    item: [
      {
        linkId: "899805419404",
        text: "Especialidade",
        answer: [
          {
            valueString: appointmentSpecialty, // Exemplo de resposta
          },
        ],
      },
      {
        linkId: "935128685464",
        text: "Exame geral",
        item: [
          {
            linkId: "435429321326",
            text: "Item",
            item: itens?.[0]?.itens?.map((it: any) => ({
              item: [
                {
                  linkId: "133692821346",
                  text: "Descrição",
                  answer: [
                    {
                      valueString: it?.text,
                    },
                  ],
                },
                {
                  linkId: "367544046444",
                  text: "Editável",
                  answer: [
                    {
                      valueBoolean: it?.notEditable || false,
                    },
                  ],
                },
              ],
            })),
          },
        ],
      },
      {
        linkId: "836259206228",
        text: "Exame específico",
        item: [
          {
            linkId: "513468617436",
            text: "Item",
            item: itens?.[1]?.itens?.map((it: any) => ({
              item: [
                {
                  linkId: "640753300173",
                  text: "Descrição",
                  answer: [
                    {
                      valueString: it?.text,
                    },
                  ],
                },
                {
                  linkId: "316465547919",
                  text: "Editável",
                  answer: [
                    {
                      valueBoolean: it?.notEditable || false,
                    },
                  ],
                },
              ],
            })),
          },
        ],
      },
    ],
  };

  const handleDeleteItem = (specialty: string, itemIndex: number) => {
    setCustomization(true);
    setItens((prevTabs: any[]) =>
      prevTabs.map((tab) =>
        tab.specialty === specialty
          ? {
              ...tab,
              itens: tab.itens.filter(
                (_: any, index: number) => index !== itemIndex
              ),
            }
          : tab
      )
    );
  };

  const handleAddItem = (specialty: string, item: string) => {
    setCustomization(true);
    setItens((prevTabs: any[]) =>
      prevTabs?.map((tab) =>
        tab?.specialty === specialty
          ? {
              ...tab,
              itens: [
                ...tab?.itens,
                { text: item }, // Exemplo de novo item
              ],
            }
          : tab
      )
    );
  };

  async function saveCustomization() {
    if (customization) {
      defaultCustomID
        ? await PutAdminQuestionnaireResponse(
            {
              ...customizationResponse,
              id: defaultCustomID,
            },
            defaultCustomID
          )
        : await PostAdminQuestionnaireResponse(customizationResponse);
    }
    setCustomization(false);
    // setDefaultCustomID("");
    // setItens(undefined);
  }

  useEffect(() => {
    const examsOptions = [
      {
        text: "Geral",
        specialty: "Geral",
        itens: [
          {
            text: "Cabeça e pescoço",
            notEditable: true,
          },
          {
            text: "Ap. Cardiovascular",
            notEditable: true,
          },
          {
            text: "Ap. Abdominal",
            notEditable: true,
          },
          {
            text: "Ap. Respiratório",
            notEditable: true,
          },
          {
            text: "Ap. urinário",
            notEditable: true,
          },
          {
            text: "Osteoarticular",
            notEditable: true,
          },
          {
            text: "Pele e fâneros",
            notEditable: true,
          },
          {
            text: "Pele e fâneros",
            notEditable: true,
          },
          {
            text: "Extremidades",
            notEditable: true,
          },
          {
            text: "Mucosas",
            notEditable: true,
          },
        ],
      },
      {
        text: "Específico",
        specialty: "Neurologia",
        itens: [
          {
            text: "Estado Mental",
          },
          {
            text: "Funções corticais superiores",
          },
          {
            text: "Marcha",
          },
          {
            text: "Nervos Cranianos",
          },
          {
            text: "Motricidade",
          },
          {
            text: "Reflexos",
          },
          {
            text: "Cerebelares",
          },
          {
            text: "Sensorial",
          },
          {
            text: "SNA",
          },
          {
            text: "Geral",
          },
        ],
      },
      {
        text: "Específico",
        specialty: "Reumatologia",
        itens: [
          {
            text: "Coluna vertebral",
          },
          {
            text: "Mãos/Punhos",
          },
          {
            text: "Cotovelos/ombros",
          },
          {
            text: "Pelve",
          },
          {
            text: "Joelhos",
          },
          {
            text: "Tornozelos",
          },
          {
            text: "Índices",
          },
        ],
      },
      {
        text: "Específico",
        specialty: "Psiquiatria",
        itens: [
          {
            text: "Rapport e desejo de ajuda",
          },
          {
            text: "Aparência e comportamento",
          },
          {
            text: "Atividade motora",
          },
          {
            text: "Linguagem e Fala",
          },
          {
            text: "Consciência",
          },
          {
            text: "Atenção e concentração",
          },
          {
            text: "Afetividade/Humor",
          },
          {
            text: "Pensamento",
          },
          {
            text: "Sensopercepção",
          },
          {
            text: "Raciocínio abstrato",
          },
          {
            text: "Juízo Crítico",
          },
        ],
      },
    ];

    async function fetchCustomization() {
      try {
        setCustomization(false);
        const response = await GetCustomization({
          questionnaire: endpoints.CUSTOMIZATION_ID,
          source: user?.username,
        });

        const verifySpecialty = response?.entry
          ?.map((e: any) => e?.resource)
          ?.find((el: any) => {
            const auxItem =
              el?.item?.find((e: any) => e.text === "Especialidade")
                ?.answer?.[0]?.valueString === appointmentSpecialty;
            return auxItem;
          });

        setDefaultCustomID(verifySpecialty?.id);

        if (verifySpecialty) {
          const geral = verifySpecialty?.item?.find(
            (e: any) => e.text === "Exame geral"
          )?.item?.[0]?.item;

          const especifico = verifySpecialty?.item?.find(
            (e: any) => e.text === "Exame específico"
          )?.item?.[0]?.item;

          setItens([
            {
              text: "Geral",
              specialty: "Geral",
              itens:
                geral?.map((e: any) => {
                  return {
                    text: e?.item?.[0]?.answer?.[0]?.valueString,
                    notEditable:
                      e?.item?.[1]?.answer?.[0]?.valueBoolean || false,
                  };
                }) || [],
            },
            {
              text: "Específico",
              specialty: appointmentSpecialty,
              itens:
                especifico?.map((e: any) => ({
                  text: e?.item?.[0]?.answer?.[0]?.valueString,
                  notEditable: e?.item?.[1]?.answer?.[0]?.valueBoolean || false,
                })) || [],
            },
          ]);
        } else {
          throw new Error();
        }
      } catch {
        setItens([
          examsOptions?.[0],
          examsOptions?.find(
            (e: any) =>
              e?.specialty === appointmentSpecialty && e?.specialty !== "Geral"
          ) || {
            text: "Específico",
            specialty: appointmentSpecialty,
            itens: [],
          },
        ]);
      }

      setLoading(false);
    }

    if (appointmentSpecialty) fetchCustomization();
  }, [appointment, appointmentSpecialty, user?.username]);

  const contextValue = {
    customization,
    itens,
    handleAddItem,
    handleDeleteItem,
    customizationResponse,
    defaultCustomID,
    saveCustomization,
  };

  return (
    <CustomizationContext.Provider value={contextValue}>
      {children}
    </CustomizationContext.Provider>
  );
}

export function useCustomizationContext() {
  return useContext(CustomizationContext);
}
