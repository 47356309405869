import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { ContentBoxTitle } from "../../../components/common";

import { Info } from "@mui/icons-material";
import { FormButtonGroup, FormStack } from "../../../components/form";
import { oids, OrganizationPartOf } from "../../../configs/Settings";
import { useSessionContext } from "../../../contexts/SessionContext";
import {
  PutOrganization,
  PutPractitioner,
  PutPractitionerRole,
} from "../../../services/fhir";
import { handleKeyPress } from "../../../utils/useKeyPress";
import TermText from "../../TermsOfUse/components/TermText";
import { IFormInput } from "../types";

interface IProps {
  handleSubmit: any;
  onBackButtonClick: Function;
  onNextButtonClick: Function;
}

export function TermsOfUse({
  handleSubmit,
  onBackButtonClick,
  onNextButtonClick,
}: IProps) {
  const [checkbox, setCheckbox] = useState(false);
  const { user } = useSessionContext();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IFormInput) => {
    // Verifica se o checkbox está marcado
    if (!checkbox) {
      toast.error(
        "A aceitação dos Termos de Uso e Política de Privacidade é obrigatória para continuar."
      );
      return; // Interrompe a execução da função aqui
    }

    data.organization.id =
      `${oids.cnpj}-${data.organization?.identifier[0]?.value}` || "";

    const organizationId = data.organization.id;

    setLoading(true);
    try {
      const auxOrganization =
        OrganizationPartOf?.id?.length &&
        data.organization?.identifier[0]?.value !== OrganizationPartOf?.id
          ? {
              ...data?.organization,
              partOf: {
                reference: `Organization/${oids.cnpj}-${OrganizationPartOf?.id}`,
                display: `${OrganizationPartOf?.display}`,
              },
            }
          : data?.organization;
      const organizationResponse = await PutOrganization(auxOrganization);
      const auxDescription = organizationResponse?.parameter?.find(
        (el: any) => el.name === "description"
      )?.valueString;
      const { id: practitionerId } = await PutPractitioner({
        name: user.name,
        identifier: user.username,
        birthDate: user.birthDate,
      });
      await PutPractitionerRole({
        organizationId,
        organizationDisplay: auxDescription,
        practitionerId,
        practitionerDisplay: user.name,
      });
      toast.success("Organização criada com sucesso!");
      onNextButtonClick(organizationId);
    } catch (err) {
      toast.error("Não foi possível criar a organização");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onKeyDown={(event) =>
        handleKeyPress(event, loading, handleSubmit(onSubmit))
      }
      style={{ height: "100%", width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>
          Termos de Uso e Política de Privacidade
        </ContentBoxTitle>
        <Box
          maxHeight="492px"
          sx={{
            width: "100%",
            overflowY: "scroll",
            mt: 2,
            mb: 2,
          }}
        >
          <Box height="70vh">
            <TermText termOf="owner" />
          </Box>
        </Box>
        <FormGroup sx={{ width: "100%", textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={() => setCheckbox(!checkbox)}
              />
            }
            label="Eu li e estou de acordo com os Termos de Uso e Politica de Privacidade."
          />
        </FormGroup>
        {!checkbox && (
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={2}
            width="100%"
          >
            <Info sx={{ color: "neutral300.main" }} />
            <Typography
              variant="body1"
              fontWeight={400}
              color="neutral600.main"
            >
              Aceite os Termos de Uso e Política de Privacidade para continuar o
              acesso a plataforma e seus serviços, ao recusar os Termos de Uso e
              Política de Privacidade,pode levar a perda de acesso a plataforma
              e seus serviços.
            </Typography>
          </Stack>
        )}
        <Stack
          direction="row-reverse"
          width="100%"
          mt={3}
          spacing={3}
          justifyContent="space-between"
        >
          <FormButtonGroup
            maxWidth
            onGoBackButtonClick={() => onBackButtonClick()}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Recusar"
            nextButtonText="Aceitar"
            loading={loading}
            disabled={checkbox === false}
          />
        </Stack>
      </FormStack>
    </form>
  );
}
