import "@whereby.com/browser-sdk/embed";
import { Container } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";

interface WherebyComponentProps {
  roomUrl: string;
  screenSize: number;
  onClose: () => void;
}

const WherebyComponent: React.FC<WherebyComponentProps> = ({
  roomUrl,
  screenSize,
  onClose,
}) => {
  const wherebyRef = useRef<any>(null);
  const [iframeReady, setIframeReady] = useState(false);

  const handleReadyToClose = () => {
    onClose(); // Notifique o componente pai
  };

  useEffect(() => {
    // Verificar se a URL da sala está correta
    console.log("Conectando ao Whereby com URL:", roomUrl);
  }, [roomUrl]);

  useEffect(() => {
    if (wherebyRef.current && iframeReady) {
      const width = 400 * screenSize;
      const height = 250 * screenSize;
      wherebyRef.current.style.width = `${width}px`;
      wherebyRef.current.style.height = `${height}px`;
    }
  }, [screenSize, iframeReady]);

  return (
    <Container sx={{ padding: "0 !important" }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <whereby-embed
          ref={wherebyRef}
          room={roomUrl}
          style={{
            width: `${400 * screenSize}px`,
            height: `${250 * screenSize}px`,
            border: "none",
          }}
          // onLoad={() => setIframeReady(true)}
        />
      </div>
    </Container>
  );
};

export default WherebyComponent;
