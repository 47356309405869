import { Stack } from "@mui/material";

import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { CircularLoading } from "../../../../../components/common";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { BundlePatientSummary } from "../../../../../services/fhir";
import { useFhirData } from "../../../../../utils/useFhirData";
import { IframeDialog } from "../../../../ehrrecords/components/IframeDialog";
import { MedicalNotes } from "../../../../ehrrecords/components/patientSummaryAccordions/Documents/components/MedicalNotes";
import { NoteReport } from "../../../../ehrrecords/components/patientSummaryAccordions/Documents/components/NoteReport";
import { Solicitations } from "../../../../ehrrecords/components/patientSummaryAccordions/Documents/components/Solicitations";
import { MyfamilyForDash } from "../../../../myfamily/MyfamilyForDash";

// interface TabPanelProps {
//   children?: ReactNode;
//   index: number;
//   value: number;
// }

export function ViewDocs({ type, sign }: any) {
  const { user } = useSessionContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState();
  const [refetch, setRefetch] = useState<boolean>(false);

  const medicalNoteData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "prescricao"
  )?.[0]?.entry;

  const solicitationData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "service-request"
  )?.[0]?.entry;

  const NoteReportData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "atestado"
  )?.[0]?.entry;

  const resultsData = useFhirData.extractPatientSummaryResource(
    summaryData,
    "DocumentReference",
    "resultado"
  );
  // const medicalNote = medicalNoteData?.filter((e: any) => {
  //   return (
  //     e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
  //   );
  // });

  // const solicitation = solicitationData?.filter((e: any) => {
  //   return (
  //     e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin" &&
  //     e?.resource?.category?.[0]?.coding?.[0]?.display === "Solicitação"
  //   );
  // });

  // const noteReport = NoteReportData?.filter((e: any) => {
  //   return (
  //     e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
  //   );
  // });

  useEffect(() => {
    async function GetBundle() {
      setLoading(true);
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: user?.username,
          purposeofuse: "Atendimento",
        });

        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoading(false);
      }
    }
    if (user?.username) GetBundle();
  }, [user, refetch]);

  const [open, setOpen] = useState<boolean>(false);
  const [binaryUrl, setBinaryUrl] = useState<string>("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBinaryUrl("");
    setOpen(false);
  };

  return (
    <Stack
      sx={{
        padding: "20px 10px",
        width: "100%",
        height: "100%",
        maxHeight: isMobile ? window.innerHeight - 150 : "100%",
      }}
    >
      {loading ? (
        <Stack sx={{ height: "100%" }}>
          <CircularLoading />
        </Stack>
      ) : (
        <>
          {type === "Notes" && (
            <MedicalNotes
              sign
              data={medicalNoteData}
              handleOpen={handleOpen}
              setBinaryUrl={setBinaryUrl}
            />
          )}

          {type === "Exames" && (
            <Solicitations
              setRefetch={setRefetch}
              sign
              handleOpen={handleOpen}
              setBinaryUrl={setBinaryUrl}
              data={solicitationData}
              resultsData={resultsData}
            />
          )}

          {type === "Certificates" && (
            <NoteReport
              sign
              data={NoteReportData}
              handleOpen={handleOpen}
              setBinaryUrl={setBinaryUrl}
            />
          )}
          {type === "MyFamily" && <MyfamilyForDash />}

          {open && (
            <IframeDialog
              handleClose={handleClose}
              isOpen={open}
              data={binaryUrl}
            />
          )}
        </>
      )}
    </Stack>
  );
}
