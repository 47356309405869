import React from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle, Stack } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import { toast } from "react-toastify";

interface IProps {
  type: "add" | "edit";
  isOpen: boolean;
  handleClose: VoidFunction;
  display: any;
  control: any;
  handleSubmit: any;
}
export function GenderDialog({
  type,
  isOpen,
  handleClose,
  display,
  control,
  handleSubmit,
}: IProps) {
  const { userPatient, fetchUser } = useSessionContext();

  const genderCodes = [
    {
      value: "unknown",
      display: "Desconhecido",
      code: "UNK",
    },
    {
      value: "Identifies as nonbinary gender",
      display: "Não binário",
      code: "33791000087105",
    },
    {
      value: "Identifies as male gender",
      display: "Masculino",
      code: "446151000124109",
    },
    {
      value: "Identifies as female gender",
      display: "Feminino",
      code: "446141000124107",
    },
    {
      value: "Female-to-male transsexual",
      display: "Transgênero Masculino",
      code: "407377005",
    },
    {
      value: "Male-to-female transsexual",
      display: "Transgênero Feminino",
      code: "407376001",
    },
    {
      value: "Asked But Declined",
      display: "Prefiro não responder",
      code: "asked-declined",
    },
  ];

  const auxDefault = genderCodes?.find((el: any) => el.display === display);

  function verifyPath(extensionGender: any) {
    if (extensionGender === undefined) {
      return "/extension";
    }
    if (extensionGender === -1) {
      return "/extension/-";
    } else {
      return `/extension/${extensionGender}`;
    }
  }

  const auxFindExtensionGender = userPatient?.extension?.findIndex(
    (el: any) =>
      el.url ===
      "http://www.saude.gov.br/fhir/StructureDefinition/BRIdentidadeGenero"
  );

  const onSubmit = async (data: any) => {
    const auxDisplay = genderCodes?.find(
      (el: any) => el.value === data.genderIdentity?.value
    )?.display;

    const path = verifyPath(auxFindExtensionGender);
    const content = {
      url: "http://www.saude.gov.br/fhir/StructureDefinition/BRIdentidadeGenero",
      valueCodeableConcept: {
        coding: [
          {
            system:
              "http://www.saude.gov.br/fhir/r4/CodeSystem/BRIdentidadeGenero",
            code: data?.genderIdentity?.code,
            display: auxDisplay,
          },
        ],
      },
    };

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: auxFindExtensionGender === undefined ? [content] : content,
      });
      await fetchUser();
      toast.success(
        `Identidade de gênero ${
          type === "add" ? "adicionada" : "atualizada"
        } com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>
        {type === "add" ? "Adicionar" : "Atualizar"} Identidade de Gênero
        <Stack marginY={2}>
          <FormSelectCompleted
            defaultValue={auxDefault}
            name="genderIdentity"
            label=""
            control={control}
            selectOptions={genderCodes}
            required
          />
        </Stack>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </DialogTitle>
    </CommonDialog>
  );
}
