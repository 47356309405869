import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";
import { EditableCardRow } from "../../../components/common";
import { AddressDialog } from "./AddressDialog";
import { RaceDialog } from "./RaceDialog/RaceDialog";
import { useForm } from "react-hook-form";
import { GenderDialog } from "./GenderDialog/GenderDialog";
import { EducationDialog } from "./EducationDialog/EducationDialog";
import { ReligionDialog } from "./ReligionDialog/ReligionDialog";
import { MaritalStatusDialog } from "./MaritalStatusDialog/MaritalStatusDialog";

export function CardDemographic() {
  const { userPatient } = useSessionContext();
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false);
  const [dialogDemographic, setDialogDemographic] = useState<{
    [key: string]: boolean;
  }>({
    race: false,
    gender: false,
    education: false,
    religion: false,
    maritalStatus: false,
  });

  const { control, handleSubmit } = useForm();

  const auxType = {
    raceExists: userPatient?.extension?.find(
      (el: any) =>
        el.url ===
        "http://www.saude.gov.br/fhir/StructureDefinition/BRRacaCorEtnia-1.0"
    ),
    genderExists: userPatient?.extension?.find(
      (el: any) =>
        el.url ===
        "http://www.saude.gov.br/fhir/StructureDefinition/BRIdentidadeGenero"
    ),
    educationExists: userPatient?.extension?.find(
      (el: any) =>
        el.url === "http://terminology.hl7.org/ValueSet/v3-EducationLevel"
    ),
    religionExist: userPatient?.extension?.find(
      (el: any) =>
        el.url === "http://terminology.hl7.org/ValueSet/v3-ReligiousAffiliation"
    ),
    maritalStatusExists: userPatient?.maritalStatus,
  };

  const viewText = {
    displayRace:
      auxType?.raceExists?.valueCodeableConcept?.coding?.[0]?.display,
    displayGender:
      auxType?.genderExists?.valueCodeableConcept?.coding?.[0]?.display,
    displayEducation:
      auxType?.educationExists?.valueCodeableConcept?.coding?.[0]?.display,
    displayReligion:
      auxType?.religionExist?.valueCodeableConcept?.coding?.[0]?.display,
    displayMaritalStatus: auxType?.maritalStatusExists?.coding?.[0]?.display,
  };

  const auxAddress = userPatient?.address?.[0];

  const city = auxAddress?.city || "";
  const district = auxAddress?.district || "";
  const state = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, district, city, state, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Dados demográficos
      </Typography>

      <EditableCardRow
        title="Endereço"
        titleContext={address}
        type={userPatient?.address ? "edit" : "add"}
        onClick={() => setAddressDialogOpen(true)}
      />

      <EditableCardRow
        type={!auxType?.raceExists ? "add" : "edit"}
        title="Raça ou etnia"
        titleContext={viewText?.displayRace || "-"}
        onClick={() =>
          setDialogDemographic((prev: any) => ({ ...prev, race: true }))
        }
      />

      <EditableCardRow
        type={!auxType?.genderExists ? "add" : "edit"}
        title="Identidade de Gênero"
        titleContext={viewText?.displayGender || "-"}
        onClick={() =>
          setDialogDemographic((prev: any) => ({ ...prev, gender: true }))
        }
      />

      <EditableCardRow
        type={!auxType?.educationExists ? "add" : "edit"}
        title="Escolaridade"
        titleContext={viewText?.displayEducation || "-"}
        onClick={() =>
          setDialogDemographic((prev: any) => ({ ...prev, education: true }))
        }
      />

      <EditableCardRow
        type={!auxType?.religionExist ? "add" : "edit"}
        title="Religião"
        titleContext={viewText?.displayReligion || "-"}
        onClick={() =>
          setDialogDemographic((prev: any) => ({ ...prev, religion: true }))
        }
      />

      <EditableCardRow
        type={!auxType?.maritalStatusExists ? "add" : "edit"}
        title="Estado Civil"
        titleContext={viewText?.displayMaritalStatus || "-"}
        onClick={() =>
          setDialogDemographic((prev: any) => ({
            ...prev,
            maritalStatus: true,
          }))
        }
      />

      {dialogDemographic?.race && (
        <RaceDialog
          type={!auxType?.raceExists ? "add" : "edit"}
          isOpen={dialogDemographic?.race}
          handleClose={() =>
            setDialogDemographic((prev: any) => ({ ...prev, race: false }))
          }
          display={viewText?.displayRace}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}

      {dialogDemographic?.gender && (
        <GenderDialog
          type={!auxType?.genderExists ? "add" : "edit"}
          isOpen={dialogDemographic?.gender}
          handleClose={() =>
            setDialogDemographic((prev: any) => ({ ...prev, gender: false }))
          }
          display={viewText?.displayGender}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}
      {dialogDemographic?.education && (
        <EducationDialog
          type={!auxType?.educationExists ? "add" : "edit"}
          isOpen={dialogDemographic?.education}
          handleClose={() =>
            setDialogDemographic((prev: any) => ({ ...prev, education: false }))
          }
          display={viewText?.displayEducation}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}
      {dialogDemographic?.religion && (
        <ReligionDialog
          type={!auxType?.religionExist ? "add" : "edit"}
          isOpen={dialogDemographic?.religion}
          handleClose={() =>
            setDialogDemographic((prev: any) => ({ ...prev, religion: false }))
          }
          display={viewText?.displayReligion}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}
      {dialogDemographic?.maritalStatus && (
        <MaritalStatusDialog
          type={!auxType?.maritalStatusExists ? "add" : "edit"}
          isOpen={dialogDemographic?.maritalStatus}
          handleClose={() =>
            setDialogDemographic((prev: any) => ({
              ...prev,
              maritalStatus: false,
            }))
          }
          display={viewText?.displayMaritalStatus}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}
      {addressDialogOpen && (
        <AddressDialog
          type={userPatient?.address ? "edit" : "add"}
          isOpen={addressDialogOpen}
          setIsOpen={setAddressDialogOpen}
        />
      )}
    </Stack>
  );
}
