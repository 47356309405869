import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { OAuthApplication } from "../../auth";

export async function GetPractitioner(id: string, authAplication?: boolean) {
  const url = `${endpoints.ADMFHIR}/Practitioner/${oids.cpf}-${id}`;

  const bearerToken = authAplication
    ? await OAuthApplication()
    : await useAuth.getUserToken();
  const headers = {
    Accept: "*/*",
    Authorization: bearerToken,
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetPractitioner: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetPractitioner ", error);
      // toast.error(`GetPractitioner Error: ${error?.response?.status}`);
      // throw new Error();
    });
  if (response) return response;
}
