import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetSignUser } from "../services/dsig/GetSignUser";
import { RevokeSignToken } from "../services/dsig/RevokeSignToken";
import { useSessionContext } from "./SessionContext";

interface UserSessionInfo {
  vidaasExpiresAt: string;
  safeidExpiresAt: string;
}

interface SignatureControlContextValue {
  sessionInfo: UserSessionInfo | null;
  loading: boolean;
  error: string | null;
  fetchSessionInfo: () => Promise<void>;
  handleResetSession: (provider: "vidaas" | "safeid") => Promise<void>;
  updateSessionAfterSignature: () => Promise<void>; // Nova função]
  setRefetch: any;
}

export const SignatureControlContext =
  createContext<SignatureControlContextValue>(null!);

export function SignatureControlContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [sessionInfo, setSessionInfo] = useState<UserSessionInfo | null>(null);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useSessionContext();

  const fetchSessionInfo = async () => {
    setLoading(true);
    try {
      if (!user?.username) {
        setSessionInfo(null);
        setError(null);
        return null;
      }

      const info = await GetSignUser({ cpf: user.username });

      setSessionInfo(info);
      setError(null);
      return info;
    } catch (err) {
      setError("Erro ao carregar informações da sessão");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleResetSession = async (provider: "vidaas" | "safeid") => {
    try {
      if (!user?.username) {
        setError("CPF do usuário não disponível");
        return;
      }
      await RevokeSignToken({
        cpf: user.username,
        provider: provider,
      });
    } catch (err) {
      setError(`Erro ao resetar a sessão do ${provider}`);
      console.error(err);
    } finally {
      await fetchSessionInfo();
    }
  };

  const updateSessionAfterSignature = async () => {
    await fetchSessionInfo();
  };

  useEffect(() => {
    fetchSessionInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.username, refetch]);

  const contextValue = {
    sessionInfo,
    loading,
    error,
    fetchSessionInfo,
    handleResetSession,
    updateSessionAfterSignature,
    setRefetch,
  };

  return (
    <SignatureControlContext.Provider value={contextValue}>
      {children}
    </SignatureControlContext.Provider>
  );
}

export function useSignatureControlContext() {
  return useContext(SignatureControlContext);
}
