import { useEffect, useState } from "react";
import { FormAutoComplete, FormTextInput } from "../../../../components/form";

import { Publish } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { GetValueSet } from "../../../../services/terminology";
import { CustomCID10Option } from "./CustomCID10Option";
import { CustomServiceOption } from "./CustomServiceOption";
import { SubmitItemButton } from "./SubmitItemButton";

interface IProps {
  action: any;
  methods: any;
  isEdit: boolean;
}

export function ServiceTab({ action, methods, isEdit }: IProps) {
  const { control, handleSubmit, reset, setValue } = methods;
  let selectedService = useWatch({ name: "service", control });
  const [searchCondition, setSearchCondition] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customService, setCustomService] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [renderField, setRenderField] = useState<boolean>(false);
  const [serviceOptions, setServiceOptions] = useState<any>();
  const [conditionOptions, setConditionOptions] = useState<any>();

  useEffect(() => {
    setRenderField(true);
  }, [renderField]);

  const serviceList =
    serviceOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: el?.display,
      value: el?.code,
    })) || [];

  const conditionList =
    conditionOptions?.expansion?.contains?.map((el: any, index: number) => ({
      ...el,
      label: el?.display,
      value: el?.code,
    })) || [];

  const categoryList = [
    {
      label: "Laboratorial",
      value: 1,
      code: "108252007",
      display: "Laboratory procedure",
    },
    { label: "Imagem", value: 2, code: "363679005", display: "Imaging" },
  ];
  const onSubmit = async (data: any) => {
    try {
      if (!data?.service) {
        toast.warn("Selecione um exame para realizar a solicitação");
        throw new Error("Campo vazio");
      } else {
        const auxItem = {
          type: "service",
          tabValue: "2",
          inputValue: serviceList.find(
            (e: any) => data?.service?.code === e?.code
          ) || {
            name: data?.service?.label,
            value: data?.service?.label,
            label: data?.service?.label,
          },
          name: data?.service?.label,
          tag: "EX",
          form: "",
          note: data?.note,
          condition: data?.condition,
          category: data?.category,
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      if (data?.note) setRenderField(false);
      reset();
    }
  };

  useEffect(() => {
    async function fetchServices() {
      const valueSetReponse = await GetValueSet({
        valueset: "FluxmedExames",
        version: "r5",
        filter: selectedService?.label,
      });
      setServiceOptions(valueSetReponse?.data);
      setLoading(false);
    }

    if (selectedService?.code) setCustomService(false);

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchServices();
    }, 500);
    return () => clearTimeout(debounce);
  }, [selectedService]);

  useEffect(() => {
    async function fetchConditions() {
      const valueSetReponse = await GetValueSet({
        valueset: "CID10",
        version: "r4",
        filter: searchCondition,
      });
      setConditionOptions(valueSetReponse?.data);
      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchConditions();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchCondition]);

  return (
    <Stack gap={1}>
      <FormAutoComplete
        loading={loading}
        name="service"
        label="Selecionar exame"
        required
        control={control}
        freeSolo
        onInputChange={(e: any) => {
          if (e?.target?.value?.length)
            setValue("service", {
              name: e?.target?.value,
              label: e?.target?.value,
              value: e?.target?.value,
              type: "service",
            });
        }}
        noOptionsText={
          <Button
            sx={{ width: "fit-content", textTransform: "none" }}
            onClick={() => setCustomService(true)}
            endIcon={<Publish />}
          >
            Adicionar novo exame
          </Button>
        }
        renderOption={(option: any) => <CustomServiceOption option={option} />}
        selectOptions={serviceList}
      />
      <Stack direction="row" gap={2}>
        <FormAutoComplete
          freeSolo
          loading={loading}
          name="condition"
          label="Selecionar condição"
          control={control}
          onInputChange={(e: any) => {
            if (e?.target?.value?.length) {
              setValue("condition", {
                display: e?.target.value,
                name: e?.target?.value,
                label: e?.target?.value,
                value: e?.target?.value,
              });
              setSearchCondition(e?.target?.value);
            }
          }}
          renderOption={(option: any) => <CustomCID10Option option={option} />}
          selectOptions={conditionList}
        />

        <FormAutoComplete
          loading={loading}
          name="category"
          label="Selecionar categoria"
          control={control}
          // renderOption={(option: any) => <CustomCID10Option option={option} />}
          selectOptions={categoryList}
        />
      </Stack>

      {renderField && (
        <Stack>
          <FormTextInput
            multiline
            rows={3}
            label="Observação"
            name="note"
            control={control}
          />
        </Stack>
      )}

      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </Stack>
  );
}
