import { Delete } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { InputDate, InputText } from "../Form";
import { RenderResultsList } from "./RenderResultsList";

interface GruposExames {
  [key: string]: any[];
}

export function RenderResultadoDeExame({
  control,
  setValue,
  item,
  unregister,
}: any) {
  const [inputs, setInputs] = useState<any>([]); // Estado para armazenar os inputs dinâmicos
  const respostas = item?.item;

  const [dateRef, setDateRef] = useState<Dayjs>();

  function saveDefaultValues() {
    respostas?.map(async (el: any, index: number) => {
      await setValue(
        `801d90b0-0fd1-410f-89e5-d26087895039_past_${index}`,
        el?.item?.[0]?.answer?.[0]?.valueString
      );
      await setValue(
        `4a58a481-b19a-41a5-9865-d3e0821b1005_past_${index}`,
        el?.item?.[1]?.answer?.[0]?.valueString
      );
      await setValue(
        `7296a61f-f41c-4325-9518-be203663594a_past_${index}`,
        el?.item?.[2]?.answer?.[0]?.valueDate
      );
      return el;
    });
  }

  function agruparPorNomeExame(exames: any) {
    const grupos: GruposExames = {};

    exames?.forEach((exame: any) => {
      const nomeExameItem = exame?.item?.find(
        (item: any) => item?.text === "Nome do exame"
      );
      if (nomeExameItem) {
        const nomeExame = nomeExameItem?.answer?.[0]?.valueString;
        if (nomeExame === undefined) return;
        if (!grupos[nomeExame]) {
          grupos[nomeExame] = [];
        }
        grupos[nomeExame].push(exame);
      }
    });

    return grupos;
  }

  const resultados = agruparPorNomeExame(respostas);

  const handleAddInput = () => {
    // Adiciona um novo input ao estado do array
    const newInput = {
      nameId: `801d90b0-0fd1-410f-89e5-d26087895039_${inputs.length}`,
      valueId: `4a58a481-b19a-41a5-9865-d3e0821b1005_${inputs.length}`,
      dateId: `7296a61f-f41c-4325-9518-be203663594a_${inputs.length}`,
    };
    setInputs([...inputs, newInput]);
  };

  useEffect(() => {
    saveDefaultValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack sx={{ marginBottom: 2 }}>
      <Accordion
        sx={{
          border: "1px solid #ccc",
          boxShadow: "2px 2px 16px 2px rgba(0, 0, 0, 0.2)",
          position: "initial",
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant={"h6"}>Resultado de exame</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack>
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack>
                Data de referencia
                <InputDate
                  key={"Results"}
                  label="Data de referencia"
                  handleChange={(e: any) => setDateRef(e.$d)}
                  name="Data de Referencia"
                  defaultValue={dayjs()}
                  control={control}
                />
              </Stack>
              <Button
                sx={{ width: "250px" }}
                onClick={handleAddInput}
                variant="outlined"
              >
                Novo exame
              </Button>
            </Stack>

            {/*Fazer função para remover item */}
            {inputs?.map((input: any, index: any) => (
              <Stack
                direction="row"
                spacing={1}
                padding={1}
                alignItems="center"
              >
                <InputText
                  key={input?.nameId}
                  handleChange={(e: any) =>
                    setValue(input?.nameId, e.target.value)
                  }
                  label="Nome do exame"
                  name={input.nameId}
                  control={control}
                  sx={{ mb: 4, width: "300px" }}
                />
                <InputText
                  key={input?.valueId}
                  handleChange={(e: any) =>
                    setValue(input?.valueId, e.target.value)
                  }
                  label="Valor do exame"
                  name={input.valueId}
                  control={control}
                  sx={{ mb: 4, width: "300px" }}
                />

                <InputDate
                  key={input?.dateId}
                  label="Data de realização"
                  name={input?.dateId}
                  defaultValue={dayjs(dateRef)}
                  control={control}
                />
                <IconButton
                  onClick={() => {
                    setInputs(
                      inputs?.filter((e: any, i: number) => index !== i)
                    );
                    unregister(input.nameId);
                    unregister(input.valueId);
                    unregister(input?.dateId);
                  }}
                >
                  <Delete sx={{ color: "error600.main" }} />
                </IconButton>
              </Stack>
            ))}

            <Stack>
              {Object.keys(resultados)?.map((el: any) => (
                <>
                  <Divider />
                  <RenderResultsList
                    dateRef={dateRef}
                    unregister={unregister}
                    values={resultados?.[el]}
                    name={el}
                    control={control}
                    setValue={setValue}
                  />
                </>
              ))}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
