import { Box } from "@mui/material";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useEffect, useRef, useState } from "react";

interface JitsiComponentProps {
  roomName: string;
  displayName: string;
  email: string;
  screenSize: number;
  onClose: () => void;
}

const JitsiComponent: React.FC<JitsiComponentProps> = ({
  roomName,
  displayName,
  email,
  screenSize,
  onClose,
}) => {
  const YOUR_DOMAIN = "teleatendimento.fluxmed.com.br";
  const jitsiRef = useRef<any>(null);
  const [iframeReady, setIframeReady] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (jitsiRef.current && iframeReady && containerRef.current) {
      const width = 400 * screenSize;
      const height = 300 * screenSize;

      containerRef.current.style.width = `${width}px`;
      containerRef.current.style.height = `${height}px`;
      jitsiRef.current.style.width = "100%";
      jitsiRef.current.style.height = "100%";
    }
  }, [screenSize, iframeReady]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        "& iframe": {
          border: "none",
          width: "100%",
          height: "100%",
        },
        "& .new-toolbox": {
          position: "absolute !important",
          bottom: "0 !important",
          left: "0 !important",
          right: "0 !important",
          width: "100% !important",
          minHeight: "40px !important",
          background: "rgba(0,0,0,0.5) !important",
          zIndex: 9999,
        },
        "& .toolbox-content": {
          display: "flex !important",
          justifyContent: "center !important",
          gap: "8px !important",
          padding: "8px !important",
        }
      }}
    >
      <JitsiMeeting
        domain={YOUR_DOMAIN}
        roomName={roomName}
        onReadyToClose={onClose}
        configOverwrite={{
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          disableModeratorIndicator: true,
          enableEmailInStats: false,
          disableProfile: true,
          prejoinPageEnabled: false,
          toolbarConfig: {
            alwaysVisible: true,
            initiallyVisible: true,
            timeout: -1,
            displayMode: 'always',
          },
          notifications: [],
          toolbarButtons: [
            'microphone',
            'camera',
            'hangup',
            'desktop',
            'chat',
            'raisehand',
            'videoquality',
          ],
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_BRAND_WATERMARK: false,
          BRAND_WATERMARK_LINK: "",
          MOBILE_APP_PROMO: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          TOOLBAR_ALWAYS_VISIBLE: true,
          TOOLBAR_TIMEOUT: -1,
          DEFAULT_BACKGROUND: "#000000",
          DEFAULT_REMOTE_DISPLAY_NAME: "Participante",
          DEFAULT_LOCAL_DISPLAY_NAME: "Você",
          TOOLBAR_BUTTONS: [
            'microphone',
            'camera',
            'hangup',
            'desktop',
            'chat',
            'raisehand',
            'videoquality',
          ],
          SETTINGS_SECTIONS: ['devices', 'language', 'moderator'],
          RECENT_LIST_ENABLED: false,
          ENABLE_FEEDBACK_ANIMATION: false,
          VERTICAL_FILMSTRIP: false,
          CLOSE_PAGE_GUEST_HINT: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          FILM_STRIP_MAX_HEIGHT: 120,
          INITIAL_TOOLBAR_TIMEOUT: 20000,
          TOOLBAR_POSITION: 'bottom',
        }}
        userInfo={{
          displayName: displayName,
          email: email,
        }}
        getIFrameRef={(iframeRef) => {
          jitsiRef.current = iframeRef;
          setIframeReady(true);
        }}
      />
    </Box>
  );
};

export default JitsiComponent;
