import { Avatar, Box, Modal, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButtonGroup } from "../../../components/form";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useFormatter } from "../../../utils/useFormatter";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75vh",
  bgcolor: "background.paper",
  background: "linear-gradient(#009E8C 20%, #ffffff 38%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export function ModalFirstAccess({ handleClose, open }: any) {
  const { user, loading, setFirstAccessDialog } = useSessionContext();

  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          height="90%"
        >
          <Stack alignItems="center" spacing={3}>
            <Avatar
              sx={{
                width: 90,
                height: 90,
                backgroundColor: "primary100.main",
                color: "primary900.main",
              }}
            >
              <Typography variant="h3" fontWeight={600}>
                {user?.name?.slice(0, 1)}
              </Typography>
            </Avatar>

            <Typography variant="h5" fontWeight={600}>
              Bem vindo ao Fluxmed,{" "}
              {useFormatter.formatNameDisplay(user?.givenName)}!
            </Typography>
          </Stack>
          <Stack alignItems="center" spacing={3} width="80%">
            <Typography
              variant="body1"
              fontSize={20}
              textAlign="center"
              fontWeight={500}
            >
              Para iniciar suas atividades no sistema, cadastre suas informações
              profissionais ou convide um médico para sua equipe. Esse passo é
              essencial para garantir o acesso completo às funcionalidades da
              plataforma.
            </Typography>
          </Stack>
          <Stack width="80%" direction="row" alignItems="center" spacing={3}>
            <FormButtonGroup
              onGoBackButtonClick={() => {
                setFirstAccessDialog(false);
                navigate("/professional-register");
              }}
              onNextButtonClick={() => {
                setFirstAccessDialog(false);
                navigate("/practitioners");
              }}
              goBackButtonText="Cadastrar informações"
              nextButtonText="Convidar"
              loading={loading}
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
