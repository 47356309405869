import React from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogTitle, Stack } from "@mui/material";
import { FormButtonGroup } from "../../../../components/form";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import { toast } from "react-toastify";

interface IProps {
  type: "add" | "edit";
  isOpen: boolean;
  handleClose: VoidFunction;
  display: any;
  control: any;
  handleSubmit: any;
}
export function EducationDialog({
  type,
  isOpen,
  handleClose,
  display,
  control,
  handleSubmit,
}: IProps) {
  const { userPatient, fetchUser } = useSessionContext();

  const codesEducation = [
    {
      value: "Elementary School",
      display: "Ensino Fundamental Completo",
      code: "ELEM",
    },
    {
      value: "Some secondary or high school education",
      display: "Ensino Médio Incompleto",
      code: "SEC",
    },
    {
      value: "High School or secondary school degree complete",
      display: "Ensino Médio Completo",
      code: "HS",
    },
    {
      value: "Some College education",
      display: "Ensino Superior Incompleto",
      code: "SCOL",
    },
    {
      value: "Associate's or technical degree complete",
      display: "Curso Técnico ou Tecnológico Completo",
      code: "ASSOC",
    },
    {
      value: "College or baccalaureate degree complete",
      display: "Ensino Superior Completo",
      code: "BD",
    },
    {
      value: "Some post-baccalaureate education",
      display: "Pós-Graduação Incompleta",
      code: "PB",
    },
    {
      value: "Graduate or professional Degree complete",
      display: "Pós-Graduação Completa",
      code: "GD",
    },
    {
      value: "Doctoral or post graduate education",
      display: "Doutorado ou Pós-Doutorado Completo",
      code: "POSTG",
    },
  ];

  const auxDefault = codesEducation?.find((el: any) => el.display === display);

  function verifyPath(extensionEducation: any) {
    if (extensionEducation === undefined) {
      return "/extension";
    }
    if (extensionEducation === -1) {
      return "/extension/-";
    } else {
      return `/extension/${extensionEducation}`;
    }
  }

  const auxFindExtensionEducation = userPatient?.extension?.findIndex(
    (el: any) =>
      el.url === "http://terminology.hl7.org/ValueSet/v3-EducationLevel"
  );

  const onSubmit = async (data: any) => {
    const auxDisplay = codesEducation?.find(
      (el: any) => el.value === data.education?.value
    )?.display;

    const path = verifyPath(auxFindExtensionEducation);
    const content = {
      url: "http://terminology.hl7.org/ValueSet/v3-EducationLevel",
      valueCodeableConcept: {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v3-EducationLevel",
            code: data?.education?.code,
            display: auxDisplay,
          },
        ],
      },
    };

    try {
      await PatchPatient({
        patientId: userPatient?.id,
        operation: type === "add" ? "add" : "replace",
        path,
        content: auxFindExtensionEducation === undefined ? [content] : content,
      });
      await fetchUser();
      toast.success(
        `Escolaridade ${
          type === "add" ? "adicionada" : "atualizada"
        } com sucesso!`
      );
      handleClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <DialogTitle>
        {type === "add" ? "Adicionar" : "Atualizar"} Escolaridade
        <Stack marginY={2}>
          <FormSelectCompleted
            defaultValue={auxDefault}
            name="education"
            label=""
            control={control}
            selectOptions={codesEducation}
            required
          />
        </Stack>
        <FormButtonGroup
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
          onGoBackButtonClick={handleClose}
          onNextButtonClick={handleSubmit(onSubmit)}
        />
      </DialogTitle>
    </CommonDialog>
  );
}
