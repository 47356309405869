import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FitContentBox } from "../../../components/common";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { GetAppointment, PostMedicationRequest } from "../../../services/fhir";
import { PostDocumentReference } from "../../../services/fhir/patientsummary/documentreference/PostDocumentReference";
import { PostServiceRequest } from "../../../services/fhir/patientsummary/serviceRequest/PostServiceRequest";
import { useFhirData } from "../../../utils/useFhirData";
import { ButtonSendPrescription } from "./ButtonSendPrescription";
import { DocumentVisualization } from "./DocumentVisualization";

import { useNavigate } from "react-router-dom";
import { oids } from "../../../configs/Settings";
import { SignAndDownloadDocument } from "../SignAndDownloadDocument";
import { MedicalPrescription } from "./MedicalPrescription";
import { CreateProtocolDialog } from "./prescription/CreateProtocolDialog";
import {
  Pages,
  dictStepsPrescription,
  labelsStepperPrescription,
} from "./prescription/PrescriptionAuxiliarTranslation";

export function PrescriptionContent({ references, handleClose }: any) {
  const { user } = useSessionContext();
  const { userPractitioner, userPractitionerRole, userSpecialties } =
    usePractitionerRoleContext();
  const { organization } = useOrganizationContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [patient, setPatient] = useState<fhir5.Patient>();
  const [appointment, setAppointment] = useState<fhir5.Appointment>();

  const [prescriptionItens, setPrescriptionItens] = useState<Array<any>>([]);
  const [base64, setbase64] = useState<any>();
  const [dataPdf, setDataPdf] = useState<any>([]);

  const [speciaMedicationID, setSpeciaMedicationID] = useState<any>([]);
  const [medicationSimpleID, setMedicationSimpleId] = useState<string>("");
  const [serviceID, setServiceID] = useState<string>("");
  const [procedureID, setProcedureID] = useState<string>("");

  const [currentStep, setCurrentStep] = useState(Pages.S1Prescription);

  const medicationSpecial = prescriptionItens?.filter(
    (el: any) =>
      el?.tag?.toUpperCase()?.includes("VERMELHA") ||
      el?.tag?.toUpperCase()?.includes("PRETA")
  );
  const medicationSimple = prescriptionItens?.filter(
    (el: any) =>
      (el?.type === "medication" ||
        el?.type === "supplement" ||
        el?.type === "herbalMedicine") &&
      !(
        el?.tag?.toUpperCase()?.includes("VERMELHA") ||
        el?.tag?.toUpperCase()?.includes("PRETA")
      )
  );

  const service = prescriptionItens?.filter(
    (el: any) => el?.type === "service"
  );

  const procedure = prescriptionItens?.filter(
    (el: any) => el?.type === "procedure"
  );
  const certificates = prescriptionItens?.filter(
    (el: any) => el?.type === "certificate"
  );
  const healthcare = useFhirData?.findParticipant(
    appointment?.participant,
    "HealthcareService"
  )?.display;
  const servicePratice = userSpecialties?.find(
    (el: any) => el.coding?.[0]?.display === healthcare
  );
  const crm = userPractitionerRole?.identifier
    ?.find((id: any) => id.system === "crm")
    ?.value?.split("/")?.[1];

  const crmValue = crm?.slice(2);
  const crmUf = crm?.slice(0, 2);
  const docReferenceData = {
    organization: {
      id: organization?.id,
      alias: organization?.alias?.[0],
      name: organization?.name,
    },
    practitioner: {
      id: userPractitioner?.id,
      name: userPractitioner?.name?.[0]?.text,
    },
    servicePratice: {
      display: servicePratice?.coding?.[0]?.display,
      code: servicePratice?.coding?.[0]?.code,
      system: "https://fluxmed.com.br/fhir/CodeSystem/CFMEspecialidadeMedica",
    },
    time: {
      start: appointment?.start,
      end: appointment?.end,
    },
  };

  const nextStep = () => {
    if (!prescriptionItens.length) {
      toast.warn("Nenhum item foi selecionado.");
      setLoading(false);
      return;
    }
    if (currentStep === Pages.S2Document) {
      handlePrescription();
    }
    if (currentStep === Pages.S3SignDocument) {
      try {
        handleSignDocument();
        handleClose && handleClose();
        setCurrentStep(Pages.S1Prescription - 1);
        toast.success("Documentos registrados com sucesso!");
        setPrescriptionItens([]);
      } catch (err) {
        toast.error("Não foi possível criar os documentos");
      }
    }
    setCurrentStep((prev: number) => prev + 1);
  };

  const handlePrescription = async () => {
    setLoading(true);
    const contained = [
      {
        resourceType: "Practitioner",
        id: "practitioner-1",
        identifier: [
          {
            ...(crmUf &&
              crmValue && {
                extension: [
                  {
                    url: "http://ehrrunner.com/fhir/StructureDefinition/BRJurisdicaoOrgaoEmissor-1.0",
                    valueCodeableConcept: {
                      coding: [
                        {
                          system:
                            "http://ehrrunner.com/fhir/CodeSystem/BRUnidadeFederacao-1.0",
                          code: crmUf,
                        },
                      ],
                    },
                  },
                ],
              }),

            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "MD",
                },
              ],
            },
            value: crmValue,
            assigner: {
              display: "CONSELHO REGIONAL DE MEDICINA (CRM)",
            },
          },
          {
            type: {
              coding: [
                {
                  system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                  code: "TAX",
                },
              ],
            },
            value: user?.username,
          },
        ],
        name: [
          {
            text: user?.name,
          },
        ],
      },
    ];

    if (references)
      try {
        if (medicationSimple?.length) {
          const simpleResponse = await PostMedicationRequest(
            medicationSimple,
            references
          );
          const auxSimpleBasedOn = simpleResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
              <fhir:reference value="${
                el.response?.location?.split("/_history")?.[0]
              }"/>
              </fhir:basedOn>
              `
            )
            ?.join("");
          setMedicationSimpleId(auxSimpleBasedOn);
        }

        if (medicationSpecial?.length) {
          medicationSpecial?.map(async (special: any, index: number) => {
            const specialResponse = await PostMedicationRequest(
              [special],
              references
            );

            return setSpeciaMedicationID((prev: any) => [
              ...prev,
              {
                id: specialResponse?.entry
                  ?.map(
                    (el: any) =>
                      `<fhir:basedOn>
                <fhir:reference value="${
                  el.response?.location?.split("/_history")?.[0]
                }"/>
                </fhir:basedOn>
                `
                  )
                  ?.join(""),
                medication: special,
              },
            ]);
          });
        }

        if (service?.length) {
          const serviceResponse = await PostServiceRequest(
            service,
            references,
            contained
          );

          const auxServiceBasedOn = serviceResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");
          setServiceID(auxServiceBasedOn);
        }

        if (procedure?.length) {
          const procedureResponse = await PostServiceRequest(
            procedure,
            references,
            contained
          );

          const auxProcedureBasedOn = procedureResponse?.entry
            ?.map(
              (el: any) =>
                `<fhir:basedOn>
            <fhir:reference value="${
              el.response?.location?.split("/_history")?.[0]
            }"/>
            </fhir:basedOn>
            `
            )
            ?.join("");
          setProcedureID(auxProcedureBasedOn);
        }
        toast.success("Prescrição realizada com sucesso!");
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
  };

  const handleSignDocument = async () => {
    const simpleBase64 = base64
      ?.find((el: any) => el["simple"])
      ?.["simple"]?.base64?.split(",")?.[1];

    const serviceBase64 = base64
      ?.find((el: any) => el["exam"])
      ?.["exam"]?.base64?.split(",")?.[1];

    const procedureBase64 = base64
      ?.find((el: any) => el["procedure"])
      ?.["procedure"]?.base64?.split(",")?.[1];

    const filterSpecial = base64?.filter((el: any, index: number) => {
      const labelTypeFilter = Object.keys(el)?.[0];
      return el?.[`${labelTypeFilter}`]?.type?.includes("special");
    });

    if (simpleBase64)
      await PostDocumentReference(
        {
          ...docReferenceData,
          base64: simpleBase64,
          codeValue: "REC_SIM",
          codeDisplay: "Receituário Simples",
          categoryValue: "prescricao",
          categoryDisplay: "Receituário",
        },
        references,
        medicationSimpleID
      );

    if (serviceBase64) {
      await PostDocumentReference(
        {
          ...docReferenceData,
          base64: serviceBase64,
          codeValue: "SERVICE_REQUEST",
          codeDisplay: "Solicitação de exame",
          categoryValue: "service-request",
          categoryDisplay: "Solicitação",
        },
        references,
        serviceID
      );
    }

    if (procedureBase64) {
      await PostDocumentReference(
        {
          ...docReferenceData,
          base64: procedureBase64,
          codeValue: "SERVICE_REQUEST",
          codeDisplay: "Procedimento",
          categoryValue: "service-request",
          categoryDisplay: "Procedimento",
        },
        references,
        procedureID
      );
    }

    if (filterSpecial?.length) {
      filterSpecial?.map(async (el: any) => {
        const labelFilterSpecial = Object.keys(el)?.[0];
        const specialBase64 =
          el?.[`${labelFilterSpecial}`]?.base64?.split(",")?.[1];
        const findId = speciaMedicationID?.find(
          (element: any) =>
            el?.[`${Object.keys(el)?.[0]}`]?.info?.[0]?.label ===
            element?.medication?.label
        )?.id;
        return await PostDocumentReference(
          {
            ...docReferenceData,
            base64: specialBase64,
            codeValue: "REC_ESP",
            codeDisplay: "Receituário Especial",
            categoryValue: "prescricao",
            categoryDisplay: "Receituário",
          },
          references,
          findId
        );
      });
    }

    if (certificates?.length) {
      certificates.map(async (certificates: any, index: number) => {
        const certificateBase64 = base64
          ?.find((el: any) => el[`certificate-${index}`])
          ?.[`certificate-${index}`]?.base64?.split(",")?.[1];

        return await PostDocumentReference(
          {
            ...docReferenceData,
            base64: certificateBase64,
            codeValue: "AT",
            codeDisplay: "Atestado",
            categoryValue: "atestado",
            categoryDisplay: "Atestado Médico",
          },
          references,
          null
        );
      });
    }
  };
  useEffect(() => {
    async function getData() {
      const response = await GetAppointment({
        _id: references?.appointmentId,
        includePatient: true,
        actor: `Practitioner/${oids.cpf}-${user.username}`,
      });

      const auxPatient = response.entry.find(
        (el: any) => el.resource.resourceType === "Patient"
      );
      setPatient(auxPatient?.resource);

      const auxAppointment = response.entry.find(
        (el: any) => el.resource.resourceType === "Appointment"
      );
      setAppointment(auxAppointment?.resource);
    }

    if (references?.appointmentId) {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [references?.appointmentId]);

  const [openCreateProtocol, setOpenCreateProtocol] = useState<boolean>(false);

  const onClickSaveProtocol = () => {
    if (prescriptionItens.length) {
      setOpenCreateProtocol(true);
    }
  };

  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      <FitContentBox>
        <Stepper
          activeStep={currentStep}
          sx={{ width: "90%", padding: "12px", marginBottom: 2 }}
        >
          {labelsStepperPrescription.map((key, value) => (
            <Step key={value}>
              <StepLabel>{key}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {currentStep === Pages.S1Prescription && (
          <MedicalPrescription
            patient={patient}
            prescriptionItens={prescriptionItens}
            setPrescriptionItens={setPrescriptionItens}
            references={references}
            appointment={appointment}
            setDataPdf={setDataPdf}
          />
        )}

        {currentStep === Pages.S2Document && (
          <DocumentVisualization
            // base64={base64}
            setBase64={setbase64}
            dataPdf={dataPdf}
            // dataPrescription={prescriptionItens}
          />
        )}

        {currentStep === Pages.S3SignDocument && (
          <SignAndDownloadDocument base64={base64} />
        )}

        <ButtonSendPrescription
          onClick={nextStep}
          onClickSaveProtocol={onClickSaveProtocol}
          backClick={() =>
            currentStep === Pages.S1Prescription
              ? handleClose
                ? handleClose()
                : navigate(-1)
              : setCurrentStep((prev: any) => prev - 1)
          }
          textCancel={
            currentStep === Pages.S1Prescription ? "Fechar" : "Voltar"
          }
          textProtocol={"Salvar protocolo"}
          loading={loading}
          visible={currentStep === Pages.S1Prescription}
          notVisible={currentStep === Pages.S3SignDocument}
          text={dictStepsPrescription[currentStep]}
        />
      </FitContentBox>
      {openCreateProtocol && (
        <CreateProtocolDialog
          itens={prescriptionItens}
          isOpen={openCreateProtocol}
          handleClose={() => setOpenCreateProtocol(false)}
        />
      )}
    </Stack>
  );
}
