import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { IAppointmentData } from "../../../../utils/interfaces";
import { Steps } from "../../types";
import { ConfirmAppointment } from "./ConfirmAppointment";
import { ConfirmRelationship } from "./ConfirmRelationship";
import { CreatePatient } from "./CreatePatient";
import { SelectAppointmentActors } from "./SelectAppointmentActors";
import { SelectAppointmentPatient } from "./SelectAppointmentPatient";

interface IProps {
  setModalOpen: Function;
  isOpen: boolean;
  row?: any;
  walkin?: boolean;
  setRefetchDashboard?: Dispatch<SetStateAction<boolean>>;
  cpfT: any;
}

export const stepTitles: { [key in Steps]: string } = {
  [Steps.S1SelectPatient]: "Selecionar Paciente",
  [Steps.S2CreateResponsible]: "Criar Responsável",
  [Steps.S3CreatePatient]: "Criar Paciente",
  [Steps.S4ConfirmRelationship]: "Confirmação do responsável",
  [Steps.S5SelectAppointmentActors]: "Selecione o profissional",
  [Steps.S6ConfirmAppointment]: "Confirmar agendamento",
};

export function CreateAppointmentDialog({
  setModalOpen,
  isOpen,
  row,
  walkin,
  setRefetchDashboard,
  cpfT,
}: IProps) {
  const defaultValues = {
    cpf: cpfT,
    typeService: undefined,
    professionalOptions: undefined,
    cpfResponsible: undefined,
    gender: undefined,
    schedule: undefined,
  };

  const [page, setPage] = useState(Steps.S1SelectPatient);
  const [dataResponsible, setDataResponsible] = useState<fhir5.Patient>();

  const [scheduleActors, setScheduleActors] = useState<any>(
    row?.scheduleActors
  );

  const methods = useForm<IAppointmentData>({ defaultValues });
  const { handleSubmit, control, setValue, reset, watch } = methods;

  let cpfValue = useWatch({ name: "cpf", control });
  let cpfResponsible = useWatch({ name: "cpfResponsible", control });

  const watchAllFields = watch();

  return (
    <CommonDialog handleClose={() => setModalOpen(false)} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        <Typography variant="h5" fontWeight={600}>
          {page === Steps.S1SelectPatient
            ? walkin
              ? "Encaixe"
              : "Realizar atendimento"
            : stepTitles[page]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {page === Steps.S1SelectPatient && (
          <SelectAppointmentPatient
            control={control}
            walkin={walkin}
            cpfT={cpfT}
            setModalOpen={setModalOpen}
            setPage={setPage}
            handleSubmit={handleSubmit}
          />
        )}

        {page === Steps.S2CreateResponsible && (
          <CreatePatient
            type="responsible"
            cpf={cpfResponsible}
            control={control}
            handleSubmit={handleSubmit}
            navigateToNextPage={() => setPage(Steps.S3CreatePatient)}
            setValue={setValue}
            setPage={setPage}
            row={row}
            walkin={walkin}
            reset={reset}
            setDataResponsible={setDataResponsible}
          />
        )}

        {page === Steps.S3CreatePatient && (
          <CreatePatient
            type="patient"
            cpf={cpfValue}
            control={control}
            handleSubmit={handleSubmit}
            navigateToNextPage={() => setPage(Steps.S5SelectAppointmentActors)}
            setValue={setValue}
            setPage={setPage}
            row={row}
            walkin={walkin}
            reset={reset}
            dataResponsible={dataResponsible}
            watchAllFields={watchAllFields}
          />
        )}

        {page === Steps.S4ConfirmRelationship && (
          <ConfirmRelationship
            control={control}
            handleSubmit={handleSubmit}
            navigateToNextPage={() =>
              setPage(
                walkin
                  ? Steps.S5SelectAppointmentActors
                  : Steps.S6ConfirmAppointment
              )
            }
            onBackButtonCLick={() => {
              reset();
              setPage(Steps.S1SelectPatient);
            }}
            dataResponsible={dataResponsible}
          />
        )}

        {page === Steps.S5SelectAppointmentActors && (
          <SelectAppointmentActors
            setScheduleActors={setScheduleActors}
            control={control}
            handleSubmit={handleSubmit}
            setModalOpen={setModalOpen}
            setValue={setValue}
            navigateToNextPage={() => setPage(Steps.S6ConfirmAppointment)}
          />
        )}
        {page === Steps.S6ConfirmAppointment && (
          <ConfirmAppointment
            row={row}
            setValue={setValue}
            scheduleActors={scheduleActors}
            control={control}
            walkin={walkin}
            setModalOpen={setModalOpen}
            setTabRefetch={row?.setTabRefetch || setRefetchDashboard}
            handleSubmit={handleSubmit}
            onBackButtonClick={() =>
              setPage(
                walkin ? Steps.S5SelectAppointmentActors : Steps.S1SelectPatient
              )
            }
          />
        )}
      </DialogContent>
    </CommonDialog>
  );
}
