import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { FormAutoComplete, FormTextInput } from "../../../../components/form";
import { CustomMedicationOption } from "./CustomMedicationOption";
import { SubmitItemButton } from "./SubmitItemButton";

interface IProps {
  methods: any;
  isEdit: boolean;
  action: Function;
}

export function ProcedureTab({ methods, action, isEdit }: IProps) {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const { control, reset, handleSubmit, setValue } = methods;

  let proc = useWatch({ name: "procedure", control });

  const onSubmit = async (data: any) => {
    try {
      if (!data.procedure) {
        toast.warn("Selecione algum item pra efetuar a prescrição");
        throw new Error("Formulário vazio");
      } else {
        const auxForm = [
          data?.quantity?.label && `${data?.quantity?.label}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.procedure?.name,
          tag: data?.procedure?.tag,
          inputValue: data?.procedure,
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "procedure",
          tabValue: "1",
        };
        action(auxItem);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsDescriptionOpen(false);
      reset();
    }
  };

  useEffect(() => {
    if (proc) setIsDescriptionOpen(true);
  }, [proc]);

  return (
    <Stack gap={1}>
      <FormAutoComplete
        required
        freeSolo
        name="procedure"
        onInputChange={(e: any) => {
          if (e?.target?.value?.length)
            setValue("procedure", {
              name: e?.target?.value,
              label: e?.target?.value,
              value: e?.target?.value,
            });
        }}
        control={control}
        label="Selecione o procedimento"
        renderOption={(option: any) => (
          <CustomMedicationOption option={option} />
        )}
        selectOptions={[]}
      />

      {isDescriptionOpen && (
        <Stack>
          <FormTextInput
            multiline
            rows={3}
            label="Observação"
            name="note"
            control={control}
          />
        </Stack>
      )}

      <SubmitItemButton isEdit={isEdit} action={handleSubmit(onSubmit)} />
    </Stack>
  );
}
