/**
 * @description Página de login do sistema Fluxmed
 * @module SignIn
 * @since Fevereiro 2024
 *
 * Fluxo de autenticação:
 * 1. Recebe credenciais (identificador e senha)
 * 2. Valida o tipo de credencial (email, telefone ou usuário)
 * 3. Formata as credenciais conforme necessário
 * 4. Realiza autenticação via OAuth
 * 5. Armazena token e informações no localStorage
 * 6. Redireciona para a página inicial
 */

import { Box, Link, Stack } from "@mui/material";
import base64 from "base-64";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OAuthUser } from "../../services/auth";

import { CircularLoading } from "../../components/common";
import { useValidate } from "../../utils/useValidate";
import { Form } from "./components/Form";

import SignInLogo from "../../assets/fluxmed-signin.svg";
import PoweredByFluxmed from "../../assets/poweredFluxmed.png";
import { endpoints } from "../../configs/Settings";

export function SignIn() {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { signinCredentialType } = useValidate;

  /**
   * @description Verifica se uma string é um código base64 válido
   * @param {string} baseCode - String a ser verificada
   * @returns {boolean} true se for base64 válido, false caso contrário
   */
  function verificarBase64(baseCode: string) {
    try {
      window.atob(baseCode);
      return true;
    } catch (error) {
      return false;
    }
  }

  // Extrai e decodifica parâmetros da URL (usado para login via app mobile)
  const url = window.location.href.split("params=")[1];
  const urlInformation = isMobile ? url?.split("&")[0] : url;
  const decodedParams = verificarBase64(urlInformation)
    ? base64.decode(urlInformation)
    : undefined;
  const identifier = decodedParams?.split(":")[0] || "";
  const password = decodedParams?.split(":")[1] || "";

  const tokenMobile = url?.split("&tokenMobile=")[1];

  /**
   * @description Realiza o processo de login no sistema
   * @returns {Promise<void>}
   * @throws {Error} Erro de autenticação
   */
  const loginApp = async () => {
    // Determina o tipo de credencial (email, telefone, usuário)
    const auxValue = signinCredentialType(identifier);
    const auxType =
      auxValue.includes("userName") || auxValue.includes("phoneNumbers.mobile");

    let credential;

    // Formata a credencial conforme o tipo
    if (auxType) {
      credential = identifier.replace(/[^a-zA-Z0-9]/g, "");
    } else {
      credential = identifier.replace("@", "[AT]");
    }

    setLoading(true);
    try {
      // Realiza autenticação via OAuth
      const response = await OAuthUser(credential, password);
      if (response) {
        // Armazena informações de autenticação
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("logged", "true");
        localStorage.setItem("username", credential);
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("device_id", tokenMobile);
        const expirationTime = (
          Date.now() +
          response?.expires_in * 1000
        ).toString();
        localStorage.setItem("expirationTime", expirationTime);
        navigate("/");
      } else throw new Error("Sign in error");
    } catch (err) {
      // Notifica app mobile sobre falha no login
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("Login Failed");
      }
      toast.error("Erro, verifique suas credenciais!");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  let isForRender = 0;

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isFirefox = userAgent.includes("Firefox");
    const isEdge = userAgent.includes("Edg");

    if (
      !isFirefox &&
      !isEdge &&
      isForRender === 0 &&
      !window.location.hostname.includes("local")
    ) {
      window.alert(
        "Para uma melhor experiência das funcionalidades deste software, utilize preferencialmente os navegadores Firefox, Chrome ou Microsoft Edge"
      );
    }
    const isDev = window.location.hostname.includes("dev");
    const prodUrl = "https://app.fluxmed.com.br/portal/#/signin";

    if (isDev && isForRender === 0) {
      const goToProd = window.confirm(
        "Você está na versão de desenvolvimento. Deseja ir para a versão de produção?"
      );
      if (goToProd) {
        window.location.href = prodUrl; // Redirect to production URL
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isForRender = isForRender + 1;

    if (identifier && password) {
      loginApp();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Stack width="100%" direction="row">
      {loading ? (
        <Stack
          width="100%"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <CircularLoading />
        </Stack>
      ) : (
        <>
          {isMobile ? (
            <Stack width="100%" padding="60px 20px">
              <Form />
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" height="100vh" flex={1}>
              <Box
                component="img"
                src={SignInLogo}
                alt={`Logo ${endpoints.AppName}`}
                sx={{ width: "40%", objectFit: "cover", height: "100vh" }}
                display={{ xs: "none", lg: "flex" }}
              />
              {import.meta.env.VITE_PROJ !== "Fluxmed" && (
                <Link
                  display={{ xs: "none", lg: "flex" }}
                  href={endpoints.XDSB_domain}
                  sx={{
                    position: "absolute",
                    left: "12.5%",
                    bottom: 50,
                  }}
                >
                  <Box
                    component="img"
                    src={PoweredByFluxmed}
                    alt={`Logo powered by fluxmed`}
                    sx={{ width: "280px", height: "auto" }}
                  />
                </Link>
              )}
              <Form />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
