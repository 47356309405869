/**
 * @description Utilitário de validação de credenciais do Fluxmed
 * @module useValidate
 * @since Fevereiro 2024
 * 
 * Funções de validação:
 * - Tipo de credencial (CPF, telefone, email)
 * - Validação de CPF
 * - Validação de DDD
 */

/**
 * @description Determina o tipo de credencial baseado no formato do valor
 * @param {string} value - Valor da credencial a ser validada
 * @returns {string} Tipo da credencial (userName, phoneNumbers.mobile, ou email)
 */
function signinCredentialType(value: string) {
  if (cpfNumber(value)) {
    return "userName";
  }
  if (phoneInitials(value)) {
    return "phoneNumbers.mobile";
  }
  return "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User.emailAT";
}

/**
 * @description Valida se o valor começa com um DDD válido do Brasil
 * @param {string} value - Número de telefone a ser validado
 * @returns {boolean} True se começa com DDD válido, false caso contrário
 */
function phoneInitials(value: string) {
  const ddd = value?.replace(/[^a-zA-Z0-9]/g, "").substring(0, 3);
  const validDDDs = [
    "119",
    "129",
    "139",
    "149",
    "159",
    "169",
    "179",
    "189",
    "199",
    "219",
    "229",
    "249",
    "279",
    "289",
    "319",
    "329",
    "339",
    "349",
    "359",
    "379",
    "389",
    "419",
    "429",
    "439",
    "449",
    "459",
    "469",
    "479",
    "489",
    "499",
    "519",
    "529",
    "539",
    "549",
    "619",
    "629",
    "639",
    "649",
  ];

  return validDDDs.includes(ddd);
}

/**
 * @description Valida um número de CPF usando o algoritmo oficial
 * @param {string} value - CPF a ser validado (pode conter pontuação)
 * @returns {boolean} True se CPF é válido, false caso contrário
 */
function cpfNumber(value: string) {
  let Soma;
  let Resto;
  Soma = 0;
  let cpf = "";
  if (value) cpf = value.replace(/\D/g, "");
  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  const id_array = cpf?.split("");
  if (id_array.length !== 11) return false;

  for (let i = 1; i <= 9; i += 1)
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i += 1)
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(10, 11), 10)) return false;

  return true;
}

/**
 * @description Hook de validação com funções utilitárias
 */
export const useValidate = {
  signinCredentialType,
  cpfNumber,
  phoneInitials,
};
