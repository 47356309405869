/**
 * @description Configuração principal de roteamento da aplicação
 * @module Navigation
 * @since Fevereiro 2024
 */

import { createHashRouter, RouterProvider, Outlet } from "react-router-dom";

import LoggedUser from "./components/LoggedUser";
import {
  SignIn,
  SignUp,
  Recover,
  NewPassword,
  ValidateDocument,
} from "../pages";
import { AuthContextProvider } from "../contexts/AuthContext";

/**
 * @description Configuração das rotas da aplicação usando createHashRouter
 * @remarks Utiliza hash router para melhor compatibilidade com servidores estáticos
 * @property future.v7_startTransition - Habilita o recurso de transição da v7 do React Router
 */
const router = createHashRouter(
  [
    // Rotas públicas (não requerem autenticação)
    {
      path: "/signin",
      element: <SignIn />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/recover",
      element: <Recover />,
    },
    {
      path: "/validateDocument/:id",
      element: <ValidateDocument />,
    },
    {
      path: "/newpassword",
      element: <NewPassword />,
    },
    // Rotas privadas (requerem autenticação via AuthContext)
    {
      path: "/",
      element: (
        <AuthContextProvider>
          <Outlet />
        </AuthContextProvider>
      ),
      children: [
        {
          index: true,
          element: <LoggedUser />,
        },
        {
          path: ":id",
          element: <LoggedUser />,
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  }
);

/**
 * @description Componente principal de navegação
 * @returns {JSX.Element} Router provider configurado com todas as rotas
 */
export default function Navigation() {
  return <RouterProvider router={router} />;
}
