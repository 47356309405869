import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import logo from "../../../assets/fluxmed-logo-horizontal.png";

import {
  ContentBoxTitle,
  PrimaryButton,
  PrimaryLink,
} from "../../../components/common";
import { FormPasswordInput, FormTextInput } from "../../../components/form";
import ViewVersion from "../../../components/layout/private/main/ViewVersion";
import { OAuthUser } from "../../../services/auth";
import { useFormatter } from "../../../utils/useFormatter";
import { useRegex } from "../../../utils/useRegex";
import { useValidate } from "../../../utils/useValidate";

interface IFormInput {
  textValue: string;
  passwordValue: string;
}

const defaultValues = {
  textValue: "",
  passwordValue: "",
};

export function Form() {
  const navigate = useNavigate();
  const { signinCredentialType } = useValidate;
  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IFormInput) => {
    localStorage.clear();
    sessionStorage.clear();
    const auxValue = signinCredentialType(data.textValue);
    const auxType =
      auxValue.includes("userName") || auxValue.includes("phoneNumbers.mobile");

    if (auxType) {
      data.textValue = data.textValue.replace(/[^a-zA-Z0-9]/g, "");
    } else {
      data.textValue = data.textValue.replace("@", "[AT]");
    }

    setLoading(true);
    try {
      const response = await OAuthUser(data.textValue, data.passwordValue);
      if (response) {
        localStorage.setItem("token", response.access_token);
        localStorage.setItem("logged", "true");
        localStorage.setItem("username", data.textValue);
        localStorage.setItem("refresh_token", response.refresh_token);
        const expirationTime = (
          Date.now() +
          response?.expires_in * 1000
        ).toString();
        localStorage.setItem("expirationTime", expirationTime);
        navigate("/");
      } else throw new Error("Sign in error");
    } catch (err) {
      toast.error("Erro, verifique suas credenciais!");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !loading) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const watchedFieldValue = useWatch({ name: "textValue", control });

  const cleanValue = watchedFieldValue.replace(/[()\-. ]/g, "");

  const verifyValue =
    cleanValue.length === 11 && useRegex.number.test(cleanValue);

  // function selectFormater() {
  //   if (verifyValue) {
  //     if (useValidate.cpfNumber(cleanValue)) return useFormatter.formatCPF;
  //     if (useValidate.phoneInitials(cleanValue))
  //       return useFormatter.formatPhoneNumber;
  //   }
  // }

  return (
    <Stack
      flex={1}
      px={{ sm: 3, md: "10%" }}
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <img src={logo} alt="Logo" width={217} height="auto" />

      <ContentBoxTitle>Acesse sua conta</ContentBoxTitle>
      <Stack width={{ xs: "90vw", lg: "100%" }} flex={1}>
        <FormProvider {...methods}>
          <form onKeyDown={handleKeyPress}>
            <Stack width="100%" spacing={2}>
              <FormTextInput
                maxLength={verifyValue ? 11 : 100}
                name="textValue"
                control={control}
                mask={useFormatter.formatCPF}
                label="CPF"
                required={true}
                shrink={true}
                pattern={useRegex.loginEntry}
              />
              <FormPasswordInput
                name="passwordValue"
                control={control}
                label="Senha"
                required={true}
                // pattern={useRegex.password}
                shrink
              />
            </Stack>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              marginY={2}
            >
              <PrimaryLink text="Esqueci minha senha" link="/recover" />
            </Box>
            <PrimaryButton
              width="100%"
              height="52px"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
            >
              Login
            </PrimaryButton>
          </form>
        </FormProvider>
      </Stack>

      <Stack direction={{ sm: "column", md: "row" }} alignItems="center">
        <Typography variant="body1">Primeiro acesso? &ensp;</Typography>
        <PrimaryLink text="Clique aqui" link="/signup" />
      </Stack>

      <Stack direction={{ sm: "column", md: "row" }} alignItems="center">
        <Typography variant="body1">Não possui uma conta? &ensp;</Typography>
        <PrimaryLink text="Cadastre-se" link="/signup" />
      </Stack>
      <Stack position="absolute" alignItems="center" bottom={20}>
        {/* {isMobile && import.meta.env.VITE_PROJ !== "Fluxmed" && (
          <Link href={endpoints.XDSB_domain}>
            <Box
              component="img"
              src={PoweredByFluxmed}
              alt={`Logo powered by fluxmed`}
              sx={{ width: "250px", height: "auto", overflow: "hidden" }}
            />
          </Link>
        )} */}
        <ViewVersion isOpen={true} inline={true} />
      </Stack>
    </Stack>
  );
}
