import { PictureAsPdf } from "@mui/icons-material";
import { currentFeatures } from "../../configs/Settings";
import { useSessionContext } from "../../contexts/SessionContext";
import { useViewContext } from "../../contexts/ViewContext";
import { IFeatures } from "../../utils/interfaces";
import { PersonPdf } from "./PersonPdf";

export function usePersonPdf() {
  const { access } = useSessionContext();
  const { availableRoles } = useViewContext();

  const configs: IFeatures = {
    active:
      access.type === "professional" &&
      currentFeatures.profileClinic &&
      (availableRoles?.includes("manager") ||
        availableRoles?.includes("owner")),
    path: "/pdf",
    text: "Modelos de documentos",
    icon: PictureAsPdf,

    subitem: true,
    parent: "Configurações",

    sideMenu: true,
    sideMenuIndex: 0,

    component: () => <PersonPdf />,
  };

  return configs;
}
