import { Grid, Stack } from "@mui/material";
import dayjs from "dayjs";
import { FormDateInput } from "../../../../components/form";
import { FormSelectCompleted } from "../../../../components/form/FormSelectCompleted";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

// const smokingOptions = [
//   {
//     code: "LA18976-3",
//     display: "atualmente, fumante diário",
//     value: "Current every day smoker",
//   },
//   {
//     code: "LA18977-1",
//     display: "atualmente, fumante alguns dias na semana",
//     value: "Current some day smoker",
//   },
//   { code: "LA15920-4", display: "ex-fumante", value: "Former smoker" },
//   { code: "LA18978-9", display: "nunca fumou", value: "Never smoker" },
//   {
//     code: "LA18979-7",
//     display: "estado tabagista atual desconhecido",
//     value: "Smoker, current status unknown",
//   },
//   {
//     code: "LA18980-5",
//     display: "sem conhecimento se já fumou",
//     value: "Unknown if ever smoked",
//   },
//   {
//     code: "LA18981-3",
//     display: "fumante frequente",
//     value: "Heavy tobacco smoker",
//   },
//   {
//     code: "LA18982-1",
//     display: "fumante eventual",
//     value: "Light tobacco smoker",
//   },
//   {
//     code: "",
//     display: "Limpar",
//     value: "",
//   },
// ];

const breastfeedingOptions = [
  {
    display: "Exclusivo",
    value: "exclusivo",
  },
  {
    display: "Predominante",
    value: "predominante",
  },
  {
    display: "Complementado",
    value: "complementado",
  },
  {
    display: "Inexistente",
    value: "inexistente",
  },
  {
    display: "Limpar",
    value: "",
  },
];

export function AnthropometricFields({ gender }: any) {
  const { control } = useMedicalCareContext();

  return (
    <Grid container spacing={3} marginTop="1px">
      <Grid
        item
        xs={6}
        padding="0px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack direction="row" spacing={2}>
          <InputText
            label={"Pressão arterial Sistólica"}
            name={"Anthropometric.pressure.sistolic"}
            control={control}
          />
          <InputText
            label={"Pressão arterial Diastólica"}
            name={"Anthropometric.pressure.diastolic"}
            control={control}
          />
        </Stack>
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Peso (kg)"}
          name={"Anthropometric.bodyWeight"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Altura (cm)"}
          name={"Anthropometric.bodyHeight"}
          control={control}
          type="number"
        />
      </Grid>
      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Circunferência abdominal (cm)"}
          name={"Anthropometric.abdominalCircumference"}
          control={control}
          type="number"
        />
      </Grid>
      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Perímetro Cefálico (cm)"}
          name={"Anthropometric.headCircumference"}
          control={control}
          type="number"
        />
      </Grid>

      {gender === "female" ? (
        <>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <FormDateInput
              name={"Anthropometric.menstrualPeriod"}
              label={"Data da Última Menstruação"}
              control={control}
              defaultValue={dayjs()}
            />
          </Grid>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <InputText
              label={"Quantidade de Gestações"}
              name={"Anthropometric.pregnancies"}
              control={control}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <InputText
              label={"Quantidade de Abortos"}
              name={"Anthropometric.abortions"}
              control={control}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <InputText
              label={"Partos"}
              name={"Anthropometric.birthsTotal"}
              control={control}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <FormSelectCompleted
              control={control}
              name={"Anthropometric.breastfeeding"}
              label={"Tipo de Aleitamento Materno"}
              selectOptions={breastfeedingOptions}
            />
          </Grid>
          <Grid
            item
            xs={6}
            padding="24px 24px 24px 0px"
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <InputText
              label={"Idade Gestacional (semanas)"}
              name={"Anthropometric.ageGestacional"}
              control={control}
              type="number"
            />
          </Grid>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
}
