import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { PageSubTitle } from "./PageSubTitle";

interface IProps {
  onClick: Function;
}

export function AddDependent({ onClick }: IProps) {
  return (
    <Stack spacing={4} paddingTop={1}>
      <PageSubTitle
        subText="Cadastre o menor de idade ou dependente e acompanhe resultados de
        exames, agende consultas e muito mais."
        align="center"
      />
      <Button
        component="label"
        variant="text"
        startIcon={<Add />}
        sx={{
          width: "fit-content",
          color: "primary700.main",
          margin: "8px 0",
          padding: "8px",
        }}
        onClick={() => {
          onClick();
        }}
      >
        <Typography
          variant="subtitle2"
          textTransform="lowercase"
          sx={{
            "&::first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          Adicionar dependente
        </Typography>
      </Button>
    </Stack>
  );
}
