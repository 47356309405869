interface ProjectFeatures {
  [key: string]: () => {
    signin: boolean;
    signup: boolean;
    register: boolean;
    profile: boolean;
    profileClinic: boolean;
    home: boolean;
  };
}

function projectFeatures() {
  const projectFeatures: ProjectFeatures = {
    standard: () => ({
      signin: true,
      signup: true,
      profile: true,
      profileClinic: true,
      register: false,
      home: true,
    }),
    register: () => ({
      signin: true,
      signup: true,
      profile: false,
      profileClinic: false,
      register: true,
      home: false,
    }),
  };
  return projectFeatures[import.meta.env.VITE_PROJ_MODE || "standard"]();
}

export const currentFeatures = projectFeatures();

export const OrganizationPartOf = {
  id: import.meta.env.VITE_ORGANIZATION_PART_OF,
  display: import.meta.env.VITE_ORGANIZATION_PART_OF_DISPLAY,
};

interface ISettings {
  XDSB_domain: any;
  principalDomain: any;
  token_api: any;
  portalConsumerKey: any;
  portalConsumerSecret: any;
  SIGNUP: any;
  REGISTER: any;
  recover: any;
  Scim2: any;
  TERMINOLOGY: any;
  Interdrugs: any;
  Brevo: any;
  BrevoApiKey: any;
  USERFHIR: any;
  ADMFHIR: any;
  WORKFLOWFHIR: any;
  CONSENT: any;
  MPIFHIR: any;
  APPC: any;
  MHD: any;
  EHRPOSTER: any;
  SCHEDULING: any;
  QUESTIONNARIE_ID: any;
  PROTOCOL_ID: any;
  CUSTOMIZATION_ID: any;
  DSIG: any;
  ViaCep: any;
  AppName: string;
  WHEREBY: any;
  WHEREBY_API_KEY: any;
  chatbotId: string;
  chatbotHost: string;
  chatbotIdPatient: string;
}

console.log("import.meta.env:", import.meta.env);
console.log("process.env:", process.env);

// Log de teste para variável de ambiente
console.log("VITE_PROJ:", import.meta.env.VITE_PROJ);
console.log("VITE_ENV:", import.meta.env.VITE_ENV);

export const endpoints: ISettings = {
  XDSB_domain: import.meta.env.VITE_ENDPOINT_XDSB_domain,
  principalDomain: import.meta.env.VITE_ENDPOINT_principalDomain,
  token_api: import.meta.env.VITE_ENDPOINT_token_api,
  portalConsumerKey: import.meta.env.VITE_PORTAL_CONSUMER_KEY,
  portalConsumerSecret: import.meta.env.VITE_PORTAL_CONSUMER_SECRET,
  SIGNUP: import.meta.env.VITE_ENDPOINT_SIGNUP,
  REGISTER: import.meta.env.VITE_ENDPOINT_REGISTER,
  Scim2: import.meta.env.VITE_ENDPOINT_Scim2,
  recover: import.meta.env.VITE_ENDPOINT_RECOVER,
  TERMINOLOGY: import.meta.env.VITE_ENDPOINT_TERMINOLOGY,
  Brevo: import.meta.env.VITE_ENDPOINT_BREVO_SMTP,
  Interdrugs: import.meta.env.VITE_ENDPOINT_MANOLE,
  BrevoApiKey: import.meta.env.VITE_ENDPOINT_BREVO_API_KEY,
  USERFHIR: import.meta.env.VITE_ENDPOINT_USERFHIR,
  ADMFHIR: import.meta.env.VITE_ENDPOINT_ADMFHIR,
  WORKFLOWFHIR: import.meta.env.VITE_ENDPOINT_WORKFLOW_FHIR,
  CONSENT: import.meta.env.VITE_ENDPOINT_CONSENT_api,
  MPIFHIR: import.meta.env.VITE_ENDPOINT_MPI_FHIR,
  APPC: import.meta.env.VITE_ENDPOINT_APPCFHIR,
  MHD: import.meta.env.VITE_ENDPOINT_MHD,
  EHRPOSTER: import.meta.env.VITE_ENDPOINT_POSTER,
  SCHEDULING: import.meta.env.VITE_ENDPOINT_SCHEDULING,
  QUESTIONNARIE_ID: import.meta.env.VITE_QUESTIONNAIRE_ID,
  PROTOCOL_ID: import.meta.env.VITE_PROTOCOL_ID,
  CUSTOMIZATION_ID: import.meta.env.VITE_CUSTOMIZATION_ID,
  DSIG: import.meta.env.VITE_ENDPOINT_DSIG,
  ViaCep: import.meta.env.VITE_ENDPOINT_VIACEP,
  AppName: import.meta.env.VITE_PROJ || "",
  WHEREBY: import.meta.env.VITE_ENDPOINT_WHEREBY,
  WHEREBY_API_KEY: import.meta.env.VITE_ENDPOINT_WHEREBY_API_KEY,
  chatbotId: import.meta.env.VITE_CHATBOT_ID || "",
  chatbotHost: import.meta.env.VITE_CHATBOT_HOST || "",
  chatbotIdPatient: import.meta.env.VITE_CHATBOT_ID_PATIENT || "",
};

export const docTypes = [
  {
    name: "Lab results",
    alias: "RE",
    codes: [
      "1.3.6.1.4.1.54413.1.1.2.3.1",
      "SHROP - COVID19 laboratory report.v1.0",
      "Lab results",
    ],
  },
  {
    name: "Discharge summary",
    alias: "SA",
    codes: ["SHROP - Discharge summary report.v1.0"],
  },
  {
    name: "Encounter (consultation) summary",
    alias: "RAC",
    codes: ["SHROP - Encounter summary report.v1.0"],
  },
  {
    name: "Notification COVID-19",
    alias: "NCSC",
    codes: ["SHROP - Notification COVID19.v1.0"],
  },
  {
    name: "Immunization",
    alias: "RI",
    codes: ["SHROP - Immunization report.v1.0", "RegistroImunobiológico_V3"],
  },
];

export const oids = {
  organization: "1.3.6.1.4.1.54413.1.2.2.1",
  cnpj: "2.16.76.1.3.3",
  cpf: "2.16.840.1.113883.13.237",
  cns: "2.16.840.1.113883.13.236",
  practitionerRoles: "1.3.6.1.4.1.54413.1.1.2.1",
  programHealthCareService: "1.3.6.1.4.1.54413.1.1.2.2",
  categoryHealthCareService: "1.3.6.1.4.1.54413.1.1.6.8",
  susAttendance: "1.3.6.1.4.1.54413.1.1.6.7",
  admType: "1.3.6.1.4.1.54413.1.1.6.6",
  legalNature: "1.3.6.1.4.1.54413.1.1.6.4",
  orgType: "1.3.6.1.4.1.54413.1.1.6.3",
  immunobiological: "1.3.6.1.4.1.54413.1.1.6.10",
  doseCovidVaccine: "1.3.6.1.4.1.54413.1.1.6.14",
  immunizationEffects: "1.3.6.1.4.1.54413.1.1.6.13",
  documentPdf: "1.3.6.1.4.1.54413.1.1.2.3.1",
  council: "2.16.840.1.113883.13.243",
};

export const roles = {
  Practitioner: "RL_PRACTITIONER",
  Manager: "RL_MANAGER",
  Owner: "RL_OWNER",
  Invited: "RL_INVITED",
};
