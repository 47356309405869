import logo from "../../assets/fluxmed-logo-horizontal-white.png";
import { Stack } from "@mui/material";

export function HeaderLogo() {
  return (
    <a href={import.meta.env.VITE_ENDPOINT_XDSB_domain}>
      <Stack width="100%" maxWidth="151px" position="relative" height="36px">
        <img src={logo} alt="Logo fluxmed" />
      </Stack>
    </a>
  );
}
