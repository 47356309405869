import { Info } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useSessionContext } from "../../../contexts/SessionContext";

export const InfoMessage = ({ message }: { message: string }) => (
  <Stack
    direction="row"
    justifyContent="left"
    alignItems="center"
    spacing={2}
    width="100%"
  >
    <Info sx={{ color: "warning500.main" }} />
    <Typography
      variant="body1"
      fontWeight={500}
      lineHeight={"24px"}
      textAlign={"justify"}
    >
      {message}
    </Typography>
  </Stack>
);

export default function BackupNotice({
  progress,
  loading,
  startedProgress,
}: {
  loading?: boolean;
  progress?: number;
  startedProgress?: boolean;
}) {
  const { roles } = useSessionContext();
  const isManagerOrOwner = roles?.some(
    (role: any) => role.isOwner || role.isManager
  );

  return (
    <Stack
      direction="column"
      spacing={8}
      height={"50%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {!loading && !startedProgress && (
        <>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6" fontWeight={600} color={"neutral700.main"}>
              Encerrar conta
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              Não feche o navegador até concluir o processo completo para
              desativar a conta.
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="left"
            alignItems="center"
            spacing={4}
            width="100%"
          >
            <InfoMessage
              message={
                isManagerOrOwner
                  ? "Ao solicitar o backup, daremos início ao processo de encerramento da sua conta. Todos os seus dados serão organizados e disponibilizados para download em breve. Esse processo pode levar algum tempo, dependendo do volume de informações associadas à sua conta."
                  : "Os seus dados pessoais serão organizados e disponibilizados para download após a confirmação. Esse processo pode levar algum tempo, dependendo do volume de informações associadas à sua conta."
              }
            />
            <InfoMessage
              message={
                isManagerOrOwner
                  ? "Após o encerramento, você terá 30 dias de acesso à plataforma para finalizar atendimentos, visualizar dados e todas as suas configurações serão desativadas!"
                  : "Após o encerramento, você não terá mais acesso à plataforma!"
              }
            />
            <InfoMessage message="Antes de confirmar, certifique-se de que deseja prosseguir!" />
          </Stack>
        </>
      )}
    </Stack>
  );
}
