import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

import { ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import { useLocationContext } from "../../../contexts/LocationContext";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { useFhirData } from "../../../utils/useFhirData";
import { DocumentAccordion } from "./DocumentAccordion";
import { ReadOnlyField } from "./ReadOnlyField";

export function Footer({
  patientName,
  participant,
  setDataPdf,
  auxPrescription,
}: {
  patientData?: fhir5.Patient;
  patientName: Object;
  participant: fhir5.Appointment["participant"] | undefined;
  setDataPdf: any;
  auxPrescription: any;
}) {
  const { organization } = useOrganizationContext();
  const { userPractitioner, userSpecialties, userPractitionerRole } =
    usePractitionerRoleContext();
  const { location } = useLocationContext();

  let crmPractitioner =
    userPractitionerRole?.identifier?.[0]?.value?.split("/") || "";
  crmPractitioner = [
    crmPractitioner[1]?.substring(0, 2),
    crmPractitioner[1]?.substring(2),
  ];

  const practitionerReference = useFhirData.findParticipant(
    participant,
    "Practitioner"
  );

  const locationReference = useFhirData.findParticipant(
    participant,
    "Location"
  );

  const healthcareReference = useFhirData.findParticipant(
    participant,
    "HealthcareService"
  );

  const specialty =
    userSpecialties?.find((el) =>
      el.coding?.[0].display?.includes(healthcareReference?.display)
    )?.coding?.[0]?.display ||
    healthcareReference?.display ||
    "-";

  const practitionerName =
    userPractitioner?.name?.[0]?.text || practitionerReference?.display || "-";

  const address =
    location?.find((loc) =>
      locationReference?.reference.includes(loc.resource?.id)
    )?.resource?.address?.line ||
    organization?.contact?.find((el: fhir5.ExtendedContactDetail) => el.address)
      ?.address?.line;

  const data = [
    {
      title: "Profissional",
      description: practitionerName,
    },

    {
      title: "Especialidade",
      description: specialty,
    },
    { title: "Data", description: `${dayjs()?.format("DD/MM/YYYY")}` },
    {
      title: "Endereço",
      description: address?.filter(Boolean).join(", ") || "",
    },
  ];

  useEffect(() => {
    const auxDataPdf = [];
    auxDataPdf.push(
      address,
      data,
      patientName,
      crmPractitioner,
      auxPrescription
    );
    setDataPdf(auxDataPdf);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialty, auxPrescription]);

  return (
    <DocumentAccordion
      title="Assinatura digital e impressão"
      subtitle="Data, CRm e assinatura digital"
    >
      <Grid container direction="row" spacing={2}>
        {data.map((el) => (
          <Grid item xs={12} sm={6} key={`footer-${el.title}`}>
            <ReadOnlyField title={el.title} description={el.description} />
          </Grid>
        ))}

        <Grid item xs={12} sm={12} key={`view-role-signature`}>
          <Stack direction="column" spacing={1}>
            <Typography
              variant="body1"
              color="neutral1000.main"
              fontWeight={500}
            >
              Assinatura
            </Typography>
            <FormControlLabel control={<Switch />} label="Assinatura digital" />
            <Button
              endIcon={<ArrowForward />}
              variant="text"
              sx={{ textTransform: "none", width: "fit-content", padding: 0 }}
            >
              Configurar assinatura digital
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DocumentAccordion>
  );
}
