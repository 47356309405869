import {
  AccessTime,
  Close,
  Event,
  Notifications,
  PersonOutline,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { NoDataWarning } from "../../../../../../../components/common";
import { useLocationContext } from "../../../../../../../contexts/LocationContext";
import { useManagerContext } from "../../../../../../../contexts/ManagerContext";
import { useSessionContext } from "../../../../../../../contexts/SessionContext";
import { GetAppointment } from "../../../../../../../services/fhir";
import { useDatetime } from "../../../../../../../utils/useDatetime";
import { useFhirData } from "../../../../../../../utils/useFhirData";
import { useFormatter } from "../../../../../../../utils/useFormatter";
import { useInternationalVerify } from "../../../../../../../utils/useInternationalVerify";

export function FutureViewer({ setDialogCancelAppointment }: any) {
  const { displaySchedules, selectedDate, setSelectedRow } =
    useManagerContext();

  const { access } = useSessionContext();
  const { formatNameDisplay } = useFormatter;

  const { location } = useLocationContext();
  const { formatDate } = useDatetime;

  const [nextAppointment, setNextAppointment] = useState<any>();
  const [patients, setPatients] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const { formatDateToISO } = useDatetime;
  const { formatDateTimeToHour } = useFormatter;

  const schedulesIds = displaySchedules
    ?.map((e: any) => e.resource?.id)
    ?.join(",");

  const practitioners = displaySchedules
    ?.map(
      (e: any) =>
        useFhirData.findActor(e?.resource?.actor, "Practitioner")?.reference
    )
    ?.join(",");

  const [page, setPage] = useState(1);
  // Número de itens por página
  const itemsPerPage = 3;

  // Função para mudar de página
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  // Cálculo dos itens que serão exibidos com base na página
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = nextAppointment

    ?.sort((a: any, b: any) => {
      const dateA = dayjs(a.resource?.start);
      const dateB = dayjs(b.resource?.start);

      return dateA.diff(dateB);
    })
    ?.slice(startIndex, endIndex);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        if (schedulesIds) {
          const startDate = formatDateToISO(selectedDate);

          const endDate = formatDateToISO(selectedDate);

          const nextAppointmentResponse = await GetAppointment({
            schedule: schedulesIds,
            startDate,
            endDate,
            status: ["booked"],
            includePatient: true,
            scheduling: true,
            professionalActor: practitioners,
          });

          const auxNextAppoitments = nextAppointmentResponse?.entry?.filter(
            (e: any) =>
              e.resource?.resourceType === "Appointment" &&
              dayjs(e?.resource?.end).isAfter(dayjs())
          );

          const auxNextPatients = nextAppointmentResponse?.entry?.filter(
            (e: any) => e.resource?.resourceType === "Patient"
          );

          if (auxNextAppoitments?.length === 0) {
            setNextAppointment(undefined);
            setPatients(undefined);
          } else {
            setNextAppointment(auxNextAppoitments);
            setPatients(auxNextPatients);
          }
        } else {
          setNextAppointment(undefined);
          setPatients(undefined);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
    if (access?.organizationId) fetchData();
  }, [
    access?.organizationId,
    refetch,
    selectedDate,
    formatDateToISO,
    schedulesIds,
    displaySchedules,
    practitioners,
  ]);

  //   if (loading)
  //     return (
  //       <Stack height="100%">
  //         <CircularLoading />
  //       </Stack>
  //     );

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        {paginatedData?.map((e: any, index: number) => {
          const link =
            e?.resource?.virtualService?.[0]?.addressUrl ||
            `https://teleatendimento.fluxmed.com.br/Teleconsulta-${e?.resource?.id}`;
          const healthcare = useFhirData.findParticipant(
            e?.resource?.participant,
            "HealthcareService"
          );
          const patient = useFhirData.findParticipant(
            e?.resource?.participant,
            "Patient"
          );
          const appointmentLocation = useFhirData.findParticipant(
            e?.resource?.participant,
            "Location"
          );
          const auxVirtualService = useFhirData.virtualService(
            location?.find((loc: any) =>
              loc?.resource?.id.includes(
                appointmentLocation?.reference?.split("/")?.[1]
              )
            )?.resource
          );

          const auxLocation = location?.find((loc: any) =>
            loc?.resource?.id.includes(
              appointmentLocation?.reference?.split("/")?.[1]
            )
          )?.resource;

          const address = auxLocation?.address?.line?.join(", ");

          const findPatient = patients?.find((e: any) =>
            patient?.reference.includes(e?.resource?.id)
          )?.resource;

          // const patientId = e?.reference?.participant?.split("-")?.[1];
          // const auxSchedule = displaySchedules?.find((el: any) => {
          //   const auxScheduleHealthCare = useFhirData.findActor(
          //     el?.resource?.actor,
          //     "HealthcareService"
          //   );
          //   return auxScheduleHealthCare?.reference === healthcare?.reference;
          // });
          const practitioner = useFhirData.findParticipant(
            e?.resource?.participant,
            "Practitioner"
          )?.display;
          const date = dayjs(e?.resource?.start)?.format("DD/MM/YYYY") || "";
          const hour = e?.resource?.start
            ? formatDateTimeToHour(e?.resource.start)
            : "";
          const lembreteMessage = auxVirtualService
            ? `Olá ${findPatient?.name?.[0]?.text}. Você possui uma teleconsulta agendada para ${date} - ${hour} com Dr. ${practitioner} na especialidade de ${healthcare.display}. Para acessar a videoconferênciar por favor acesse o link: ${link}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`
            : `Olá ${findPatient?.name?.[0]?.text}. Você possui uma teleconsulta agendada para ${date} - ${hour} com Dr. ${practitioner} na especialidade de ${healthcare.display}. Endereço: ${address}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`;
          const phone = findPatient?.telecom?.find(
            (e: any) => e.system === "phone"
          )?.value;

          const isLate = dayjs()?.add(10, "minute").isAfter(e?.resource?.start);
          //   const isInProgress = inProgress === e?.resource?.id;
          //   const isNext = index === 0;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  border: isLate ? "2px solid " : "2px solid",
                  borderColor: isLate ? "warning500.main" : "neutral100.main",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: 0.5,
                  backgroundColor: "neutral0.main",
                }}
              >
                <CardContent sx={{ paddingBottom: 0 }}>
                  <Stack spacing={1}>
                    <Stack mb={2} direction="row" alignItems="center" flex={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        width="100%"
                      >
                        <Typography fontSize={20} variant="body1">
                          {date}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          pl={3}
                        >
                          <AccessTime />
                          <Typography fontSize={20} variant="body1">
                            {hour}
                          </Typography>
                        </Stack>
                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography variant="body1" color="text.secondary">
                            {healthcare?.display}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <PersonOutline sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={18} variant="body1">
                        {formatNameDisplay(findPatient?.name?.[0]?.text)}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Event sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={18} variant="body1">
                        {dayjs(findPatient?.birthDate).isValid()
                          ? `${formatDate(
                              findPatient?.birthDate
                            )} - ${useFormatter.formatAge(
                              findPatient?.birthDate
                            )} anos`
                          : findPatient?.birthDate}
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <Phone sx={{ color: "primary.main", mr: 1 }} />

                        <Typography fontSize={18} variant="body1">
                          {useFormatter.formatPhoneNumber(phone || "")}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Tooltip title="Enviar Lembrete da reunião">
                          <Button
                            variant="text"
                            sx={{ fontSize: 12 }}
                            onClick={() =>
                              window.open(
                                `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                                  phone
                                )}&text=${lembreteMessage}`,
                                "_blank"
                              )
                            }
                          >
                            <Notifications />
                            Enviar lembrete
                          </Button>
                        </Tooltip>
                      </Box>
                    </Stack>

                    <Divider sx={{ my: 2 }}></Divider>
                    {/* <Stack width="fit-content">
                      <Chip
                        label={healthcare?.display}
                        size="small"
                        sx={{
                          color: "neutral700.main",
                          fontWeight: 500,
                          fontStyle: "italic",
                          fontSize: 16,

                          // backgroundColor: "#1B1F27",
                        }}
                      />
                    </Stack> */}

                    <Typography
                      color="neutral700.main"
                      sx={{
                        fontWeight: 500,
                        fontStyle: "italic",
                        fontSize: 16,
                      }}
                    >
                      Dr. {practitioner}
                    </Typography>

                    {!auxVirtualService ? (
                      <Typography variant="body1" color="text.secondary">
                        {address}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        Atendimento remoto
                      </Typography>
                    )}
                  </Stack>
                </CardContent>

                <CardActions
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={<WhatsApp />}
                    onClick={() =>
                      window.open(
                        `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                          phone
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    Entrar em contato
                  </Button>

                  <Button
                    color="error"
                    startIcon={<Close />}
                    onClick={() => {
                      setSelectedRow({
                        auxCancelAppointment: {
                          id: e?.resource?.id,
                          wherebyId: e?.resource?.virtualService?.[0]?.sessionKey,
                        },
                        setTabRefetch: setRefetch,
                      });
                      setDialogCancelAppointment(true);
                    }}
                  >
                    Cancelar consulta
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        }) || <NoDataWarning message="Nenhuma consulta foi encontrada!" />}
      </Grid>

      {paginatedData?.length && (
        <Pagination
          count={Math.ceil(nextAppointment?.length / itemsPerPage)} // Número total de páginas
          page={page} // Página atual
          onChange={handleChangePage} // Função de mudança de página
          sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
        />
      )}
    </Box>
  );
}
