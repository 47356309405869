import { ArrowForward } from "@mui/icons-material";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  NoDataWarning
} from "../../../components/common";
import { HealthMedicineDetails } from "./HealthMedicineDetails";
import { NewMedication } from "./NewMedication";

interface IProps {
  image: any;
  text: string;
  CardType?: string;
  data: any;
  setState?: any;
  setRefetch?: any;
  loading?: boolean;
  control?: any;
}

export function ControlPrescriṕtions({
  image,
  text,
  CardType,
  control,
  data,
  setState,
  setRefetch,
  loading,
}: IProps) {
  const [openNewMedicament, setOpenNewMedicament] = useState(false);

  return (
    <Stack
      sx={{
        width: "100%",
        minWidth: "100px",

        backgroundColor: "neutral0.main",
        borderRadius: "16px",
      }}
      alignItems="flex-start"
    >
      {openNewMedicament ? (
        <NewMedication
          setModalOpen={setOpenNewMedicament}
          setRefetch={setRefetch}
        />
      ) : (
        <>
          {CardType === "medication" ? (
            <Button
              component="label"
              variant="text"
              endIcon={<ArrowForward />}
              sx={{
                width: "fit-content",
                color: "primary700.main",
                margin: "0 8px 8px",
                //padding: "8px",
                borderRadius: "4px",
              }}
              onClick={() => setOpenNewMedicament(true)}
            >
              <Typography
                variant="subtitle2"
                textTransform="lowercase"
                sx={{
                  "&::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                Registrar novo medicamento
              </Typography>
            </Button>
          ) : (
            ""
          )}
          {loading ? (
            <Stack width="100%" height="100%" mt={2}>
              <Skeleton variant="rectangular" width="100%" height="90%" />
            </Stack>
          ) : data?.length ? (
            data?.map((el: any) => (
              <HealthMedicineDetails
                fullData={el.fullResource}
                type={el.type}
                name={el.name}
                result={el.result}
                date={el.date}
                setRefetch={setRefetch}
              />
            ))
          ) : (
            <Stack width="100%" mt={2}>
              <NoDataWarning />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
