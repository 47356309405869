import { Skeleton, Stack } from "@mui/material";
import { useEffect } from "react";

import { isMobile } from "react-device-detect";
import { useSessionContext } from "../../../contexts/SessionContext";
import { DeviceIDPatch } from "../../../services/scim/profile/DeviceIDPatch";
import { MobilePatient } from "./PatientDash/MobilePatient";
import { WebPatient } from "./PatientDash/WebPatient";

interface ISelectOption {
  label: string;
  value: string;
}

export interface IFormInput {
  healthcareService: ISelectOption | undefined;
  organization: ISelectOption | undefined;
  practitioner: ISelectOption | undefined;
}

export function PatientDashboard() {
  const { inviteDialog, user, fetchUser } = useSessionContext();

  const tokenMobile = localStorage.getItem("device_id") || undefined;

  const updateDeviceIdMobile = async () => {
    if (user?.tokenFirebaseMobile === undefined && isMobile) {
      try {
        await DeviceIDPatch({
          idUser: user?.id,
          operation: "add",
          pathName:
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDMobile",
          valueField: tokenMobile,
        });
      } catch (err) {
        console.log("err", err);
      }

      fetchUser();
      return;
    }
    if (tokenMobile !== user?.tokenFirebaseMobile) {
      try {
        await DeviceIDPatch({
          idUser: user?.id,
          operation: "replace",
          pathName:
            "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDMobile",
          valueField: tokenMobile,
        });
      } catch (err) {
        console.log("err", err);
      }
      fetchUser();
      return;
    }
  };

  useEffect(() => {
    if (user?.tokenFirebaseMobile === tokenMobile || tokenMobile === undefined)
      return;
    else {
      updateDeviceIdMobile();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMobile && user?.tokenFirebaseMobile]);

  if (inviteDialog) {
    return <Skeleton variant="rectangular" width="100%" height="15vh" />;
  }

  return (
    <Stack width="100%" height="fit-content" gap={1}>
      {isMobile ? <MobilePatient /> : <WebPatient />}
    </Stack>
  );
}
