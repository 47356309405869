import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
interface IProps {
  handleClose: any;
  isOpen: boolean;
  data: any;
}

export function ObservationEncounterVisualizer({
  data,
  handleClose,
  isOpen,
}: IProps) {
  const translateCode: any = {
    wk: "semanas",
    mo: "meses",
  };
  const verifyValue = (el: any) => {
    if (el?.resource?.code?.coding?.[0]?.code === "55284-4") {
      return `${el?.resource?.component?.[0]?.valueQuantity?.value || "-"}/${
        el?.resource?.component?.[1]?.valueQuantity?.value || "-"
      } mmHg`;
    }

    if (el?.resource?.hasOwnProperty("valueCodeableConcept")) {
      return `${
        el?.resource?.valueCodeableConcept?.coding?.[0]?.display ||
        el?.resource?.valueCodeableConcept?.text
      }`;
    }

    if (el?.resource?.hasOwnProperty("valueQuantity")) {
      const value = el?.resource?.valueQuantity?.value;
      const code = el?.resource?.valueQuantity?.code;

      if (value !== undefined && code !== undefined) {
        return `${value} ${translateCode[code] || code}`;
      }
      if (value !== undefined) {
        return `${value}`;
      }
      if (code !== undefined) {
        return `${translateCode[code] || code}`;
      }
    }
    if (el?.resource?.hasOwnProperty("valueDateTime")) {
      return dayjs(el?.resource?.valueDateTime).format("DD/MM/YYYY");
    }

    return "-";
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "1200px",
        },
      }}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="left">Observação</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {data?.map((observation: any) => (
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={600}>
                {observation?.resource?.code?.text ||
                  observation?.resource?.code?.coding?.[0]?.display ||
                  ""}
              </Typography>
              <Typography variant="body1" fontWeight={400}>
                {verifyValue(observation)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
